import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Logo from "../assets/images/logo.png";
import {AuthResponse, SignIn as sign_in, about_me} from "../apis/Auth";
import {Alert, InputAdornment, Snackbar} from "@mui/material";
import {useEffect, useState} from "react";
import Copyright from "../utilities/Copyright";
import background1 from "../assets/images/user_screen_backgrounds/background1.png";
import background2 from "../assets/images/user_screen_backgrounds/background2.png";
import background3 from "../assets/images/user_screen_backgrounds/background3.png";
import background4 from "../assets/images/user_screen_backgrounds/background4.png";
import background5 from "../assets/images/user_screen_backgrounds/background5.png";
import background6 from "../assets/images/user_screen_backgrounds/background6.png";
import GA4EventSend from "../utilities/GA4EventSend";
import {Helmet} from "react-helmet";
import IconButton from "@mui/material/IconButton";
import {Visibility, VisibilityOff} from "@mui/icons-material";

export default function SignIn() {
    let params = new URLSearchParams(window.location.search);
    let redirect = "";
    if(params.has('path')){
        let encodedPath = params.get('path');
        redirect = decodeURIComponent(encodedPath as string);
    }
    const [open, setOpen] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [isClicked, setIsClicked] = useState(false);
    const [currentImage, setCurrentImage] = useState(background1);
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    useEffect(() => {
        // Define your images in an array
        const images = [background1, background2, background3, background4, background5, background6];
        // Create an array to store the preloaded images
        const preloadedImages = [];
        // Pre-load the images
        images.forEach((image) => {
            const img = new Image();
            img.src = image;
            preloadedImages.push(img);
        });
        const timer = setInterval(() => {
            setCurrentImage((currentImage) => {
                const currentIndex = images.indexOf(currentImage);
                const nextIndex = (currentIndex + 1) % images.length;
                return images[nextIndex];
            });
        }, 5000); // Change every 5 seconds

        // Clean up the interval on unmount
        return () => clearInterval(timer);
    }, []);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        setIsClicked(true);
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        // Call sign in API
        const response: AuthResponse = await sign_in((data.get('email') as string).toLowerCase(), data.get('password') as string);
        if (response.succeeded) {
            GA4EventSend("sign_in", "user_action", "sign_in", response.statusCode, false);
            localStorage.setItem('access_token', response.data.access);
            localStorage.setItem('refresh_token', response.data.refresh);
            // call about me API
            const about_me_response = await about_me(response.data.refresh, response.data.access);
            if (about_me_response.succeeded) {
                GA4EventSend("about_me", "user_action", "about_me", about_me_response.statusCode, false);
                localStorage.setItem('user', JSON.stringify(about_me_response.data));
                localStorage.setItem('signed_in', "true");
                if(redirect !== ""){
                    window.location.href = redirect;
                }
                else {
                    window.location.href = "/";
                }
            } else {
                GA4EventSend("about_me", "user_action", "about_me", about_me_response.statusCode, false);
                setMessage("Failed to fetch user data.");
                setOpen(true);
            }
        } else {
            GA4EventSend("sign_in", "user_action", "sign_in", response.statusCode, false);
            setMessage(response.message);
            setOpen(true);
        }
        setIsClicked(false);
    };

    return (
        <Grid container sx={{height: '100vh'}}>
            <CssBaseline/>
            <Helmet>
                <title>DRBB | Sign In</title>
            </Helmet>
            <Snackbar anchorOrigin={{vertical: "top", horizontal: "center"}} open={open} onClose={() => setOpen(false)}
                      autoHideDuration={6000}>
                <Alert elevation={6} severity="error" sx={{width: '100%'}} variant="filled">
                    {message}
                </Alert>
            </Snackbar>
            <Grid
                item
                xs={false}
                sm={4}
                md={7}
                sx={{
                    transition: 'background-image 2s ease-in-out',
                    backgroundImage: `url(${currentImage})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundColor: (t) =>
                        t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
            />
            <Grid item xs={12} sm={8} md={5} sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                backgroundColor: (t) =>
                    t.palette.mode === 'light' ? t.palette.grey[50] : "#141414",
            }}>
                <Box
                    sx={{
                        my: 8,
                        mx: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <img src={Logo} alt={'DRBB Logo'} height={'200'}/>
                    <Typography component="h1" variant="h3" sx={{mt: 1}} fontWeight={"bold"}>
                        Sign In
                    </Typography>
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{mt: 1}}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type={showPassword ? 'text' : 'password'}
                            id="password"
                            autoComplete="current-password"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={handleClickShowPassword}
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{
                                mt: 3,
                                mb: 2,
                                backgroundColor: "#486ce7",
                                color: "#ffffff",
                            }}
                            disabled={isClicked}
                        >
                            <Typography variant={"h6"} fontWeight={"bold"} sx={{
                                textTransform: 'none',
                            }}>
                                Sign In
                            </Typography>
                        </Button>
                        <Grid container display={"flex"} flexDirection={"row"} justifyContent={"space-between"}>
                            <Button
                                startIcon={<MailOutlineIcon />}
                                href="/forgot_password"
                                sx={{
                                    backgroundColor: "#486ce7",
                                    color: "#ffffff",
                                    width: '48%',
                                    ":hover": {
                                        backgroundColor: "#c40000",
                                        color: "#ffffff",
                                    }
                                }}
                            >
                                <Typography variant={"body2"} sx={{
                                    textTransform: 'none',
                                }}>
                                    Reset Password
                                </Typography>
                            </Button>
                            <Button
                                startIcon={<PersonAddIcon />}
                                href="/signup"
                                sx={{
                                    backgroundColor: "#486ce7",
                                    color: "#ffffff",
                                    width: '48%',
                                    ":hover": {
                                        backgroundColor: "#c40000",
                                        color: "#ffffff",
                                    }
                                }}
                            >
                                <Typography variant={"body2"} sx={{
                                    textTransform: 'none',
                                }}>
                                    Sign Up
                                </Typography>
                            </Button>
                        </Grid>
                        <Copyright sx={{mt: 5}}/>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
}