import {keyframes} from "@mui/system";
import {styled, useTheme} from "@mui/material";

export default function Loader() {
    const theme = useTheme();
    const darkMode = theme.palette.mode === 'dark';
    const color = darkMode ? '#ffffff' : '#000000';

    const l26 = keyframes`
    12.5% {box-shadow:
    19px -19px 0 0px, 38px -19px 0 0px, 57px -19px 0 5px,
        19px 0     0 5px, 38px 0     0 0px, 57px 0     0 5px,
        19px 19px  0 0px, 38px 19px  0 0px, 57px 19px  0 0px}
    25%   {box-shadow:
    19px -19px 0 5px, 38px -19px 0 0px, 57px -19px 0 5px,
        19px 0     0 0px, 38px 0     0 0px, 57px 0     0 0px,
        19px 19px  0 0px, 38px 19px  0 5px, 57px 19px  0 0px}
    50%   {box-shadow:
    19px -19px 0 5px, 38px -19px 0 5px, 57px -19px 0 0px,
        19px 0     0 0px, 38px 0     0 0px, 57px 0     0 0px,
        19px 19px  0 0px, 38px 19px  0 0px, 57px 19px  0 5px}
    62.5% {box-shadow:
    19px -19px 0 0px, 38px -19px 0 0px, 57px -19px 0 0px,
        19px 0     0 5px, 38px 0     0 0px, 57px 0     0 0px,
        19px 19px  0 0px, 38px 19px  0 5px, 57px 19px  0 5px}
    75%   {box-shadow:
    19px -19px 0 0px, 38px -19px 0 5px, 57px -19px 0 0px,
        19px 0     0 0px, 38px 0     0 0px, 57px 0     0 5px,
        19px 19px  0 0px, 38px 19px  0 0px, 57px 19px  0 5px}
    87.5% {box-shadow:
    19px -19px 0 0px, 38px -19px 0 5px, 57px -19px 0 0px,
        19px 0     0 0px, 38px 0     0 5px, 57px 0     0 0px,
        19px 19px  0 5px, 38px 19px  0 0px, 57px 19px  0 0px}
`;

    const LoaderBox = styled('div')`
    margin: auto;
    width: 4px;
    color: ${color};
    aspect-ratio: 1;
    border-radius: 50%;
    box-shadow: 19px -19px 0 0px, 38px -19px 0 0px, 57px -19px 0 0px, 19px 0 0 5px, 38px 0 0 5px, 57px 0 0 5px, 19px 19px 0 0px, 38px 19px 0 0px, 57px 19px 0 0px;
    transform: translateX(-38px);
    animation: ${l26} 2s infinite linear;
`;
    return <LoaderBox/>
}