import Grid from "@mui/material/Grid";
import {Driver} from "../../../data/models/bracket_api/Driver";
import Typography from "@mui/material/Typography";
import {
    Dialog, DialogActions,
    DialogContent,
    DialogTitle, FormControl, InputLabel,
    MenuItem,
    Select,
    useMediaQuery,
    useTheme
} from "@mui/material";
import TextField from "@mui/material/TextField";
import {Fragment, useState} from "react";
import {Qualifier} from "../../../data/models/bracket_api/Qualifier";
import {RaceClass} from "../../../data/models/bracket_api/RaceClass";
import * as React from "react";
import Button from "@mui/material/Button";
import {PrimaryButtonStyles} from "../../../styles/ButtonStyles";

type QualifierRowProps = {
    qualifier: Qualifier,
    drivers: Driver[],
    editQualifier: (division: RaceClass, seed: number, elapsed_time?: number, speed?: number, driver?: Driver) => void,
    division: string,
    qualifiers: Qualifier[],
}

function QualifierRow(props: Readonly<QualifierRowProps>) {
    const [driver, setDriver] = useState<Driver | undefined>(props.qualifier.driver);

    function onChange(driver?: Driver, elapsedTime?: number,  speed?: number) {
        props.editQualifier(
            props.qualifier.race_class,
            props.qualifier.seed,
            elapsedTime,
            speed,
            driver
        );
    }

    return (
        <Grid container sx={{
            display: 'flex',
            flexDirection: 'row',
            minWidth: '100%',
            flexWrap: 'nowrap',
            alignItems: 'center',
        }}>
            <Typography variant={'h6'} align={'left'} sx={{
                marginRight: '12px',
            }}>
                {props.qualifier.seed}
            </Typography>
            <FormControl sx={{
                marginY: "12px",
                marginX: '12px',
            }}>
                <InputLabel id={"driver-label" + props.division + props.qualifier.seed}>Driver</InputLabel>
                <Select
                    labelId={"driver-label" + props.division + props.qualifier.seed}
                    label={"Driver"}
                    name={'driver'}
                    defaultValue={props.qualifier.driver?.id}
                    sx={{
                        minWidth: '200px',
                    }}
                    value={driver?.id}
                    onChange={(event) => {
                        let driver = props.drivers.find(driver => driver.id === event.target.value);
                        setDriver(driver)
                        onChange(driver);
                    }}
                >
                    {
                        props.drivers.filter((driver2) => {return props.qualifiers.find((qualifier) => {
                            return qualifier.driver === driver2 && qualifier.seed !== props.qualifier.seed
                        }) === undefined}).map((driver: Driver) => {
                            return <MenuItem key={driver.id}
                                             value={driver.id}>{driver.first_name + " " + driver.last_name}</MenuItem>
                        })
                    }
                </Select>
            </FormControl>
            <TextField
                type={'number'}
                label="Qualifying Time"
                variant="filled"
                name={'elapsed-time'}
                defaultValue={props.qualifier.elapsed_time}
                sx={{
                    width: 'fit-content',
                    minWidth: '125px',
                    marginY: 1,
                    marginRight: '12px',
                }}
                onChange={(event) => {
                    if (event.target.value === '') {
                        onChange(driver, 0, props.qualifier.speed);
                        return;
                    } else {
                        onChange(driver, parseFloat(event.target.value), props.qualifier.speed);
                    }
                }}
            />
            <TextField
                type={'number'}
                label="Speed (MPH)"
                variant="filled"
                name={'speed'}
                defaultValue={props.qualifier.speed}
                sx={{
                    width: 'fit-content',
                    minWidth: '125px',
                    marginY: 1,
                    marginRight: '12px',
                }}
                onChange={(event) => {
                    if (event.target.value === '') {
                        onChange(driver, props.qualifier.elapsed_time, 0);
                    } else {
                        onChange(driver, props.qualifier.elapsed_time, parseFloat(event.target.value));
                    }
                }}
            />
        </Grid>
    )
}

type QualifierFormProps = {
    drivers: Driver[],
    division: string
    qualifiers: Qualifier[],
    editQualifier: (division: RaceClass, seed: number, elapsed_time?: number, speed?: number, driver?: Driver) => void,
    adjustNumberOfQualifiers: (division: RaceClass, numberOfQualifiers: number) => void,
}
export default function QualifierForm(props: Readonly<QualifierFormProps>) {
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Fragment>
            <Button variant="contained" onClick={handleClickOpen} sx={{
                backgroundColor: "#486ce7",
                color: "#ffffff",
                borderRadius: 0,
                ":hover": {
                    backgroundColor: "#c40000",
                    color: "#ffffff",
                },
                width: '100%',
            }}>
                <Typography variant={'h6'}>
                    Edit {props.division} Qualifiers
                </Typography>
            </Button>
            <Dialog
                fullScreen={fullScreen}
                maxWidth={'lg'}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                sx={{
                    margin: 'auto',
                    minWidth: '900px'
                }}
            >
                <DialogTitle id="responsive-dialog-title">
                    {props.division} Qualifiers
                </DialogTitle>
                <DialogContent>
                    <TextField
                        type={'number'}
                        label="Number of Qualifiers"
                        variant="filled"
                        defaultValue={props.qualifiers.length}
                        name={'number-of-qualifiers'}
                        sx={{
                            width: '100%',
                            marginY: 1,
                            marginRight: '12px',
                        }}
                        onChange={(event) => {
                            if (event.target.value === '') {
                                props.adjustNumberOfQualifiers(props.division as RaceClass, 0);
                                return;
                            }
                            props.adjustNumberOfQualifiers(props.division as RaceClass, parseInt(event.target.value));
                        }}
                    />

                    {
                        props.qualifiers.map((qualifier: Qualifier) => {
                            return <QualifierRow key={qualifier.race_class + qualifier.seed}
                                                 qualifier={qualifier}
                                                 qualifiers={props.qualifiers}
                                                 drivers={props.drivers}
                                                 editQualifier={props.editQualifier} division={props.division}/>
                        })
                    }
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}