import {Result} from "../../../data/models/bracket_api/Result";
import {Pick} from "../../../data/models/bracket_api/Pick";
import {PickStatus} from "../../../data/models/bracket_api/PickStatus";
import {FourWidePick} from "../../../data/models/bracket_api/FourWidePick";
import {FourWideResult} from "../../../data/models/bracket_api/FourWideResult";
import {Driver} from "../../../data/models/bracket_api/Driver";
import {getRaceExceptionString, RaceException} from "../../../data/models/bracket_api/RaceException";
import {SyntheticEvent} from "react";
import Helmet from "../../../assets/images/helmet.png";


export function determineActualDriver(pick: Pick | FourWidePick | undefined, competitor_number: number, result: Result | FourWideResult | undefined): Driver | undefined {
    if (competitor_number === 1) {
        return result?.competitor_1;
    } else if (competitor_number === 2) {
        return result?.competitor_2;
    } else if (competitor_number === 3) {
        return (result as FourWideResult)?.competitor_3;
    } else if (competitor_number === 4) {
        return (result as FourWideResult)?.competitor_4;
    }
}

export function determinePickRowText(competitor_number: number, pick: Pick | FourWidePick | undefined, result: Result | FourWideResult | undefined){
    let driver: Driver | undefined;
    let seed: number | undefined;
    let time_or_condition: string = "";
    if (competitor_number === 1) {
        driver = pick?.competitor_1;
        seed = pick?.seed_1;
        if (result?.competitor_1_exception !== undefined && result?.competitor_1_exception !== RaceException.NONE) {
            time_or_condition = result?.competitor_1_exception;

        } else if (result?.competitor_1_time !== undefined && result?.competitor_1_time !== null) {
            time_or_condition = result?.competitor_1_time.toString();
        }
    } else if (competitor_number === 2) {
        driver = pick?.competitor_2;
        seed = pick?.seed_2;
        if (result?.competitor_2_exception !== undefined && result?.competitor_2_exception !== RaceException.NONE) {
            time_or_condition = result?.competitor_2_exception;
        } else if (result?.competitor_2_time !== undefined && result?.competitor_2_time !== null) {
            time_or_condition = result?.competitor_2_time.toString();
        }
    } else if (competitor_number === 3) {
        driver = (pick as FourWidePick)?.competitor_3;
        seed = (pick as FourWidePick)?.seed_3;
        let result2 = result as FourWideResult;
        if (result2?.competitor_3_exception !== undefined && result2?.competitor_3_exception !== RaceException.NONE) {
            time_or_condition = result2?.competitor_3_exception;
        } else if (result2?.competitor_3_time !== undefined && result2?.competitor_3_time !== null) {
            time_or_condition = result2?.competitor_3_time.toString();
        }
    } else if (competitor_number === 4) {
        driver = (pick as FourWidePick)?.competitor_4;
        seed = (pick as FourWidePick)?.seed_4;
        let result2 = result as FourWideResult;
        if (result2?.competitor_4_exception !== undefined && result2?.competitor_4_exception !== RaceException.NONE) {
            time_or_condition = result2?.competitor_4_exception;
        } else if (result2?.competitor_4_time !== undefined && result2?.competitor_4_time !== null) {
            time_or_condition = result2?.competitor_4_time.toString();
        }
    }
    if(time_or_condition === "None"){
        time_or_condition = "";
    }
    return {driver, seed, time_or_condition};
}
export function determinePickBackgroundColor(picks_locked: boolean,
                                      fourWide: boolean,
                                      result: Result | FourWideResult | undefined,
                                      pick: Pick | FourWidePick | undefined,
                                      driver: Driver | undefined,
                                      competitor_number: number,
                                      successColor: string, rowColor: string) {
    if (picks_locked) {
        if (fourWide) {
            let result2 = result as FourWideResult;
            let pick2 = pick as FourWidePick;
            let placing = result2?.first === driver ? 1 : (result2?.second === driver ? 2 : (result2?.third === driver ? 3 : (result2?.fourth === driver ? 4 : 0)));
            if(result2 === undefined){
                return rowColor;
            }
            switch (competitor_number) {
                case 1:
                    if (result2.competitor_1 && result2.competitor_1 !== driver) {
                        return rowColor;
                    }
                    switch (placing) {
                        case 1:
                            if (driver === pick2.first) {
                                return successColor;
                            }
                            return rowColor;
                        case 2:
                            if (driver === pick2.second) {
                                return successColor;
                            }
                            return rowColor;
                        case 3:
                            if (driver === pick2.third) {
                                return successColor;
                            }
                            return rowColor;
                        case 4:
                            if (driver === pick2.fourth) {
                                return successColor;
                            }
                            return rowColor;
                        default:
                            return rowColor;
                    }
                case 2:
                    if (result2.competitor_2 && result2.competitor_2 !== driver) {
                        return rowColor;
                    }
                    switch (placing) {
                        case 1:
                            if (driver === pick2.first) {
                                return successColor;
                            }
                            return rowColor;
                        case 2:
                            if (driver === pick2.second) {
                                return successColor;
                            }
                            return rowColor;
                        case 3:
                            if (driver === pick2.third) {
                                return successColor;
                            }
                            return rowColor;
                        case 4:
                            if (driver === pick2.fourth) {
                                return successColor;
                            }
                            return rowColor;
                        default:
                            return rowColor;
                    }
                case 3:
                    if (result2.competitor_3 && result2.competitor_3 !== driver) {
                        return rowColor;
                    }
                    switch (placing) {
                        case 1:
                            if (driver === pick2.first) {
                                return successColor;
                            }
                            return rowColor;
                        case 2:
                            if (driver === pick2.second) {
                                return successColor;
                            }
                            return rowColor;
                        case 3:
                            if (driver === pick2.third) {
                                return successColor;
                            }
                            return rowColor;
                        case 4:
                            if (driver === pick2.fourth) {
                                return successColor;
                            }
                            return rowColor;
                        default:
                            return rowColor;
                    }
                case 4:
                    if (result2.competitor_4 && result2.competitor_4 !== driver) {
                        return rowColor;
                    }
                    switch (placing) {
                        case 1:
                            if (driver === pick2.first) {
                                return successColor;
                            }
                            return rowColor;
                        case 2:
                            if (driver === pick2.second) {
                                return successColor;
                            }
                            return rowColor;
                        case 3:
                            if (driver === pick2.third) {
                                return successColor;
                            }
                            return rowColor;
                        case 4:
                            if (driver === pick2.fourth) {
                                return successColor;
                            }
                            return rowColor;
                        default:
                            return rowColor;
                    }
            }
        } else {
            let result2 = result as Result;
            let pick2 = pick as Pick;
            if(pick2 === undefined){
                return rowColor;
            }
            if (pick2.pick_status === PickStatus.CORRECT && driver === result2.winner) {
                return successColor;
            }
            if (pick2.pick_status === PickStatus.INCORRECT && driver === pick2.winner) {
                return "#FF0000";
            }
            switch (competitor_number) {
                case 1:
                    if (result2.competitor_1 && result2.competitor_1 !== driver) {
                        return "#FF0000";
                    } else if (result2.winner && result2.competitor_1 && result2.competitor_1 === driver && driver !== result2.winner && driver === pick2.winner) {
                        return "#FF0000";
                    } else if (result2.competitor_1 && result2.competitor_1 === driver && driver === result2.winner && driver === pick2.winner) {
                        return successColor;
                    } else {
                        return rowColor;
                    }
                case 2:
                    if (result2.competitor_2 && result2.competitor_2 !== driver) {
                        return "#FF0000";
                    } else if (result2.winner && result2.competitor_2 && result2.competitor_2 === driver && driver !== result2.winner && driver === pick2.winner) {
                        return "#FF0000";
                    } else if (result2.competitor_2 && result2.competitor_2 === driver && driver === result2.winner && driver === pick2.winner) {
                        return successColor;
                    } else {
                        return rowColor;
                    }
            }
        }
    } else if (driver !== undefined) {
        if (fourWide) {
            let pick2 = pick as FourWidePick;
            if (driver === pick2.first || (driver === pick2.second && result?.round !== 3)) {
                return successColor;
            } else {
                return rowColor;
            }
        } else {
            let pick2 = pick as Pick;
            if (driver === pick2.winner) {
                return successColor;
            } else {
                return rowColor;
            }
        }
    } else {
        return rowColor;
    }
}

export function determinePickTextColor(picks_locked: boolean, pick: Pick | FourWidePick | undefined, driver: Driver | undefined, result: Result | FourWideResult | undefined, fourWide: boolean, textColor: string) {
    if (picks_locked) {
        switch (pick?.pick_status) {
            case(PickStatus.CORRECT):
                if (fourWide) {
                    return textColor;
                } else {
                    if ((result as Result)?.winner === driver) {
                        return "#000000";
                    }
                    return textColor;
                }
            default:
                return textColor;
        }

    } else if (driver !== undefined) {
        if (fourWide) {
            let pick2 = pick as FourWidePick;
            if (driver === pick2.first || (driver === pick2.second && result?.round !== 3)) {
                return "#000000";
            } else {
                return textColor;
            }
        } else {
            let pick2 = pick as Pick;
            if (driver === pick2.winner) {
                return "#000000";
            } else {
                return textColor;
            }
        }
    } else {
        return textColor;
    }
}

export function determinePosition(fourWide: boolean, driver: Driver | undefined, pick: Pick | FourWidePick | undefined) : "" | "1st" | "2nd" | "3rd" | "4th" {
    let position: "" | "1st" | "2nd" | "3rd" | "4th"  = "";
    if (fourWide) {
        if (driver !== undefined) {
            switch (driver) {
                case (pick as FourWidePick).first:
                    position = "1st";
                    break;
                case (pick as FourWidePick).second:
                    position = "2nd";
                    break;
                case (pick as FourWidePick).third:
                    position = "3rd";
                    break;
                case (pick as FourWidePick).fourth:
                    position = "4th";
                    break;
                default:
                    position = "";
                    break;
            }
        }
    }
    return position;
}

export function determineActualPosition(fourWide: boolean, result: Result | FourWideResult | undefined, actualDriver: Driver | undefined) : "" | "1st" | "2nd" | "3rd" | "4th" {
    let position: "" | "1st" | "2nd" | "3rd" | "4th"  = "";
    if (fourWide) {
        if (actualDriver !== undefined) {
            switch (actualDriver) {
                case (result as FourWideResult).first:
                    position = "1st";
                    break;
                case (result as FourWideResult).second:
                    position = "2nd";
                    break;
                case (result as FourWideResult).third:
                    position = "3rd";
                    break;
                case (result as FourWideResult).fourth:
                    position = "4th";
                    break;
                default:
                    position = "";
                    break;
            }
        }
    }
    return position;
}

export function handleClick(driver: Driver | undefined, picks_locked: boolean, fourWide: boolean, pick: Pick | FourWidePick | undefined, seed: number, onClick: (pick: Pick | FourWidePick) => void){
    if (driver !== undefined && !picks_locked) {
        if (fourWide) {
            let pick2 = pick as FourWidePick;
            let driver_order = [
                [pick2.first, pick2.first_seed],
                [pick2.second, pick2.second_seed],
                [pick2.third, pick2.third_seed],
                [pick2.fourth, pick2.fourth_seed]
            ];
            let driver_index = driver_order.findIndex((x) => x[0] === driver);
            if (driver_index === -1) {
                if (pick2.first === undefined) {
                    pick2.first = driver;
                    pick2.first_seed = seed;
                } else if (pick2.second === undefined) {
                    pick2.second = driver;
                    pick2.second_seed = seed;
                } else if (pick2.third === undefined) {
                    pick2.third = driver;
                    pick2.third_seed = seed;
                } else if (pick2.fourth === undefined) {
                    pick2.fourth = driver;
                    pick2.fourth_seed = seed;
                } else {
                    return;
                }
            } else {
                // Remove driver from slot and bump up other drivers if needed
                if (driver_index === 0) {
                    pick2.first = pick2.second;
                    pick2.first_seed = pick2.second_seed;
                    pick2.second = pick2.third;
                    pick2.second_seed = pick2.third_seed;
                    pick2.third = pick2.fourth;
                    pick2.third_seed = pick2.fourth_seed;
                    pick2.fourth = undefined;
                    pick2.fourth_seed = undefined;
                } else if (driver_index === 1) {
                    pick2.second = pick2.third;
                    pick2.second_seed = pick2.third_seed;
                    pick2.third = pick2.fourth;
                    pick2.third_seed = pick2.fourth_seed;
                    pick2.fourth = undefined;
                    pick2.fourth_seed = undefined;
                } else if (driver_index === 2) {
                    pick2.third = pick2.fourth;
                    pick2.third_seed = pick2.fourth_seed;
                    pick2.fourth = undefined;
                    pick2.fourth_seed = undefined;
                } else if (driver_index === 3) {
                    pick2.fourth = undefined;
                    pick2.fourth_seed = undefined;
                }
            }
            onClick(pick2);
        } else {
            let pick2 = pick as Pick;
            if (pick2.winner && driver === pick2.winner) {
                pick2.winner = undefined;
                pick2.winner_seed = undefined;
                pick2.loser = undefined;
                pick2.loser_seed = undefined;
            } else {
                pick2.winner = driver;
                pick2.winner_seed = seed;
                pick2.loser = driver === pick2.competitor_1 ? pick2.competitor_2 : pick2.competitor_1;
                pick2.loser_seed = driver === pick2.competitor_1 ? pick2.seed_2 : pick2.seed_1;
            }
            onClick(pick2);
        }
    }
}

export const handleError = (event: SyntheticEvent<HTMLImageElement, Event>) => {
    event.currentTarget.src = Helmet;
};