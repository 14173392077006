import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";

export default function LeagueRowSkeleton(){
    return(
        <Grid container direction={'column'} sx={{width: '100%'}}>
            <Skeleton variant="text" width={'100%'} height={220}>
            </Skeleton>
        </Grid>
    )
}