import {DriverDetailProps} from "./ResultsCard";
import Grid from "@mui/material/Grid";
import GaugeChart from "react-gauge-chart";
import "../../../styles/ResultDetailStyles.css";
import Typography from "@mui/material/Typography";
import {Divider, Theme, useMediaQuery} from "@mui/material";
import {SyntheticEvent, useEffect, useState} from "react";
import Helmet from "../../../assets/images/helmet.png";

type ResultDetailDriverColumnProps = {
    details: DriverDetailProps,
    sx: any,
    seed?: number,
    qualifying_time?: number,
}
export const handleError = (event: SyntheticEvent<HTMLImageElement, Event>) => {
    event.currentTarget.src = Helmet;
};

export default function ResultDetailDialogDriverColumn(props: Readonly<ResultDetailDriverColumnProps>) {
    const [isMdOrUp, setIsMdOrUp] = useState(useMediaQuery((theme: Theme) => theme.breakpoints.up('md')));

    useEffect(() => {

        const handleResize = () => {
            setIsMdOrUp(window.innerWidth >= 960);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [isMdOrUp]);


    return (
        <Grid container direction={"column"} sx={props.sx} flexWrap={"nowrap"}>
            <Typography variant={"body1"} textAlign={"center"} fontWeight={"bolder"} marginY={1}
                        height={isMdOrUp ? '60px' : '45px'} overflow={'hidden'}>
                {props.details.driver.first_name + " " + props.details.driver.last_name}
            </Typography>
            <Divider sx={{width: "100%"}}/>
            {props.details.driver.waist_up !== null ?
                <img
                    src={props.details.driver.waist_up}
                    alt={`${props.details.driver.first_name} 
                ${props.details.driver.last_name}`}
                    className={"result-detail-driver-image"}
                    style={{width: isMdOrUp ? "200px" : "90px", height: isMdOrUp ? "220px" : "99px"}}
                    onError={handleError}
                />
                :
                <img
                    src={Helmet}
                    alt={`${props.details.driver.first_name} 
                ${props.details.driver.last_name}`}
                    className={"result-detail-driver-image"}
                    style={{
                        width: isMdOrUp ? "200px" : "90px",
                        height: isMdOrUp ? "220px" : "99px"
                    }}
                />
            }
            <Divider sx={{width: "100%"}}/>
            <Typography variant={isMdOrUp ? "h6" : "body1"} textAlign={"center"} height={isMdOrUp ? '60px' : '25px'}
                        overflow={'hidden'} fontWeight={isMdOrUp ? "bold" : "normal"} marginY={1}>
                {props.seed}
            </Typography>
            <Typography variant={isMdOrUp ? "h6" : "body1"} textAlign={"center"} height={isMdOrUp ? '60px' : '25px'}
                        overflow={'hidden'} fontWeight={isMdOrUp ? "bold" : "normal"} marginY={1}>
                {props.qualifying_time || ""}
            </Typography>
            <Typography variant={isMdOrUp ? "h6" : "body1"} textAlign={"center"} height={isMdOrUp ? '60px' : '25px'}
                        overflow={'hidden'} fontWeight={isMdOrUp ? "bold" : "normal"} marginY={1}>
                {props.details.driver.career_best_et ? `${props.details.driver.career_best_et}` : "Unknown"}
            </Typography>
            <Typography variant={isMdOrUp ? "h6" : "body1"} textAlign={"center"} height={isMdOrUp ? '60px' : '25px'}
                        overflow={'hidden'} fontWeight={isMdOrUp ? "bold" : "normal"} marginY={1}>
                {props.details.driver.career_best_speed ? `${Number(props.details.driver.career_best_speed).toFixed(2)} MPH` : "Unknown"}
            </Typography>
            <Typography variant={isMdOrUp ? "h6" : "body1"} textAlign={"center"} height={isMdOrUp ? '60px' : '25px'}
                        overflow={'hidden'} fontWeight={isMdOrUp ? "bold" : "normal"} marginY={1}>
                {`${props.details.season_record.wins}-${props.details.season_record.losses}`}
            </Typography>
            {props.details.pick_percentage !== null &&
                <GaugeChart
                    nrOfLevels={2}
                    arcsLength={[props.details.pick_percentage / 100, 1 - props.details.pick_percentage / 100]}
                    colors={["#486ce7", "#aaa"]}
                    animate={false}
                    cornerRadius={0}
                    percent={props.details.pick_percentage / 100}
                    style={{
                        width: isMdOrUp ? "150px" : "60px",
                    }}
                />
            }
        </Grid>
    )
}