import Grid from "@mui/material/Grid";
import {Divider, MenuItem, useMediaQuery, useTheme} from "@mui/material";
import Paper from "@mui/material/Paper";
import {Fragment, SyntheticEvent, useState} from "react";
import {
    getRaceExceptionFromString,
    getRaceExceptionString,
    RaceException
} from "../../../../data/models/bracket_api/RaceException";
import Helmet from "../../../../assets/images/helmet.png";
import {Driver} from "../../../../data/models/bracket_api/Driver";
import * as React from "react";
import {FourWideResult} from "../../../../data/models/bracket_api/FourWideResult";
import ResultRow from "../ConfigRow";
import FourWideResultForm from "../../../admin/event_config/FourWideResultForm";
import ResultRowReverse from "../../results/ResultRowReverse";
import ConfigRowReverse from "../ConfigRowReverse";

type TwoWideConfigBlockProps = {
    result?: FourWideResult,
    updateResult?: (result: FourWideResult) => void
}

export default function FourWideConfigBlockReverse(props: Readonly<TwoWideConfigBlockProps>) {
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));
    const [firstId, setFirstId] = useState<number>(props.result?.first?.id ?? 0);
    const [secondId, setSecondId] = useState<number>(props.result?.second?.id ?? 0);
    const [thirdId, setThirdId] = useState<number>(props.result?.third?.id ?? 0);
    const [fourthId, setFourthId] = useState<number>(props.result?.fourth?.id ?? 0);
    const [exception1, setException1] = useState<RaceException>(RaceException.NONE);
    const [exception2, setException2] = useState<RaceException>(RaceException.NONE);
    const [exception3, setException3] = useState<RaceException>(RaceException.NONE);
    const [exception4, setException4] = useState<RaceException>(RaceException.NONE);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleImageError = (event: SyntheticEvent<HTMLImageElement, Event>) => {
        event.currentTarget.src = Helmet;
    };

    const handleUpdateResult = (event: SyntheticEvent<HTMLFormElement, Event>) => {
        if(props.result && props.updateResult) {
            let data = new FormData(event.currentTarget);
            let competitor_1_time = data.get('competitor_1_time') as unknown as number ?? null;
            let competitor_2_time = data.get('competitor_2_time') as unknown as number ?? null;
            let competitor_3_time = data.get('competitor_3_time') as unknown as number ?? null;
            let competitor_4_time = data.get('competitor_4_time') as unknown as number ?? null;

            let competitor_1_speed = data.get('competitor_1_speed') as unknown as number ?? null;
            let competitor_2_speed = data.get('competitor_2_speed') as unknown as number ?? null;
            let competitor_3_speed = data.get('competitor_3_speed') as unknown as number ?? null;
            let competitor_4_speed = data.get('competitor_4_speed') as unknown as number ?? null;

            let competitor_1_reaction_time = data.get('competitor_1_reaction_time') as unknown as number ?? null;
            let competitor_2_reaction_time = data.get('competitor_2_reaction_time') as unknown as number ?? null;
            let competitor_3_reaction_time = data.get('competitor_3_reaction_time') as unknown as number ?? null;
            let competitor_4_reaction_time = data.get('competitor_4_reaction_time') as unknown as number ?? null;

            let updatedResult: FourWideResult = {
                ...props.result,
                competitor_1_time: competitor_1_time,
                competitor_2_time: competitor_2_time,
                competitor_3_time: competitor_3_time,
                competitor_4_time: competitor_4_time,
                competitor_1_speed: competitor_1_speed,
                competitor_2_speed: competitor_2_speed,
                competitor_3_speed: competitor_3_speed,
                competitor_4_speed: competitor_4_speed,
                competitor_1_reaction_time: competitor_1_reaction_time,
                competitor_2_reaction_time: competitor_2_reaction_time,
                competitor_3_reaction_time: competitor_3_reaction_time,
                competitor_4_reaction_time: competitor_4_reaction_time,
            }
            props.updateResult(updatedResult);
        }
    }

    return (
        <Fragment>
            <Grid container component={Paper} m={1} sx={{
                display: 'flex',
                flexDirection: "column",
                height: 'fit-content',
                width: 'fit-content',
                cursor: 'pointer'
            }}
                  onClick={() => {
                      handleClickOpen()
                  }}
            >
                <ConfigRowReverse
                    competition_number={1}
                    result={props.result}
                    seed={props.result?.seed_1}
                    driver={props.result?.competitor_1}
                    winner={props.result?.competitor_1 && (props.result?.first?.id === props.result?.competitor_1.id || props.result?.second?.id === props.result?.competitor_1.id)}
                    time_or_condition={props.result?.competitor_1_exception !== RaceException.NONE && props.result?.competitor_1_exception ? props.result?.competitor_1_exception : props.result?.competitor_1_time as number}
                />
                <Divider/>
                <ConfigRowReverse
                    competition_number={2}
                    result={props.result}
                    seed={props.result?.seed_2}
                    driver={props.result?.competitor_2}
                    winner={props.result?.competitor_2 && (props.result?.first?.id === props.result?.competitor_2.id || props.result?.second?.id === props.result?.competitor_2.id)}
                    time_or_condition={props.result?.competitor_2_exception !== RaceException.NONE && props.result?.competitor_2_exception ? props.result?.competitor_2_exception : props.result?.competitor_2_time as number}
                />
                <Divider/>
                <ConfigRowReverse
                    competition_number={3}
                    result={props.result}
                    seed={props.result?.seed_3}
                    driver={props.result?.competitor_3}
                    winner={props.result?.competitor_3 && (props.result?.first?.id === props.result?.competitor_3.id || props.result?.second?.id === props.result?.competitor_3.id)}
                    time_or_condition={props.result?.competitor_3_exception !== RaceException.NONE && props.result?.competitor_3_exception ? props.result?.competitor_3_exception : props.result?.competitor_3_time as number}
                />
                <Divider/>
                <ConfigRowReverse
                    competition_number={4}
                    result={props.result}
                    seed={props.result?.seed_4}
                    driver={props.result?.competitor_4}
                    winner={props.result?.competitor_4 && (props.result?.first?.id === props.result?.competitor_4.id || props.result?.second?.id === props.result?.competitor_4.id)}
                    time_or_condition={props.result?.competitor_4_exception !== RaceException.NONE && props.result?.competitor_4_exception ? props.result?.competitor_4_exception : props.result?.competitor_4_time as number}
                />
            </Grid>
            <FourWideResultForm
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                result={props.result!}
                onImageError={handleImageError}
                changeException1={handleUpdateResult}
                updateException1={(event) => {
                    setException1(getRaceExceptionFromString(event.target.value))
                    if (props.result && props.updateResult) {
                        props.updateResult({
                            ...props.result,
                            competitor_1_exception: getRaceExceptionFromString(event.target.value)
                        });
                    }
                }}
                updateException2={(event) => {
                    setException2(getRaceExceptionFromString(event.target.value))
                    if (props.result && props.updateResult) {
                        props.updateResult({
                            ...props.result,
                            competitor_2_exception: getRaceExceptionFromString(event.target.value)
                        });
                    }
                }}
                updateException3={(event) => {
                    setException3(getRaceExceptionFromString(event.target.value))
                    if (props.result && props.updateResult) {
                        props.updateResult({
                            ...props.result,
                            competitor_3_exception: getRaceExceptionFromString(event.target.value)
                        });
                    }
                }}
                updateException4={(event) => {
                    setException4(getRaceExceptionFromString(event.target.value))
                    if (props.result && props.updateResult) {
                        props.updateResult({
                            ...props.result,
                            competitor_4_exception: getRaceExceptionFromString(event.target.value)
                        });
                    }
                }}
                exception1Row={(key) => {
                    let exception = getRaceExceptionString(RaceException[key as keyof typeof RaceException])
                    return <MenuItem key={props.result?.game_code + "1" + key} value={exception}>{exception}</MenuItem>
                }}
                exception2Row={(key) => {
                    let exception = getRaceExceptionString(RaceException[key as keyof typeof RaceException])
                    return <MenuItem key={props.result?.game_code + "2" + key} value={exception}>{exception}</MenuItem>
                }}
                exception3Row={(key) => {
                    let exception = getRaceExceptionString(RaceException[key as keyof typeof RaceException])
                    return <MenuItem key={props.result?.game_code + "3" + key} value={exception}>{exception}</MenuItem>
                }}
                exception4Row={(key) => {
                    let exception = getRaceExceptionString(RaceException[key as keyof typeof RaceException])
                    return <MenuItem key={props.result?.game_code + "4" + key} value={exception}>{exception}</MenuItem>
                }}
                raceException={exception1}
                raceException1={exception2}
                raceException2={exception3}
                raceException3={exception4}
                firstId={firstId}
                updateFirst={(event) => {
                    setFirstId(event.target.value as number)
                    if (props.result && props.updateResult) {
                        let first_id = event.target.value;
                        let first: Driver | undefined;
                        let first_seed: number | undefined;
                        switch(first_id) {
                            case(props.result?.competitor_1?.id):
                                first = props.result?.competitor_1;
                                first_seed = props.result?.seed_1;
                                break;
                            case(props.result?.competitor_2?.id):
                                first = props.result?.competitor_2;
                                first_seed = props.result?.seed_2;
                                break;
                            case(props.result?.competitor_3?.id):
                                first = props.result?.competitor_3;
                                first_seed = props.result?.seed_3;
                                break;
                            case(props.result?.competitor_4?.id):
                                first = props.result?.competitor_4;
                                first_seed = props.result?.seed_4;
                                break;
                        }
                        props.updateResult({
                            ...props.result,
                            first: first,
                            first_seed: first_seed,
                        })
                    }
                }}
                secondId={secondId}
                updateSecond={(event) => {
                    setSecondId(event.target.value as number)
                    if (props.result && props.updateResult) {
                        let second_id = event.target.value;
                        let second: Driver | undefined;
                        let second_seed: number | undefined;
                        switch(second_id) {
                            case(props.result?.competitor_1?.id):
                                second = props.result?.competitor_1;
                                second_seed = props.result?.seed_1;
                                break;
                            case(props.result?.competitor_2?.id):
                                second = props.result?.competitor_2;
                                second_seed = props.result?.seed_2;
                                break;
                            case(props.result?.competitor_3?.id):
                                second = props.result?.competitor_3;
                                second_seed = props.result?.seed_3;
                                break;
                            case(props.result?.competitor_4?.id):
                                second = props.result?.competitor_4;
                                second_seed = props.result?.seed_4;
                                break;
                        }
                        props.updateResult({
                            ...props.result,
                            second: second,
                            second_seed: second_seed,
                        })
                    }
                }}
                thirdId={thirdId}
                updateThird={(event) => {
                    setThirdId(event.target.value as number)
                    if (props.result && props.updateResult) {
                        let third_id = event.target.value;
                        let third: Driver | undefined;
                        let third_seed: number | undefined;
                        switch(third_id) {
                            case(props.result?.competitor_1?.id):
                                third = props.result?.competitor_1;
                                third_seed = props.result?.seed_1;
                                break;
                            case(props.result?.competitor_2?.id):
                                third = props.result?.competitor_2;
                                third_seed = props.result?.seed_2;
                                break;
                            case(props.result?.competitor_3?.id):
                                third = props.result?.competitor_3;
                                third_seed = props.result?.seed_3;
                                break;
                            case(props.result?.competitor_4?.id):
                                third = props.result?.competitor_4;
                                third_seed = props.result?.seed_4;
                                break;
                        }
                        props.updateResult({
                            ...props.result,
                            third: third,
                            third_seed: third_seed,
                        })
                    }
                }}
                fourthId={fourthId}
                updateFourth={(event) => {
                    setFourthId(event.target.value as number)
                    if (props.result && props.updateResult) {
                        let fourth_id = event.target.value;
                        let fourth: Driver | undefined;
                        let fourth_seed: number | undefined;
                        switch(fourth_id) {
                            case(props.result?.competitor_1?.id):
                                fourth = props.result?.competitor_1;
                                fourth_seed = props.result?.seed_1;
                                break;
                            case(props.result?.competitor_2?.id):
                                fourth = props.result?.competitor_2;
                                fourth_seed = props.result?.seed_2;
                                break;
                            case(props.result?.competitor_3?.id):
                                fourth = props.result?.competitor_3;
                                fourth_seed = props.result?.seed_3;
                                break;
                            case(props.result?.competitor_4?.id):
                                fourth = props.result?.competitor_4;
                                fourth_seed = props.result?.seed_4;
                                break;
                        }
                        props.updateResult({
                            ...props.result,
                            fourth: fourth,
                            fourth_seed: fourth_seed,
                        })
                    }
                }}
            />
        </Fragment>
    )
}