import {Fragment, useState} from "react";
import Button from "@mui/material/Button";
import {
    Alert,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Snackbar,
    useMediaQuery,
    useTheme
} from "@mui/material";
import {delete_user} from "../../apis/Auth";
import * as React from "react";

export default function DeleteConfirmationDialog() {

    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [severity, setSeverity] = useState<"success" | "error" | "info" | "warning" | undefined>("error");
    const [open2, setOpen2] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClose2 = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen2(false);
    };

    async function deleteAccount() {
        let response = await delete_user(localStorage.getItem("access_token")!, localStorage.getItem("refresh_token")!);
        if(response.succeeded){
            setMessage("Account deleted successfully, you will be redirected to the home page");
            setSeverity("success");
            setOpen2(true);
            setTimeout(() => {
                localStorage.clear();
                window.location.href = "/";
            }, 5000);
        }
        else{
            setMessage("Failed to delete account");
            setSeverity("error");
            setOpen2(true);
        }

    }

    return (
        <Fragment>
            <Snackbar anchorOrigin={{vertical: "top", horizontal: "center"}} open={open2} onClose={handleClose2}
                      autoHideDuration={6000}>
                <Alert elevation={6} severity={severity} sx={{width: '100%'}} variant="filled">
                    {message}
                </Alert>
            </Snackbar>
            <Button variant="contained" onClick={handleClickOpen} sx={{
                backgroundColor: 'red'
            }}>
                Delete Account
            </Button>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    {"Are you sure you want to delete your account?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        You are about to delete all personal information of yourself from Drag Race Bracket Bonanza. This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button onClick={deleteAccount} autoFocus>
                        Delete Me
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}