import Skeleton from '@mui/material/Skeleton';
import Grid from "@mui/material/Grid";

export default function EventRowSkeleton() {
    return (
        <Grid container sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            p: 2,
            m: 2,
            width: '100%',
            height: '100px',
            boxShadow: 1,
        }}>
            <Skeleton variant="text" width={210} height={40} />
            <Skeleton variant="text" width={210} height={40} />
            <Skeleton variant="text" width={210} height={40} />
            <Skeleton variant="circular" width={40} height={40} />
        </Grid>
    );
}