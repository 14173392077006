import {Theme, Typography, useMediaQuery} from "@mui/material";
import Layout from "../utilities/Layout";
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import {Helmet} from "react-helmet";


export default function About() {
    const [isMdOrUp, setIsMdOrUp] = useState(useMediaQuery((theme: Theme) => theme.breakpoints.up('md')));
    useEffect(() => {
        const handleResize = () => {
            setIsMdOrUp(window.innerWidth >= 960);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [isMdOrUp]);

    return (
        <Layout>
            <Helmet>
                <title>DRBB | About Us</title>
            </Helmet>
            <Box sx={{minHeight: '70vh', height: 'fit-content', display: 'flex', width: '100%', p: 2}}>
                <Paper elevation={3} sx={{
                    width: isMdOrUp ? "60vw" : "90vw",
                    m: "auto",
                    borderRadius: '10px',
                    height: 'fit-content',
                    p: 2
                }}>
                    <Typography
                        variant="h3"
                        textAlign="center"
                        fontWeight={"bold"}>
                        About
                        Us
                    </Typography>
                    <Divider/>
                    <Typography mt={2} ml={2} mr={2} variant="h6" fontSize={isMdOrUp ? "1.1rem !important" : ""}>
                        This site is the culmination of years of “Why doesn’t someone…?” or “When will someone…?” make a
                        dynamic bracket game for NHRA drag racing. We are the first free dynamic bracket game for
                        professional drag racing. We are lucky enough to have bracket action nearly two dozen times a
                        year
                        with amazing races and personalities.
                    </Typography>
                    <Typography mt={isMdOrUp ? 5 : 2} ml={2} mr={2} variant="h6"
                                fontSize={isMdOrUp ? "1.1rem !important" : ""}>
                        Last year we introduced ourselves to the drag racing community during the U.S. Nationals and the
                        Countdown. By the end of the season, we had thousands of people playing from across the country
                        and
                        around the world. In 2024 we have made Drag Race Bracket Bonanza better with more photos, more
                        stats, league play and a more streamlined bracket picking experience.
                    </Typography>
                    <Typography mt={isMdOrUp ? 5 : 2} ml={2} mr={2} variant="h6"
                                fontSize={isMdOrUp ? "1.1rem !important" : ""}>
                        It is easy to play. Sign-up for free and we will let you know when the brackets are open with
                        either
                        an
                        email or text. Fill out your brackets for all four professional classes. You earn points for
                        your
                        picks and at
                        the end of the race we’ll award prizes to the overall winner and each class winner (Top Fuel,
                        Funny
                        Car,
                        Pro Stock and Pro Stock Motorcycle). Five prizes every race and we’ll have several special
                        series
                        with
                        more free prizes. Let’s have some fun. Watch the race on FS1 or FOX.
                    </Typography>
                    <Typography mt={isMdOrUp ? 5 : 2} ml={2} mr={2} variant="h6"
                                fontSize={isMdOrUp ? "1.1rem !important" : ""}>
                        We want you to offer feedback and tell your friends about us. Our goal is to make this the MUST
                        play
                        interactive bracket for drag racing.
                        Thanks for giving us a chance and good luck with your picks!
                    </Typography>
                </Paper>
            </Box>
        </Layout>
    )
}