import Grid from "@mui/material/Grid";
import Logo from "../../../../assets/images/logo.png";
import {Bracket} from "../../../../data/models/bracket_api/Bracket";
import {Event} from "../../../../data/models/bracket_api/Event";
import {FourWidePick} from "../../../../data/models/bracket_api/FourWidePick";
import {FourWideResult} from "../../../../data/models/bracket_api/FourWideResult";
import {RaceClass} from "../../../../data/models/bracket_api/RaceClass";
import Typography from "@mui/material/Typography";
import PrintBracketFourWide from "./PrintBracketFourWide";

type FourWidePrintBracketContainerProps = {
    picks_locked?: boolean
    brackets? : Bracket[],
    updatePick: (pick: FourWidePick) => void,
    results?: FourWideResult[]
    picks?: FourWidePick[],
    event?: Event,
    title: string
}
export default function PrintBracketContainerFourWide({brackets, picks_locked, updatePick, results, picks, event, title}: Readonly<FourWidePrintBracketContainerProps>) {
    return (
        <Grid
            container
            sx={{
                width: '100%',
                height: '100%',
                overflow: 'auto',
                userSelect: 'none',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
            }}
        >
            <Grid container sx={{
                display: 'flex',
                flexDirection: "column",
                justifyContent: "space-evenly",
                alignItems: "center",
                alignContent: "center",
                height: 'fit-content',
                width: 'fit-content',
                margin: 'auto',
            }}>
                <Grid container sx={{
                    marginY: 1,
                    display: 'flex',
                    flexDirection: "row",
                    flexWrap: "nowrap",
                    width: 'fit-content',
                    position: 'relative',
                }}>
                    <PrintBracketFourWide
                        race_class={RaceClass.TOP_FUEL}
                        picks={picks?.filter((pick) => {
                                return pick.result.bracket.race_class === RaceClass.TOP_FUEL;
                            })}
                        picks_locked={picks_locked}
                        updatePick={updatePick}
                        results={results?.filter((result) => {
                            return result.bracket.race_class === RaceClass.TOP_FUEL;
                        })}
                        bracket={brackets?.find((bracket) => {
                            return bracket.race_class === RaceClass.TOP_FUEL;
                        })}
                    />
                    <div style={{width: '25px'}}/>
                    <Typography variant={"h6"} textAlign={'center'} sx={{
                        position: 'absolute',
                        top: '0',
                        alignSelf: 'center',
                        width: '100%',
                        color: "#000000",
                        fontWeight: "bold",
                    }}>
                        {title}
                    </Typography>
                    <PrintBracketFourWide
                        race_class={RaceClass.FUNNY_CAR}
                        picks={picks?.filter((pick) => {
                            return pick.result.bracket.race_class === RaceClass.FUNNY_CAR;
                        })}
                        picks_locked={picks_locked}
                        updatePick={updatePick}
                        results={results?.filter((result) => {
                            return result.bracket.race_class === RaceClass.FUNNY_CAR;
                        })}
                        bracket={brackets?.find((bracket) => {
                            return bracket.race_class === RaceClass.FUNNY_CAR;
                        })}
                    />
                </Grid>
                <Grid container sx={{
                    marginY: 1,
                    display: 'flex',
                    flexDirection: "row",
                    flexWrap: "nowrap",
                    height: '1rem',
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                    {
                        event?.left_sponsor_image_link && (
                            <img src={event.left_sponsor_image_link} alt={"Sponsor Logo"} style={{maxHeight: '100px', maxWidth: '100px'}}/>
                        )
                    }
                    <img src={Logo} alt={"DRBB Logo"} height={'175px'} style={{margin: '50px'}}/>
                    {
                        event?.right_sponsor_image_link && (
                            <img src={event.right_sponsor_image_link} alt={"Sponsor Logo"} style={{maxHeight: '100px', maxWidth: '100px'}}/>
                        )
                    }
                </Grid>
                <Grid container sx={{
                    marginY: 1,
                    display: 'flex',
                    flexDirection: "row",
                    flexWrap: "nowrap",
                    width: 'fit-content',
                }}>
                    <PrintBracketFourWide
                        race_class={RaceClass.PRO_STOCK}
                        picks={picks?.filter((pick) => {
                            return pick.result.bracket.race_class === RaceClass.PRO_STOCK;
                        })}
                        picks_locked={picks_locked}
                        updatePick={updatePick}
                        results={results?.filter((result) => {
                            return result.bracket.race_class === RaceClass.PRO_STOCK;
                        })}
                        bracket={brackets?.find((bracket) => {
                            return bracket.race_class === RaceClass.PRO_STOCK;
                        })}
                    />
                    <div style={{width: '25px'}}/>
                    <PrintBracketFourWide
                        race_class={RaceClass.PRO_STOCK_MOTORCYCLE}
                        picks={picks?.filter((pick) => {
                            return pick.result.bracket.race_class === RaceClass.PRO_STOCK_MOTORCYCLE;
                        })}
                        picks_locked={picks_locked}
                        updatePick={updatePick}
                        results={results?.filter((result) => {
                            return result.bracket.race_class === RaceClass.PRO_STOCK_MOTORCYCLE;
                        })}
                        bracket={brackets?.find((bracket) => {
                            return bracket.race_class === RaceClass.PRO_STOCK_MOTORCYCLE;
                        })}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}