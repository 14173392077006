import * as React from 'react';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Grid from "@mui/material/Grid";
import Logo from "../../assets/images/logo.png";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import {useTheme} from "@mui/material";

export type HeaderProps = {
    sections: ReadonlyArray<{
        title: string;
        url: string;
        icon: React.ReactElement;
        active: boolean;
    }>;
    title: string;
}

export default function Header(props: Readonly<HeaderProps>) {
    const {sections, title} = props;
    const theme = useTheme();
    const darkMode = theme.palette.mode === 'dark';
    return (
        <Grid data-testid={"header"} container sx={{
            height: "fit-content",
            padding: '8px',
            display: 'flex',
            alignContent: 'flex-start',
        }}>
            <Toolbar sx={{
                display: 'flex',
                width: '100%'
            }}>
                <img src={Logo} height={'125'} width={'125'}/>
                <Grid container sx={{display: "flex", justifyContent: "end"}}>
                    <Button variant="contained"
                            sx={{
                                textTransform: 'none',
                                backgroundColor: darkMode ? "white" : "#7F9AF5",
                                color: darkMode ? "#121212" : "white",
                                marginX: 1,
                                ":hover": {
                                    backgroundColor: "#c40000",
                                    color: "#ffffff",
                                }
                            }}
                            onClick={() => {
                        if (localStorage.getItem('signed_in') === "true") {
                            localStorage.removeItem('signed_in');
                            localStorage.removeItem('user');
                            window.location.href = "/";
                        } else {
                            window.location.href = "/signin/";
                        }
                    }}>
                        <Typography variant={"h6"} fontWeight={"bold"}>
                            {localStorage.getItem('signed_in') === "true" ? "Sign Out" : "Sign In"}
                        </Typography>
                    </Button>
                    {localStorage.getItem('signed_in') === "false" || localStorage.getItem('signed_in') === null ?
                        <Button variant="contained"
                                sx={{
                                    textTransform: 'none',
                                    backgroundColor: darkMode ? "white" : "#7F9AF5",
                                    color: darkMode ? "#121212" : "white",
                                    ":hover": {
                                        backgroundColor: "#c40000",
                                        color: "#ffffff",
                                    }
                                }}
                                onClick={() => {
                            window.location.href = "/signup";
                        }}>
                            <Typography variant={"h6"} fontWeight={"bold"}>
                                Sign Up
                            </Typography>
                        </Button> : null
                    }
                </Grid>
            </Toolbar>
            <Toolbar
                component="nav"
                variant="dense"
                sx={{
                    justifyContent: 'space-between',
                    overflowX: 'auto !important',
                    padding: 0,
                    width: '100%'
            }}
            >
                {sections.map((section) => (
                    <Link
                        color="inherit"
                        noWrap
                        key={section.title}
                        variant="body2"
                        href={section.url}
                        sx={{p: 1.5, flexShrink: 0}}
                        id={section.title + "-button"}
                        target={section.url.startsWith('https') ? "_blank" : "_self"}
                    >
                        <Button color="inherit" variant={"contained"} sx={{
                            textTransform: 'none',
                            backgroundColor: section.active ? "#c40000" : '#486CE7',
                        }}>
                            <Typography variant="body2" color="inherit" noWrap paddingX={1} style={{
                                color: 'white'
                            }}>
                                {section.title}
                            </Typography>
                            {section.icon}
                        </Button>
                    </Link>
                ))}
            </Toolbar>
        </Grid>
    );
}