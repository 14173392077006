import Layout from "../utilities/Layout";
import Typography from "@mui/material/Typography";
import {ChangeEvent, useState} from "react";
import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import CssBaseline from "@mui/material/CssBaseline";
import {Alert, Snackbar} from "@mui/material";
import * as LeagueAPI from "../apis/League";
import Paper from "@mui/material/Paper";
import GA4EventSend from "../utilities/GA4EventSend";
import Compressor from 'compressorjs';
import {Helmet} from "react-helmet";


export default function LeagueCreate() {
    const [open, setOpen] = useState<boolean>(false);
    const [message, setMessage] = useState<String>("");
    const [severity, setSeverity] = useState<"success" | "info" | "warning" | "error" | undefined>("error"); // ["success", "info", "warning", "error"
    const [isClicked, setIsClicked] = useState(false);
    const [file, setFile] = useState<File | undefined>(undefined);
    const [formValid, setFormValid] = useState<boolean>(false);
    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const form = new FormData(event.currentTarget.form as HTMLFormElement);
        // Access the form element and perform any desired operations
        setFormValid(form.get('league-name') !== '' && (form.get('league-name') as string).length < 20);
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        setIsClicked(true);
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        data.append('league-photo', file as File);
        const response: LeagueAPI.LeagueResponse = await LeagueAPI.create_league({
            creator: JSON.parse(localStorage.getItem('user') as string).id,
            league_name: data.get('league-name') as string,
        });
        if (response.succeeded) {
            if (file) {
                const fileResponse: LeagueAPI.LeagueResponse = await LeagueAPI.upload_league_photo(file, response.data.id, JSON.parse(localStorage.getItem('user') as string).id);
                if (!fileResponse.succeeded) {
                    setMessage("League Created, but photo upload failed");
                    setSeverity("error");
                    setOpen(true);
                } else {
                    setMessage("League Created, and photo upload successful");
                    setSeverity("success");
                    setOpen(true);
                    window.location.href = "/league/?" + encodeURIComponent("league_id=" + response.data.id);
                }
                GA4EventSend("league_create", "submit", "league_create", response.statusCode, false);
            }
        } else {
            setMessage(response.message);
            setSeverity("error");
            setOpen(true);
        }
        setIsClicked(false);
    };

    const handleImageUpload = (event: ChangeEvent<HTMLInputElement>) => {
        let file = event.target.files?.[0];
        // Perform any validation or checks on the file here
        // make sure file is of a photo extension
        if (file) {
            const extension: string = (file?.name.split('.').pop() as string).toLowerCase();
            if (extension !== 'jpg' && extension !== 'jpeg' && extension !== 'png') {
                setSeverity("error")
                setMessage("File must be a photo");
                setOpen(true);
            } else if ((file as File)?.size > 5000000) {
                setSeverity("error")
                setMessage("File must be less than 5MB");
                setOpen(true);
            } else {
                let quality = 0.8; // start with high quality
                new Compressor(file, {
                    quality: quality,
                    maxWidth: 300,
                    maxHeight: 300,
                    resize: 'cover',
                    success: (compressedResult) => {
                        // If file size is still more than 1MB, compress again with lower quality
                        while (compressedResult.size > 1000000 && quality > 0.1) {
                            quality -= 0.1; // reduce quality
                            new Compressor(compressedResult, {
                                quality: quality,
                                success: (doubleCompressedResult) => {
                                    compressedResult = doubleCompressedResult;
                                },
                            });
                        }
                        console.log(compressedResult.size, quality);
                        setFile(compressedResult as File)
                        setSeverity("success")
                        setMessage("File is valid format");
                        setOpen(true);
                    },
                });
            }
        }
    };


    return (
        <Layout>
            <Helmet>
                <title>DRBB | Create League</title>
            </Helmet>
            <CssBaseline/>
            <Snackbar anchorOrigin={{vertical: "top", horizontal: "center"}} open={open} onClose={handleClose}
                      autoHideDuration={6000}>
                <Alert elevation={6} severity={severity} sx={{width: '100%'}} variant="filled">
                    {message}
                </Alert>
            </Snackbar>
            <Grid container sx={{
                display: 'flex',
                justifyContent: 'center',
            }}>
                <Box
                    sx={{
                        my: 8,
                        mx: 4,
                        padding: '12px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        borderRadius: '10px'
                    }}
                    component={Paper}
                    elevation={6}
                >
                    <Typography variant="h3" fontWeight={"bold"} textAlign={"center"}>
                        Create Your League
                    </Typography>
                    {file && <img src={URL.createObjectURL(file)} alt="League Photo" width={200} height={200}/>}
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{
                        mt: 1,
                        display: 'flex',
                        flexDirection: 'column',
                    }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="league-name"
                            label="League Name"
                            name="league-name"
                            autoFocus
                            onChange={handleChange}
                        />
                        <Button variant="contained" component="label" sx={{
                            backgroundColor: "#486ce7",
                            minWidth: 'fit-content',
                            color: "#ffffff",
                            ":hover": {
                                backgroundColor: "#c40000",
                                color: "#ffffff",
                            },
                            mt: 3,
                            mb: 2
                        }}>
                            Upload League Photo File
                            <input type="file" id="league-photo" name="league-photo" required hidden
                                   onChange={handleImageUpload}/>
                        </Button>
                        <ul>
                            <li>
                                League name must be unique
                            </li>
                            <li>
                                League name must be less than 20 characters
                            </li>
                            <li>
                                File must be a photo
                            </li>
                            <li>
                                File must be less than 5MB
                            </li>
                        </ul>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{
                                backgroundColor: "#486ce7",
                                minWidth: 'fit-content',
                                color: "#ffffff",
                                ":hover": {
                                    backgroundColor: "#c40000",
                                    color: "#ffffff",
                                },
                                mt: 3,
                                mb: 2
                            }}
                            disabled={isClicked || !formValid}
                        >
                            Create League
                        </Button>
                    </Box>
                </Box>
            </Grid>
        </Layout>
    )
}