import {Config} from "../Config";

const config: Config = new Config();

export type DriverResponse = {
    succeeded: boolean;
    statusCode: number;
    message: String;
    data?: any;
}

export async function get_drivers(): Promise<DriverResponse> {
    if(window.location.pathname === "/error"){
        return {succeeded: false, statusCode: 500, message: "Something went wrong on our end"};
    }
    try{
        let response: Response = await fetch(config.backendURL + config.endpoints.driver_list, {
            method: 'GET',
            headers: {
                'content-type': 'application/json;charset=UTF-8',
            },
        });
        let responseStatus: number = response.status;
        let data: any = await response.json();
        switch (responseStatus) {
            case 200:
                return {
                    succeeded: true, statusCode: 200, message: "Drivers retrieved successfully", data: data,
                };
            case 400:
                return {succeeded: false, statusCode: 400, message: "Drivers failed to retrieve"};
            case 401:
                return {succeeded: false, statusCode: 401, message: data["detail"]};
            case 404:
                return {succeeded: false, statusCode: 404, message: "Servers are down, please come back later"};
            case 500:
                return {succeeded: false, statusCode: 500, message: "Something went wrong on our end"};
            default:
                return {succeeded: false, statusCode: 500, message: "Something went wrong on our end"};
        }
    }
    catch (e) {
        if(e instanceof TypeError && e.message === "Failed to fetch"){
            window.location.href = "/error";
        }
        return {succeeded: false, statusCode: 500, message: "Something went wrong on our end"};
    }
}

export async function driver_stats(id: number) {
    if(window.location.pathname === "/error"){
        return {succeeded: false, statusCode: 500, message: "Something went wrong on our end"};
    }
    try{
        if(id === null){
            return {succeeded: false, statusCode: 400, message: "Driver id is empty"};
        }
        else {
            let response: Response = await fetch(config.backendURL + config.endpoints.driver_stats + id + "/", {
                method: 'GET',
                headers: {
                    'content-type': 'application/json;charset=UTF-8',
                },
            });
            let responseStatus: number = response.status;
            let data: any = await response.json();
            switch (responseStatus) {
                case 200:
                    return {
                        succeeded: true, statusCode: 200, message: "Driver stats acquired successfully", data: data,
                    };
                case 400:
                    return {succeeded: false, statusCode: 400, message: "Driver stats failed to acquire"};
                case 401:
                    return {succeeded: false, statusCode: 401, message: data["detail"]};
                case 404:
                    return {succeeded: false, statusCode: 404, message: "Servers are down, please come back later"};
                case 500:
                    return {succeeded: false, statusCode: 500, message: "Something went wrong on our end"};
                default:
                    return {
                        succeeded: false, statusCode: 500, message: "Something went wrong on our end"
                    }
            }
        }
    }
    catch (e) {
        if(e instanceof TypeError && e.message === "Failed to fetch"){
            window.location.href = "/error";
        }
        return {succeeded: false, statusCode: 500, message: "Something went wrong on our end"};
    }
}