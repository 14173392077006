import Grid from "@mui/material/Grid";
import PickRow from "../PickRow";
import {Divider} from "@mui/material";
import {Driver} from "../../../../data/models/bracket_api/Driver";
import {RaceClass} from "../../../../data/models/bracket_api/RaceClass";
import Paper from "@mui/material/Paper";
import {Pick} from "../../../../data/models/bracket_api/Pick";
import {Result} from "../../../../data/models/bracket_api/Result";
import {FourWidePick} from "../../../../data/models/bracket_api/FourWidePick";
import {FourWideResult} from "../../../../data/models/bracket_api/FourWideResult";

export type FourWidePickBlockProps = {
    pick?: FourWidePick,
    result?: FourWideResult,
    updatePick: (pick: FourWidePick) => void,
    picks_locked: boolean
}

export default function PickBlockFourWide({pick, result, updatePick, picks_locked}: Readonly<FourWidePickBlockProps>){

    return (
        <Grid container component={Paper} m={1} sx={{
            display: 'flex',
            flexDirection: "column",
            height: 'fit-content',
            width: 'fit-content',
        }}>
            <PickRow
                competitor_number={1}
                pick={pick}
                result={result}
                onClick={updatePick}
                picks_locked={picks_locked}
                fourWide={true}
            />
            <Divider/>
            <PickRow
                competitor_number={2}
                pick={pick}
                result={result}
                onClick={updatePick}
                picks_locked={picks_locked}
                fourWide={true}
            />
            <Divider/>
            <PickRow
                competitor_number={3}
                pick={pick}
                result={result}
                onClick={updatePick}
                picks_locked={picks_locked}
                fourWide={true}
            />
            <Divider/>
            <PickRow
                competitor_number={4}
                pick={pick}
                result={result}
                onClick={updatePick}
                picks_locked={picks_locked}
                fourWide={true}
            />
        </Grid>
    )
}