import * as React from 'react';
import {useEffect, useState} from "react";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import {Grid, Typography, useMediaQuery} from "@mui/material";
import {Theme} from "@mui/material";
import {uuidv4} from "../../../utilities/UUIDGeneration";

type FirstRoundPickTableProps = {
    rows: [{
        "Competitor 1": string;
        "Seed 1": number;
        "Competitor 2": string;
        "Seed 2": number;
        "Competitor 1 Pick Percentage": string;
        "Competitor 2 Pick Percentage": string;
    }]
}

export default function FirstRoundPicksTable(props: Readonly<FirstRoundPickTableProps>) {
    const [isMdOrUp, setIsMdOrUp] = useState(useMediaQuery((theme: Theme) => theme.breakpoints.up('md')));

    useEffect(() => {
        const handleResize = () => {
            setIsMdOrUp(window.innerWidth >= 960);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [isMdOrUp]);

    return (
        <Grid>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell
                                key={'comp1' + uuidv4().toString()}
                                align={'left'}
                                style={{minWidth: 'fit-content'}}
                            >
                                <Typography variant='body1' fontWeight={'bold'}>
                                    Competitor 1
                                </Typography>
                            </TableCell>
                            <TableCell
                                key={'seed1' + uuidv4().toString()}
                                align={'center'}
                                style={{minWidth: 'fit-content'}}
                            >
                                <Typography variant='body1' fontWeight={'bold'}>
                                    Seed 1
                                </Typography>
                            </TableCell>
                            <TableCell
                                key={'comp2' + uuidv4().toString()}
                                align={'right'}
                                style={{minWidth: 'fit-content'}}
                            >
                                <Typography variant='body1' fontWeight={'bold'}>
                                    Competitor 2
                                </Typography>
                            </TableCell>
                            <TableCell
                                key={'seed2' + uuidv4().toString()}
                                align={'center'}
                                style={{minWidth: 'fit-content'}}
                            >
                                <Typography variant='body1' fontWeight={'bold'}>
                                    Seed 2
                                </Typography>
                            </TableCell>
                            <TableCell
                                key={'comp1perc' + uuidv4().toString()}
                                align={'left'}
                                style={{minWidth: 'fit-content'}}
                            >
                                <Typography variant='body1' fontWeight={'bold'}>
                                    Competitor 1 Pick %
                                </Typography>
                            </TableCell>
                            <TableCell
                                key={'comp1perc' + uuidv4().toString()}
                                align={'left'}
                                style={{minWidth: 'fit-content'}}
                            >
                                <Typography variant='body1' fontWeight={'bold'}>
                                    Competitor 2 Pick %
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.rows
                            .map((row) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row["Competitor 1"]} sx={{cursor: 'pointer'}}>
                                        <TableCell
                                            key={'driver' + row["Competitor 1"]}
                                            align={'left'}
                                            sx={{minWidth: 'fit-content', padding: 1}}
                                        >
                                            <Typography variant={isMdOrUp ? 'body1' : 'body2'}>
                                                {row["Competitor 1"]}
                                            </Typography>
                                        </TableCell>
                                        <TableCell
                                            key={'seed_1' + row["Competitor 1"]}
                                            align={'center'}
                                            sx={{minWidth: 'fit-content', padding: 1}}
                                        >
                                            <Typography variant={isMdOrUp ? 'body1' : 'body2'}>
                                                {row["Seed 1"]}
                                            </Typography>
                                        </TableCell>
                                        <TableCell
                                            key={'driver' + row["Competitor 2"]}
                                            align={'left'}
                                            sx={{minWidth: 'fit-content', padding: 1}}
                                        >
                                            <Typography variant={isMdOrUp ? 'body1' : 'body2'}>
                                                {row["Competitor 2"]}
                                            </Typography>
                                        </TableCell>
                                        <TableCell
                                            key={'seed_2' + row["Competitor 2"]}
                                            align={'center'}
                                            sx={{minWidth: 'fit-content', padding: 1}}
                                        >
                                            <Typography variant={isMdOrUp ? 'body1' : 'body2'}>
                                                {row["Seed 2"]}
                                            </Typography>
                                        </TableCell>
                                        <TableCell
                                            key={'perc' + row["Competitor 1"]}
                                            align={'left'}
                                            sx={{minWidth: 'fit-content', padding: 1}}
                                        >
                                            <Typography variant={isMdOrUp ? 'body1' : 'body2'}>
                                                {row["Competitor 1 Pick Percentage"]}
                                            </Typography>
                                        </TableCell>
                                        <TableCell
                                            key={'perc' + row["Competitor 2"]}
                                            align={'center'}
                                            sx={{minWidth: 'fit-content', padding: 1}}
                                        >
                                            <Typography variant={isMdOrUp ? 'body1' : 'body2'}>
                                                {row["Competitor 2 Pick Percentage"]}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    );
}