import Grid from "@mui/material/Grid";
import PrintRowReverse from "../PrintRowReverse";
import {TwoWidePickBlockProps} from "./PrintBlock";

export default function PrintBlockReverse({pick, result, updatePick, picks_locked}: Readonly<TwoWidePickBlockProps>){
    return (
        <Grid container my={0.5} sx={{
            display: 'flex',
            flexDirection: "column",
            height: 'fit-content',
            width: 'fit-content',
            border: '1px solid black',
        }}>
            <PrintRowReverse
                competitor_number={1}
                pick={pick}
                result={result}
                fourWide={false}
            />
            <PrintRowReverse
                competitor_number={2}
                pick={pick}
                result={result}
                fourWide={false}
            />
        </Grid>
    )
}