import Layout from "../utilities/Layout";
import Grid from "@mui/material/Grid";
import Toolbar from "@mui/material/Toolbar";
import * as React from "react";
import {useEffect, useRef, useState} from "react";
import Button from "@mui/material/Button";
import PickBracketContainer from "../components/bracket/picks/two_wide/PickBracketContainer";
import PickBracketContainerFourWide from "../components/bracket/picks/four_wide/PickBracketContainerFourWide";
import Box from "@mui/material/Box";
import {Event} from "../data/models/bracket_api/Event";
import {Pick} from "../data/models/bracket_api/Pick";
import {FourWidePick} from "../data/models/bracket_api/FourWidePick";
import {FourWideResult} from "../data/models/bracket_api/FourWideResult";
import {Bracket} from "../data/models/bracket_api/Bracket";
import {Result} from "../data/models/bracket_api/Result";
import {Qualifier} from "../data/models/bracket_api/Qualifier";
import {bracket_screen_load} from "../apis/ScreenLoad";
import ShuffleIcon from '@mui/icons-material/Shuffle';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import PrintIcon from '@mui/icons-material/Print';
import IosShareIcon from '@mui/icons-material/IosShare';
import PublishIcon from '@mui/icons-material/Publish';
import {RaceClass, raceClassFromInt} from "../data/models/bracket_api/RaceClass";
import {Driver} from "../data/models/bracket_api/Driver";
import {
    generateBlankPicks, generatePracticeBracketProps,
    randomizePicksFourWide,
    randomizePicksTwoWide
} from "../utilities/bracket_tools/PickGeneration";
import {User} from "../data/models/user_auth/User";
import {PickStatus, pickStatusFromInt} from "../data/models/bracket_api/PickStatus";
import Typography from "@mui/material/Typography";
import {League} from "../data/models/user_auth/League";
import {
    Alert,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Snackbar, Theme, useMediaQuery
} from "@mui/material";
import {submitPicks, submitPicksFourWide, PickResponse} from "../apis/Pick";
import {get_drivers} from "../apis/Driver";
import CssBaseline from "@mui/material/CssBaseline";
import ReactToPrint from "react-to-print";
import PrintBracketContainerFourWide from "../components/bracket/print/four_wide/PrintBracketContainerFourWide";
import PrintBracketContainer from "../components/bracket/print/two_wide/PrintBracketContainer";
import {Helmet} from "react-helmet";
import {FacebookShareButton, TwitterShareButton} from "react-share";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import GA4EventSend from "../utilities/GA4EventSend";
import Loader from "../components/common/Loader";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import IconButton from "@mui/material/IconButton";


export type BracketScreenProps = {
    event: Event,
    brackets: Bracket[],
    picks: Pick[] | FourWidePick[],
    results: Result[] | FourWideResult[],
    qualifiers: Qualifier[],
}
export default function BracketScreen() {
    const [props, setProps] = useState<BracketScreenProps | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(true);
    let searchParams = window.location.href.substring(window.location.href.indexOf("?") + 1);
    let decoded = decodeURIComponent(searchParams);
    const queryParams = new URLSearchParams(decoded);
    const [eventId, setEventId] = useState(queryParams.get('event_id'));
    const leagueId = queryParams.get('league_id') || "1";
    let userId = queryParams.get('user_id');
    const [nickname, setNickname] = useState<string>("");
    const [isClicked, setIsClicked] = useState<boolean>(false);
    let user: User | undefined = JSON.parse(localStorage.getItem('user') ?? "{}") as User || undefined;
    if(userId === null){
        try {
            userId = user.id.toString();
        }
        catch (e) {
            let pathname = encodeURIComponent("/bracket?event_id=" + eventId + "&league_id=" + leagueId);
            window.location.href = "/signin/redirect?path=" + pathname;
        }
    }
    let [league, setLeague] = useState<League | undefined>(undefined);
    const [ownPicks, setOwnPicks] = useState<boolean>(user?.id === parseInt(userId ?? "0"));
    const practiceBracket = queryParams.get('practice') === "true";
    const [resetOpen, setResetOpen] = useState(false);
    const [shareOpen, setShareOpen] = useState(false);
    const [changed, setChanged] = useState<"up-to-date" | "changed" | "sending">("up-to-date");
    const [open, setOpen] = useState<boolean>(false);
    const [openScoring, setOpenScoring] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [severity, setSeverity] = useState<"success" | "info" | "warning" | "error" | undefined>("error"); // ["success", "info", "warning", "error"
    const [isMdOrUp, setIsMdOrUp] = useState(useMediaQuery((theme: Theme) => theme.breakpoints.up('md')));

    const [helpOpen, setHelpOpen] = useState(false);
    const [isFourWide, setIsFourWide] = useState(false);

    const handleHelpClose = () => {
        setHelpOpen(false);
    }

    const handleResetClickOpen = () => {
        setResetOpen(true);
    };
    const handleResetClose = () => {
        setResetOpen(false);
    };
    const handleShareClickOpen = () => {
        setShareOpen(true);
    };
    const handleShareClose = () => {
        setShareOpen(false);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            if (changed === "changed" && !practiceBracket) {
                handleSubmitPicks(false);
            }
        }, 5000); // 5000 milliseconds = 5 seconds
        if (loading) {
            if (practiceBracket) {
                setOwnPicks(true);
                let drivers: Driver[]
                get_drivers().then((response) => {
                    drivers = response.data;
                    drivers.forEach((driver: any) => {
                        let newDivisions: RaceClass[] = [];
                        for (let division of driver.divisions) {
                            newDivisions.push(raceClassFromInt(division));
                        }
                        driver.divisions = newDivisions;
                    })
                    if(user) {
                        setProps(generatePracticeBracketProps(
                            queryParams.get("four_wide") === "true",
                            user,
                            drivers
                        ));
                    }
                    setLoading(false);
                });
            } else {
                bracket_screen_load(leagueId ?? '', userId ?? "0", eventId ?? "").then((response) => {
                    if (response.succeeded) {
                        GA4EventSend('bracket', 'load', 'bracket', 200, false)
                        setNickname(response.data.nickname);
                        let drivers: Driver[] = response.data.drivers;
                        drivers.forEach((driver: any) => {
                            let newDivisions: RaceClass[] = [];
                            for (let division of driver.divisions) {
                                newDivisions.push(raceClassFromInt(division));
                            }
                            driver.divisions = newDivisions;
                        })
                        let event = response.data.event as Event;
                        if(eventId === null){
                            setEventId(event.id as string);
                        }
                        setIsFourWide(event.is_four_wide);
                        response.data.brackets.forEach((bracket: any) => {
                            bracket.race_class = raceClassFromInt(bracket.race_class);
                        });
                        let brackets: Bracket[] = response.data.brackets;
                        response.data.qualifiers.forEach((qualifier: any) => {
                            qualifier.event = event;
                            qualifier.bracket = response.data.brackets.find((bracket: Bracket) => {
                                return bracket.id === qualifier.bracket_id
                            });
                            qualifier.driver = drivers.find((driver: any) => {
                                return driver.id === qualifier.driver_id
                            });
                            qualifier.race_class = raceClassFromInt(qualifier.race_class)
                            delete qualifier.bracket_id;
                            delete qualifier.driver_id;
                            delete qualifier.event_id;
                        });
                        let qualifiers: Qualifier[] = response.data.qualifiers;
                        if (event.is_four_wide) {
                            response.data.results.forEach((result: any) => {
                                result.bracket = brackets.find((bracket: Bracket) => {
                                    return bracket.id === result.bracket_id
                                });
                                delete result.bracket_id;
                                result.first = drivers.find((driver: any) => {
                                    return driver.id === result.first_id
                                });
                                delete result.first_id;
                                result.second = drivers.find((driver: any) => {
                                    return driver.id === result.second_id
                                });
                                delete result.second_id;
                                result.third = drivers.find((driver: any) => {
                                    return driver.id === result.third_id
                                });
                                delete result.third_id;
                                result.fourth = drivers.find((driver: any) => {
                                    return driver.id === result.fourth_id
                                });
                                delete result.fourth_id;
                                result.competitor_1 = drivers.find((driver: any) => {
                                    return driver.id === result.competitor_1_id
                                });
                                delete result.competitor_1_id;
                                result.competitor_2 = drivers.find((driver: any) => {
                                    return driver.id === result.competitor_2_id
                                });
                                delete result.competitor_2_id;
                                result.competitor_3 = drivers.find((driver: any) => {
                                    return driver.id === result.competitor_3_id
                                });
                                delete result.competitor_3_id;
                                result.competitor_4 = drivers.find((driver: any) => {
                                    return driver.id === result.competitor_4_id
                                });
                                delete result.competitor_4_id;
                            });
                        } else {
                            response.data.results.forEach((result: any) => {
                                result.bracket = brackets.find((bracket: Bracket) => {
                                    return bracket.id === result.bracket_id
                                });
                                delete result.bracket_id;
                                result.winner = drivers.find((driver: any) => {
                                    return driver.id === result.winner_id
                                });
                                delete result.winner_id;
                                result.loser = drivers.find((driver: any) => {
                                    return driver.id === result.loser_id
                                });
                                delete result.loser_id;
                                result.competitor_1 = drivers.find((driver: any) => {
                                    return driver.id === result.competitor_1_id
                                });
                                delete result.competitor_1_id;
                                result.competitor_2 = drivers.find((driver: any) => {
                                    return driver.id === result.competitor_2_id
                                });
                                delete result.competitor_2_id;
                            });
                        }
                        let results: Result[] | FourWideResult[] = response.data.results;
                        let picks: Pick[] | FourWidePick[] = response.data.picks;
                        setLeague(response.data.league);
                        if ((response.data.picks === undefined || response.data.picks.length === 0) && user !== undefined) {
                            if(leagueId !== null && leagueId === "1"){
                                setHelpOpen(true);
                            }
                            picks = generateBlankPicks({
                                results: results,
                                isFourWide: event.is_four_wide,
                                player: user,
                                league: response.data.league,
                            })
                        } else {
                            if (event.is_four_wide) {
                                let rawPicks = response.data.picks;
                                let cleanedPicks: FourWidePick[] = [];
                                rawPicks.forEach((pick: any) => {
                                    cleanedPicks.push({
                                        id: pick.id,
                                        player: user,
                                        competitor_1: drivers.find((driver: any) => {
                                            return driver.id === pick.competitor_1_id
                                        }),
                                        competitor_2: drivers.find((driver: any) => {
                                            return driver.id === pick.competitor_2_id
                                        }),
                                        competitor_3: drivers.find((driver: any) => {
                                            return driver.id === pick.competitor_3_id
                                        }),
                                        competitor_4: drivers.find((driver: any) => {
                                            return driver.id === pick.competitor_4_id
                                        }),
                                        seed_1: pick.seed_1,
                                        seed_2: pick.seed_2,
                                        seed_3: pick.seed_3,
                                        seed_4: pick.seed_4,
                                        first: drivers.find((driver: any) => {
                                            return driver.id === pick.first_id
                                        }),
                                        first_seed: pick.first_seed,
                                        second: drivers.find((driver: any) => {
                                            return driver.id === pick.second_id
                                        }),
                                        second_seed: pick.second_seed,
                                        third: drivers.find((driver: any) => {
                                            return driver.id === pick.third_id
                                        }),
                                        third_seed: pick.third_seed,
                                        fourth: drivers.find((driver: any) => {
                                            return driver.id === pick.fourth_id
                                        }),
                                        fourth_seed: pick.fourth_seed,
                                        league: response.data.league,
                                        result: (results as FourWideResult[]).find((result: any) => {
                                            return result.game_code === pick.result_id
                                        }),
                                        pick_status: pickStatusFromInt(pick.pick_status),
                                        tiebreaker: pick.tiebreaker,
                                        possible_points: pick.possible_points,
                                    } as Pick)
                                })
                                picks = cleanedPicks;
                            } else {
                                let rawPicks = response.data.picks;
                                let cleanedPicks: Pick[] = [];
                                rawPicks.forEach((pick: any) => {
                                    cleanedPicks.push({
                                        id: pick.id,
                                        player: user,
                                        competitor_1: drivers.find((driver: any) => {
                                            return driver.id === pick.competitor_1_id
                                        }),
                                        competitor_2: drivers.find((driver: any) => {
                                            return driver.id === pick.competitor_2_id
                                        }),
                                        seed_1: pick.seed_1,
                                        seed_2: pick.seed_2,
                                        winner: drivers.find((driver: any) => {
                                            return driver.id === pick.winner_id
                                        }),
                                        winner_seed: pick.winner_seed,
                                        loser: drivers.find((driver: any) => {
                                            return driver.id === pick.loser_id
                                        }),
                                        loser_seed: pick.loser_seed,
                                        league: response.data.league,
                                        result: (results as Result[]).find((result: any) => {
                                            return result.game_code === pick.result_id
                                        }),
                                        pick_status: pickStatusFromInt(pick.pick_status),
                                        tiebreaker: pick.tiebreaker,
                                        possible_points: pick.possible_points,
                                    } as Pick)
                                })
                                picks = cleanedPicks;
                            }
                        }
                        setProps({
                            event: event,
                            brackets: brackets,
                            qualifiers: qualifiers,
                            picks: picks,
                            results: results,
                        });
                        setLoading(false);
                    } else {
                        GA4EventSend('bracket', 'load', 'bracket', 500, false)
                        setLoading(false);
                    }
                });
            }
        }
        const handleResize = () => {
            setIsMdOrUp(window.innerWidth >= 960);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
            clearInterval(interval);
        };
    }, [loading, ownPicks]);

    function updatePickTwoWide(pick: Pick) {
        let picks = props?.picks as Pick[];
        if (pick.pick_status === PickStatus.PENDING) {
            // Winner does not exist make sure no one from this pick is in any future picks
            if (pick.winner !== undefined) {
                let game_code = pick.result.game_code;
                let nextPick = picks.find((pick2: Pick) => {
                    return pick2.pick_status !== PickStatus.TIEBREAKER && (pick2.result.competitor_1_game_code === game_code || pick2.result.competitor_2_game_code === game_code);
                });
                if (nextPick !== undefined) {
                    if (nextPick.result.competitor_1_game_code === game_code) {
                        nextPick.competitor_1 = pick.winner;
                        nextPick.seed_1 = pick.winner_seed;
                    } else if (nextPick.result.competitor_2_game_code === game_code) {
                        nextPick.competitor_2 = pick.winner;
                        nextPick.seed_2 = pick.winner_seed;
                    }
                }
                let round = pick.result.round;
                let invalidPicks = picks.filter((pick2: Pick) => {
                    return pick2.result.round > round &&
                        (pick2.competitor_1 === pick.loser ||
                            pick2.competitor_2 === pick.loser
                        );
                });
                for (let invalidPick of invalidPicks) {
                    if (invalidPick.competitor_1 === pick.loser) {
                        invalidPick.competitor_1 = undefined;
                        invalidPick.seed_1 = undefined;
                    } else if (invalidPick.competitor_2 === pick.loser) {
                        invalidPick.competitor_2 = undefined;
                        invalidPick.seed_2 = undefined;
                    }
                }
                for (let invalidPick of invalidPicks) {
                    picks.forEach((pick2: Pick) => {
                        if (pick2.id === invalidPick.id) {
                            pick2 = invalidPick;
                        }
                    })
                }
                picks.forEach((pick2: Pick) => {
                    if (pick2.id === nextPick?.id) {
                        pick2 = nextPick;
                    } else if (pick2.id === pick.id) {
                        pick2 = pick;
                    }
                })
            }
            // Winner exists make sure loser from this pick is not in any future picks
            else {
                let round = pick.result.round;
                let invalidPicks = picks.filter((pick2: Pick) => {
                    return pick2.result.round > round &&
                        (pick2.competitor_1 === pick.competitor_1 ||
                            pick2.competitor_1 === pick.competitor_2 ||
                            pick2.competitor_2 === pick.competitor_1 ||
                            pick2.competitor_2 === pick.competitor_2
                        );
                });
                for (let invalidPick of invalidPicks) {
                    if (invalidPick.competitor_1 === pick.competitor_1 || invalidPick.competitor_1 === pick.competitor_2) {
                        if (invalidPick.competitor_1 === invalidPick.winner) {
                            invalidPick.winner = undefined;
                            invalidPick.winner_seed = undefined;
                            invalidPick.loser = undefined;
                            invalidPick.loser_seed = undefined;
                        }
                        invalidPick.competitor_1 = undefined;
                        invalidPick.seed_1 = undefined;
                    }
                    if (invalidPick.competitor_2 === pick.competitor_1 || invalidPick.competitor_2 === pick.competitor_2) {
                        if (invalidPick.competitor_2 === invalidPick.winner) {
                            invalidPick.winner = undefined;
                            invalidPick.winner_seed = undefined;
                            invalidPick.loser = undefined;
                            invalidPick.loser_seed = undefined;
                        }
                        invalidPick.competitor_2 = undefined;
                        invalidPick.seed_2 = undefined;
                    }
                }
                for (let invalidPick of invalidPicks) {
                    picks.forEach((pick2: Pick) => {
                        if (pick2.id === invalidPick.id) {
                            pick2 = invalidPick;
                        }
                    })
                }
                picks.forEach((pick2: Pick) => {
                    if (pick2.id === pick.id) {
                        pick2 = pick;
                    }
                })
            }
        } else if (pick.pick_status === PickStatus.TIEBREAKER) {
            picks.forEach((pick2: Pick) => {
                if (pick2.id === pick.id) {
                    pick2 = pick;
                }
            })
        } else {
            return;
        }
        // set props
        if (props !== undefined) {
            setProps({
                event: props.event,
                picks: picks,
                brackets: props.brackets,
                results: props.results,
                qualifiers: props.qualifiers,
            });
        }
    }

    function fourWideRemoveDrivers(picks: FourWidePick[], pick: FourWidePick, drivers_to_remove: (Driver | undefined)[]) {
        picks.forEach((pick2: FourWidePick) => {
            if(pick2.pick_status !== PickStatus.TIEBREAKER) {
                if (pick2.result.round > pick.result.round) {
                    if (drivers_to_remove.includes(pick2.competitor_1)) {
                        pick2.competitor_1 = undefined;
                        pick2.seed_1 = undefined;
                    } else if (drivers_to_remove.includes(pick2.competitor_2)) {
                        pick2.competitor_2 = undefined;
                        pick2.seed_2 = undefined;
                    } else if (drivers_to_remove.includes(pick2.competitor_3)) {
                        pick2.competitor_3 = undefined;
                        pick2.seed_3 = undefined;
                    } else if (drivers_to_remove.includes(pick2.competitor_4)) {
                        pick2.competitor_4 = undefined;
                        pick2.seed_4 = undefined;
                    }
                    if (drivers_to_remove.includes(pick2.first)) {
                        pick2.first = undefined;
                        pick2.first_seed = undefined;
                    } else if (drivers_to_remove.includes(pick2.second)) {
                        pick2.second = undefined;
                        pick2.second_seed = undefined;
                    } else if (drivers_to_remove.includes(pick2.third)) {
                        pick2.third = undefined;
                        pick2.third_seed = undefined;
                    } else if (drivers_to_remove.includes(pick2.fourth)) {
                        pick2.fourth = undefined;
                        pick2.fourth_seed = undefined;
                    }
                }
            }
        });
    }

    function updatePickFourWide(pick: FourWidePick, picks: FourWidePick[]) {
        if (pick.pick_status === PickStatus.PENDING) {
            if (pick.first) {
                picks.forEach((pick2: FourWidePick) => {
                    if(pick2.pick_status !== PickStatus.TIEBREAKER) {
                        if (pick2.result.competitor_1_game_code === pick.result.game_code) {
                            if (pick2.first && pick2.first === pick2.competitor_1) {
                                pick2.first = pick.first;
                            } else if (pick2.second && pick2.second === pick2.competitor_1) {
                                pick2.second = pick.first;
                            } else if (pick2.third && pick2.third === pick2.competitor_1) {
                                pick2.third = pick.first;
                            } else if (pick2.fourth && pick2.fourth === pick2.competitor_1) {
                                pick2.fourth = pick.first;
                            }
                            pick2.competitor_1 = pick.first;
                        } else if (pick2.result.competitor_3_game_code === pick.result.game_code) {
                            if (pick2.first && pick2.first === pick2.competitor_3) {
                                pick2.first = pick.first;
                            } else if (pick2.second && pick2.second === pick2.competitor_3) {
                                pick2.second = pick.first;
                            } else if (pick2.third && pick2.third === pick2.competitor_3) {
                                pick2.third = pick.first;
                            } else if (pick2.fourth && pick2.fourth === pick2.competitor_3) {
                                pick2.fourth = pick.first;
                            }
                            pick2.competitor_3 = pick.first;
                        }
                        if (pick2.result.competitor_2_game_code === pick.result.game_code && pick2.competitor_2 === pick.first) {
                            pick2.competitor_2 = undefined;
                            pick2.seed_2 = undefined;
                        } else if (pick2.result.competitor_4_game_code === pick.result.game_code && pick2.competitor_4 === pick.first) {
                            pick2.competitor_4 = undefined;
                            pick2.seed_4 = undefined;
                        }
                    }
                });
            }
            // If first place is not present validate no competitor is passed this race
            else {
                let drivers_to_remove = [
                    pick.competitor_1,
                    pick.competitor_2,
                    pick.competitor_3,
                    pick.competitor_4
                ].filter((driver: Driver | undefined) => driver !== undefined);
                fourWideRemoveDrivers(picks, pick, drivers_to_remove);
            }
            // Make sure second goes only into second or fourth competitor_slot_of next_pick
            if (pick.second) {
                picks.forEach((pick2: FourWidePick) => {
                    if(pick2.pick_status !== PickStatus.TIEBREAKER) {
                        if (pick2.result.competitor_2_game_code === pick.result.game_code) {
                            pick2.competitor_2 = pick.second;
                        } else if (pick2.result.competitor_4_game_code === pick.result.game_code) {
                            pick2.competitor_4 = pick.second;
                        }
                        if (pick2.result.competitor_1_game_code === pick.result.game_code && pick2.competitor_1 === pick.second) {
                            pick2.competitor_1 = undefined;
                            pick2.seed_1 = undefined;
                        } else if (pick2.result.competitor_3_game_code === pick.result.game_code && pick2.competitor_3 === pick.second) {
                            pick2.competitor_3 = undefined;
                            pick2.seed_3 = undefined;
                        }
                    }
                });
            }
            // If second place is not present validate no competitor is passed (except for first)
            else {
                if (pick.first) {
                    let drivers_to_remove = [
                        pick.competitor_1,
                        pick.competitor_2,
                        pick.competitor_3,
                        pick.competitor_4
                    ].filter((driver: Driver | undefined) => driver !== pick.first);
                    fourWideRemoveDrivers(picks, pick, drivers_to_remove);
                }
            }
            // Make sure third place and fourth place finishes are not in any future picks
            picks.forEach((pick2: FourWidePick) => {
                picks.forEach((pick3: FourWidePick) => {
                    if (pick3.result.round > pick2.result.round) {
                        if (pick2.third === pick3.competitor_1) {
                            pick3.competitor_1 = undefined;
                            pick3.seed_1 = undefined;
                        } else if (pick2.third === pick3.competitor_2) {
                            pick3.competitor_2 = undefined;
                            pick3.seed_2 = undefined;
                        } else if (pick2.third === pick3.competitor_3) {
                            pick3.competitor_3 = undefined;
                            pick3.seed_3 = undefined;
                        } else if (pick2.third === pick3.competitor_4) {
                            pick3.competitor_4 = undefined;
                            pick3.seed_4 = undefined;
                        }
                        if (pick2.third === pick3.first) {
                            pick3.first = undefined;
                            pick3.first_seed = undefined;
                        } else if (pick2.third === pick3.second) {
                            pick3.second = undefined;
                            pick3.second_seed = undefined;
                        } else if (pick2.third === pick3.third) {
                            pick3.third = undefined;
                            pick3.third_seed = undefined;
                        } else if (pick2.third === pick3.fourth) {
                            pick3.fourth = undefined;
                            pick3.fourth_seed = undefined;
                        }
                        if (pick2.fourth === pick3.competitor_1) {
                            pick3.competitor_1 = undefined;
                            pick3.seed_1 = undefined;
                        } else if (pick2.fourth === pick3.competitor_2) {
                            pick3.competitor_2 = undefined;
                            pick3.seed_2 = undefined;
                        } else if (pick2.fourth === pick3.competitor_3) {
                            pick3.competitor_3 = undefined;
                            pick3.seed_3 = undefined;
                        } else if (pick2.fourth === pick3.competitor_4) {
                            pick3.competitor_4 = undefined;
                            pick3.seed_4 = undefined;
                        }
                        if (pick2.fourth === pick3.first) {
                            pick3.first = undefined;
                            pick3.first_seed = undefined;
                        } else if (pick2.fourth === pick3.second) {
                            pick3.second = undefined;
                            pick3.second_seed = undefined;
                        } else if (pick2.fourth === pick3.third) {
                            pick3.third = undefined;
                            pick3.third_seed = undefined;
                        } else if (pick2.fourth === pick3.fourth) {
                            pick3.fourth = undefined;
                            pick3.fourth_seed = undefined;
                        }
                    }
                });
            });
        } else if (pick.pick_status === PickStatus.TIEBREAKER) {
            picks.forEach((pick2: Pick) => {
                if (pick2.id === pick.id) {
                    pick2 = pick;
                }
            })
        } else {
            return;
        }
        let startingRound = pick.result.round;
        let round = pick.result.round + 1;
        let currentPick = pick;
        let nextPick: FourWidePick | undefined = undefined;
        while (round < 4) {
            picks.forEach((pick2: FourWidePick) => {
                if (pick2.result.round === round && pick2.pick_status !== PickStatus.TIEBREAKER) {
                    if ([pick2.result.competitor_1_game_code,
                        pick2.result.competitor_2_game_code,
                        pick2.result.competitor_3_game_code,
                        pick2.result.competitor_4_game_code].includes(currentPick.result.game_code)) {

                        pick2.first = undefined;
                        pick2.second = undefined;
                        pick2.third = undefined;
                        pick2.fourth = undefined;
                        pick2.first_seed = undefined;
                        pick2.second_seed = undefined;
                        pick2.third_seed = undefined;
                        pick2.fourth_seed = undefined;
                        nextPick = pick2;
                        if (round > startingRound + 1) {
                            if (pick2.result.competitor_1_game_code === currentPick.result.game_code) {
                                pick2.competitor_1 = undefined;
                                pick2.seed_1 = undefined;
                                pick2.competitor_2 = undefined;
                                pick2.seed_2 = undefined;
                            } else if (pick2.result.competitor_3_game_code === currentPick.result.game_code) {
                                pick2.competitor_3 = undefined;
                                pick2.seed_3 = undefined;
                                pick2.competitor_4 = undefined;
                                pick2.seed_4 = undefined;
                            }
                        }
                    }
                }
            });
            if (nextPick !== undefined) {
                currentPick = nextPick;
                round++;
            } else {
                break;
            }
        }
        picks.forEach((pick2: FourWidePick) => {
            if(pick2.competitor_1){
                pick2.seed_1 = props?.qualifiers.find((qualifier) => {return qualifier.driver === pick2.competitor_1})?.seed;
            }
            else{
                pick2.seed_1 = undefined;
            }
            if(pick2.competitor_2){
                pick2.seed_2 = props?.qualifiers.find((qualifier) => {return qualifier.driver === pick2.competitor_2})?.seed;
            }
            else{
                pick2.seed_2 = undefined;
            }
            if(pick2.competitor_3){
                pick2.seed_3 = props?.qualifiers.find((qualifier) => {return qualifier.driver === pick2.competitor_3})?.seed;
            }
            else{
                pick2.seed_3 = undefined;
            }
            if(pick2.competitor_4){
                pick2.seed_4 = props?.qualifiers.find((qualifier) => {return qualifier.driver === pick2.competitor_4})?.seed;
            }
            else{
                pick2.seed_4 = undefined;
            }
            if(pick2.first){
                pick2.first_seed = props?.qualifiers.find((qualifier) => {return qualifier.driver === pick2.first})?.seed;
            }
            else{
                pick2.first_seed = undefined;
            }
            if(pick2.second){
                pick2.second_seed = props?.qualifiers.find((qualifier) => {return qualifier.driver === pick2.second})?.seed;
            }
            else{
                pick2.second_seed = undefined;
            }
            if(pick2.third){
                pick2.third_seed = props?.qualifiers.find((qualifier) => {return qualifier.driver === pick2.third})?.seed;
            }
            else{
                pick2.third_seed = undefined;
            }
            if(pick2.fourth){
                pick2.fourth_seed = props?.qualifiers.find((qualifier) => {return qualifier.driver === pick2.fourth})?.seed;
            }
            else{
                pick2.fourth_seed = undefined;
            }
        });
        if (props !== undefined) {
            setProps({
                event: props.event,
                picks: picks,
                brackets: props.brackets,
                results: props.results,
                qualifiers: props.qualifiers,
            });
        }
    }

    const containerRef = useRef<HTMLDivElement>(null);


    function cleanFourWidePicksForSave() {
        let picksCopy: any = (props?.picks as FourWidePick[]).map((pick: FourWidePick) => ({...pick}));
        picksCopy.forEach((pick: any) => {
            pick.pick_status = pick.pick_status + 1;
            pick.possible_points = calculatePossiblePointsFourWide(pick);
            pick.result_id = pick.result.game_code;
            delete pick.result;
            pick.league_id = leagueId;
            delete pick.league
            pick.competitor_1_id = pick.competitor_1?.id ?? null;
            delete pick.competitor_1;
            pick.competitor_2_id = pick.competitor_2?.id ?? null;
            delete pick.competitor_2;
            pick.competitor_3_id = pick.competitor_3?.id ?? null;
            delete pick.competitor_3;
            pick.competitor_4_id = pick.competitor_4?.id ?? null;
            delete pick.competitor_4;
            pick.first_id = pick.first?.id ?? null;
            delete pick.first;
            pick.second_id = pick.second?.id ?? null;
            delete pick.second;
            pick.third_id = pick.third?.id ?? null;
            delete pick.third;
            pick.fourth_id = pick.fourth?.id ?? null;
            delete pick.fourth;
            pick.first_seed = props?.qualifiers.find((qualifier: Qualifier) => {return qualifier.driver !== undefined && qualifier.driver.id === pick.first_id})?.seed ?? null;
            pick.second_seed = props?.qualifiers.find((qualifier: Qualifier) => {return qualifier.driver !== undefined && qualifier.driver.id === pick.second_id})?.seed ?? null;
            pick.third_seed = props?.qualifiers.find((qualifier: Qualifier) => {return qualifier.driver !== undefined && qualifier.driver.id === pick.third_id})?.seed ?? null;
            pick.fourth_seed = props?.qualifiers.find((qualifier: Qualifier) => {return qualifier.driver !== undefined && qualifier.driver.id === pick.fourth_id})?.seed ?? null;
            pick.seed_1 = props?.qualifiers.find((qualifier: Qualifier) => {return qualifier.driver !== undefined && qualifier.driver.id === pick.competitor_1_id})?.seed ?? null;
            pick.seed_2 = props?.qualifiers.find((qualifier: Qualifier) => {return qualifier.driver !== undefined && qualifier.driver.id === pick.competitor_2_id})?.seed ?? null;
            pick.seed_3 = props?.qualifiers.find((qualifier: Qualifier) => {return qualifier.driver !== undefined && qualifier.driver.id === pick.competitor_3_id})?.seed ?? null;
            pick.seed_4 = props?.qualifiers.find((qualifier: Qualifier) => {return qualifier.driver !== undefined && qualifier.driver.id === pick.competitor_4_id})?.seed ?? null;
            pick.tiebreaker = pick.tiebreaker ?? null;
            pick.player_id = userId;
            delete pick.player;
            if (![pick.first_id, pick.second_id, pick.third_id].includes(null) && pick.fourth_id === null) {
                if (![pick.first_id, pick.second_id, pick.third_id].includes(pick.competitor_1_id) && pick.competitor_1_id !== null) {
                    pick.fourth_id = pick.competitor_1_id;
                } else if (![pick.first_id, pick.second_id, pick.third_id].includes(pick.competitor_2_id) && pick.competitor_2_id !== null) {
                    pick.fourth_id = pick.competitor_2_id;
                } else if (![pick.first_id, pick.second_id, pick.third_id].includes(pick.competitor_3_id) && pick.competitor_3_id !== null) {
                    pick.fourth_id = pick.competitor_3_id;
                } else if (![pick.first_id, pick.second_id, pick.third_id].includes(pick.competitor_4_id) && pick.competitor_4_id !== null) {
                    pick.fourth_id = pick.competitor_4_id;
                }
            }
        });
        return picksCopy;
    }

    function calculatePossiblePointsTwoWide(pick: any) {
        let possible_points = 0;
        if(pick.pick_status !== 4) {
            if (pick.seed_1 && pick.seed_2 && pick.winner_seed) {
                possible_points = Math.pow(2, pick.result.round - 1);
                if ((pick.seed_1 - pick.seed_2 >= 7 && pick.seed_1 === pick.winner_seed) ||
                    (pick.seed_2 - pick.seed_1 >= 7 && pick.seed_2 === pick.winner_seed)) {
                    possible_points *= 2;
                }
            }
            possible_points *= props?.brackets.find((bracket: Bracket) => {
                return bracket.id === pick.result.bracket_id
            })?.points_modifier ?? 1;
        }
        return possible_points;
    }

    function calculatePossiblePointsFourWide(pick: any) {
        let possible_points = 0;
        if(pick.pick_status !== 4) {
            if (pick.first && pick.second && pick.third && pick.fourth) {
                switch(pick.result.round) {
                    case 1:
                        possible_points = 5;
                        break;
                    case 2:
                        possible_points = 9;
                        break;
                    case 3:
                        possible_points = 17;
                        break;
                }
            }
        }
        return possible_points;
    }

    function cleanPicksForSave() {
        let picksCopy: any = (props?.picks as Pick[]).map((pick: Pick) => ({...pick}));
        picksCopy.forEach((pick: any) => {
            pick.pick_status = pick.pick_status + 1;
            pick.possible_points = calculatePossiblePointsTwoWide(pick);
            pick.result_id = pick.result.game_code;
            delete pick.result;
            pick.league_id = leagueId;
            delete pick.league
            pick.competitor_1_id = pick.competitor_1?.id ?? null;
            delete pick.competitor_1;
            pick.competitor_2_id = pick.competitor_2?.id ?? null;
            delete pick.competitor_2;
            pick.winner_id = pick.winner?.id ?? null;
            delete pick.winner;
            pick.winner_seed = pick.winner_seed ?? null;
            pick.loser_seed = pick.loser_seed ?? null;
            pick.seed_1 = pick.seed_1 ?? null;
            pick.seed_2 = pick.seed_2 ?? null;
            pick.tiebreaker = pick.tiebreaker ?? null;
            pick.loser_id = pick.loser?.id ?? null;
            delete pick.loser;
            pick.player_id = userId;
            delete pick.player;
            if (pick.winner_id !== null && pick.loser_id === null) {
                if (pick.competitor_1_id !== pick.winner_id) {
                    pick.loser_id = pick.competitor_1_id;
                } else {
                    pick.loser_id = pick.competitor_2_id;
                }
            }
        });
        return picksCopy;
    }

    function handleAutoSave(response: PickResponse) {
        if (response.succeeded) {
            if (changed !== "changed") {
                setChanged("up-to-date")
            }
        }
    }

    function getShareLink() {
        return "https://dragracebracketbonanza.com/bracket/?" + encodeURIComponent("event_id=" + eventId + "&league_id=" + leagueId + "&user_id=" + userId);
    }

    async function handleSubmitPicks(submit: boolean) {
        setChanged("sending");
        if (props?.event.is_four_wide) {
            let picksCopy = cleanFourWidePicksForSave();
            let response = await submitPicksFourWide(localStorage.getItem('access_token') ?? "", localStorage.getItem('refresh_token') ?? "", picksCopy)
            if (submit) {
                if (response.succeeded) {
                    setMessage("Picks Submitted Successfully. Returning to Leagues Page.");
                    setSeverity("success");
                    setOpen(true);
                    setTimeout(() => {
                        window.location.href = "/leagues/";
                    }, 3000);
                } else {
                    if(response.message) {
                        setMessage(response.message);
                    }
                    else{
                        setMessage("Failed to submit picks, please refresh the page and try again.")
                    }
                    setSeverity("error");
                    setOpen(true);
                }
                GA4EventSend('bracket', 'submit', 'bracket', response.statusCode, false)
            }
            handleAutoSave(response);
        } else {
            let picksCopy = cleanPicksForSave();
            let response = await submitPicks(localStorage.getItem('access_token') ?? "", localStorage.getItem('refresh_token') ?? "", picksCopy)
            if (submit) {
                if (response.succeeded) {
                    setMessage("Picks Submitted Successfully. Returning to Leagues Page.");
                    setSeverity("success");
                    setOpen(true);
                    setTimeout(() => {
                        window.location.href = "/leagues/";
                    }, 3000);
                } else {
                    if(response.message) {
                        setMessage(response.message);
                    }
                    else{
                        setMessage("Failed to submit picks, please refresh the page and try again.")
                    }
                    setSeverity("error");
                    setOpen(true);
                }
            }
            GA4EventSend('bracket', 'submit', 'bracket', response.statusCode, false)
            handleAutoSave(response);
        }
    }

    function updatePick(pick: Pick | FourWidePick) {
        if (props?.event.is_four_wide) {
            updatePickFourWide(pick as FourWidePick, props?.picks as FourWidePick[]);
        } else {
            updatePickTwoWide(pick as Pick);
        }
        setChanged("changed")
    }

    const handleClickOpen = () => {
        setOpenScoring(true);
    };

    const handleClose = () => {
        setOpenScoring(false);
    };

    return (
        <>
            <Helmet>
                <title>DRBB | Bracket</title>
            </Helmet>
            <Layout>
                <CssBaseline/>
                <Snackbar anchorOrigin={{vertical: "top", horizontal: "center"}} open={open} onClose={() => {
                    setOpen(false)
                }}
                          autoHideDuration={6000}>
                    <Alert elevation={6} severity={severity} sx={{width: '100%'}} variant="filled">
                        {message}
                    </Alert>
                </Snackbar>
                <Dialog
                    open={resetOpen}
                    onClose={handleResetClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Reset Picks"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to reset your picks?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleResetClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={() => {
                            if (props?.event && user) {
                                setProps({
                                    event: props.event,
                                    picks: generateBlankPicks({
                                        isFourWide: props.event.is_four_wide,
                                        player: user,
                                        league: props.picks[0].league,
                                        results: props.results,
                                    }),
                                    brackets: props.brackets,
                                    results: props.results,
                                    qualifiers: props.qualifiers,
                                });
                                setChanged("changed");
                            }
                            handleResetClose();
                        }} color="primary" autoFocus>
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={helpOpen}
                    onClose={handleResetClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {
                                isFourWide ?
                                    `
                                    For each matchup, click the drivers in 1-2-3-4 order. The first two drivers you click will be highlighted in green, 
                                    indicating they will advance to the next round. Do this for all three rounds. The final round you will select a race winner, 
                                    runner-up and 3rd and 4th place. Don't forget to then fill out the Fastest Speed tiebreaker for each professional class.
                                    `
                                    :
                                    `
                                    For each matchup, click the driver you want to advance to the next round. Your pick should be highlighted in green. 
                                    Do this for all four rounds. The final round you will select your race winner. Don't forget to then fill out the 
                                    Fastest Speed tiebreaker for each professional class.
                                    `
                            }
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {
                            handleHelpClose()
                        }} color="primary" autoFocus>
                            Got It
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={shareOpen}
                    onClose={handleShareClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title" variant={"h6"}
                                 align={"left"}>{"Share Your Bracket"}</DialogTitle>
                    <Divider/>
                    <DialogContent>
                        <Grid container sx={{
                            minWidth: 'min(350px, 60vw)',
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            flexDirection: "row",
                            justifyContent: 'space-evenly',
                        }}>
                            <FacebookShareButton url={getShareLink()} hashtag={"#DRBB"}>
                                <Grid container>
                                    <FacebookIcon style={{color: '#1877f2', paddingRight: 2}}/>
                                    <Typography variant="body2" color="text.secondary" align="left">
                                        Facebook
                                    </Typography>
                                </Grid>
                            </FacebookShareButton>
                            <TwitterShareButton title={`View My Bracket For ${props?.event.name}`} via={"dragracebb"}
                                                url={getShareLink()}
                                                hashtags={["DRBB", "NHRA", "DragRacing", "Bracket"]}>
                                <Grid container>
                                    <TwitterIcon style={{color: '#1da1f2', paddingRight: 2}}/>
                                    <Typography variant="body2" color="text.secondary" align="left">
                                        Twitter
                                    </Typography>
                                </Grid>
                            </TwitterShareButton>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" autoFocus onClick={handleShareClose}>
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
                <Grid container sx={{
                    display: 'flex',
                    flexDirection: "column",
                    height: '100%',
                    maxWidth: '100vw',
                }}>
                    <IconButton color="inherit" onClick={handleClickOpen} sx={{alignSelf: 'flex-end'}}>
                        <Typography variant={"body1"} fontWeight={"bold"} sx={{marginRight: '8px'}}>
                            Scoring Info
                        </Typography>
                        <HelpOutlineIcon/>
                    </IconButton>
                    <Dialog open={openScoring} onClose={handleClose}>
                        <DialogTitle>{"How Are My Picks Scored?"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {props?.event.is_four_wide
                                    ?
                                    `
                                    Each pick is worth different amounts of points depending on the round. 
                                    Each pick in the first round is worth one point, the second round is worth 
                                    two points, the semi-finals are worth four points, and the finals are worth eight 
                                    points. The tiebreaker is the top speed during eliminations which is worth an 
                                    additional point if correct up to the hundredths place. For each pick, if you 
                                    pick a driver to win who is 7 or more seeds lower than the loser, you will receive 
                                    double the usual amount of points for that pick (for example if you pick a 12 seed 
                                    to beat a 5 seed in the second round, it will be worth 4 points instead of 2).
                                    `
                                    :
                                    `
                                    Each pick is worth different amounts of points depending on the round. 
                                    Each pick in the first round is worth one point, the semi-finals round 
                                    is worth two points, and the finals are worth four points. The tiebreaker 
                                    is the top speed during eliminations which is worth an additional point if 
                                    correct up to the hundredths place. For each matchup you are required to pick 
                                    who will get first, second, third, and fourth. You will be fully awarded the 
                                    amount of points if you correctly pick the correct placing for a driver in that 
                                    round. If you are within one placing for a driver, you will receive half the normal
                                    points. If you more than two placings off you will receive no points. 
                                    If you get every placing correct for a round, you will receive one additional point.
                                    There are no bonus points for picking lower seeds in four-wide. So for example, 
                                    if you pick all four placings correctly in a first round race, that race will give 
                                    you 5 points. Another example is if you get one placing correct, two off by one, 
                                    and one off by two in a second round race, you will receive 1 + 0.5 + 0.5 + 0 = 2 
                                    points for that round.
                                    `
                                }
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>
                    {!loading && props?.event !== undefined && league !== undefined &&
                        <Grid container sx={{
                            display: 'flex',
                            flexDirection: "column",
                            maxWidth: '100vw',
                            alignItems: 'center',
                            padding: '12px',
                            justifyContent: 'center',
                        }}>
                            <Grid container sx={{
                                backgroundColor: 'background.paper',
                                justifyContent: 'center',
                                display: 'flex',
                                flexDirection: 'column',
                                margin: 'auto',
                                width: 'fit-content',
                                alignItems: 'center',
                                padding: '8px',
                                borderRadius: '8px',
                            }}>
                                <Typography variant={"h4"} align={"center"} marginRight={'12px'} fontWeight={"bold"}>
                                    {`${nickname}'s Bracket`}
                                </Typography>
                                <Typography variant={"subtitle2"} align={"left"}>
                                    {`(${props.event.name} - ${league.name})`}
                                </Typography>
                            </Grid>
                        </Grid>
                    }
                    {practiceBracket &&
                        <Grid container sx={{
                            display: 'flex',
                            flexDirection: "row",
                            maxWidth: '100vw',
                            alignItems: 'center',
                            padding: '12px',
                            width: '100%',
                            justifyContent: 'center',
                        }}>
                            <Typography variant={"h4"} align={"center"} marginRight={'12px'} fontWeight={"bold"}>
                                Practice Filling Out A Bracket
                            </Typography>
                        </Grid>
                    }
                    {props?.event !== undefined && ownPicks &&
                        <Toolbar
                            component="nav"
                            variant="dense"
                            sx={{
                                justifyContent: 'space-between',
                                overflowX: 'auto',
                                maxWidth: '100vw',
                            }}
                        >
                            {!practiceBracket && !props.event.picks_locked &&
                                <Button
                                    variant="contained"
                                    endIcon={<PublishIcon/>}
                                    sx={{
                                        minWidth: '120px',
                                        backgroundColor: "#486ce7",
                                        color: "#ffffff",
                                        marginX: 1,
                                        ":hover": {
                                            backgroundColor: "#c40000",
                                            color: "#ffffff",
                                        }
                                    }}
                                    disabled={isClicked}
                                    onClick={() => {
                                        setIsClicked(true);
                                        handleSubmitPicks(true).then(() => {
                                            setIsClicked(false);
                                        });
                                    }}
                                >
                                    <Typography variant={"h6"} fontWeight={"bold"}>
                                        Submit
                                    </Typography>
                                </Button>
                            }
                            {!practiceBracket &&
                                <Button
                                    variant="contained"
                                    endIcon={<IosShareIcon/>}
                                    sx={{
                                        minWidth: '120px',
                                        backgroundColor: "#486ce7",
                                        color: "#ffffff",
                                        marginX: 1,
                                        ":hover": {
                                            backgroundColor: "#c40000",
                                            color: "#ffffff",
                                        }
                                    }}
                                    onClick={() => {
                                        handleShareClickOpen()
                                    }}
                                >
                                    <Typography variant={"h6"} fontWeight={"bold"}>
                                        Share
                                    </Typography>
                                </Button>
                            }
                            {!practiceBracket && isMdOrUp &&
                                <ReactToPrint
                                    content={() => containerRef.current}
                                    trigger={() => (
                                        <Button
                                            variant="contained"
                                            endIcon={<PrintIcon/>}
                                            sx={{
                                                minWidth: '120px',
                                                backgroundColor: "#486ce7",
                                                color: "#ffffff",
                                                marginX: 1,
                                                ":hover": {
                                                    backgroundColor: "#c40000",
                                                    color: "#ffffff",
                                                }
                                            }}
                                        >
                                            <Typography variant={"h6"} fontWeight={"bold"}>
                                                Print
                                            </Typography>
                                        </Button>
                                    )}
                                />
                            }
                            {!props.event.picks_locked &&
                                <Button
                                    variant="contained"
                                    sx={{
                                        minWidth: '150px',
                                        backgroundColor: "#486ce7",
                                        color: "#ffffff",
                                        marginX: 1,
                                        ":hover": {
                                            backgroundColor: "#c40000",
                                            color: "#ffffff",
                                        }
                                    }}
                                    endIcon={<ShuffleIcon/>}
                                    onClick={() => {
                                        if (props?.event && user) {
                                            if (props.event.is_four_wide) {
                                                let randomizedPicks = randomizePicksFourWide(generateBlankPicks({
                                                    isFourWide: true,
                                                    player: user,
                                                    league: props.picks[0].league,
                                                    results: props.results,
                                                }) as FourWidePick[]);
                                                randomizedPicks.forEach((pick2: FourWidePick) => {
                                                    if(pick2.competitor_1){
                                                        pick2.seed_1 = props?.qualifiers.find((qualifier) => {return qualifier.driver === pick2.competitor_1})?.seed;
                                                    }
                                                    else{
                                                        pick2.seed_1 = undefined;
                                                    }
                                                    if(pick2.competitor_2){
                                                        pick2.seed_2 = props?.qualifiers.find((qualifier) => {return qualifier.driver === pick2.competitor_2})?.seed;
                                                    }
                                                    else{
                                                        pick2.seed_2 = undefined;
                                                    }
                                                    if(pick2.competitor_3){
                                                        pick2.seed_3 = props?.qualifiers.find((qualifier) => {return qualifier.driver === pick2.competitor_3})?.seed;
                                                    }
                                                    else{
                                                        pick2.seed_3 = undefined;
                                                    }
                                                    if(pick2.competitor_4){
                                                        pick2.seed_4 = props?.qualifiers.find((qualifier) => {return qualifier.driver === pick2.competitor_4})?.seed;
                                                    }
                                                    else{
                                                        pick2.seed_4 = undefined;
                                                    }
                                                    if(pick2.first){
                                                        pick2.first_seed = props?.qualifiers.find((qualifier) => {return qualifier.driver === pick2.first})?.seed;
                                                    }
                                                    else{
                                                        pick2.first_seed = undefined;
                                                    }
                                                    if(pick2.second){
                                                        pick2.second_seed = props?.qualifiers.find((qualifier) => {return qualifier.driver === pick2.second})?.seed;
                                                    }
                                                    else{
                                                        pick2.second_seed = undefined;
                                                    }
                                                    if(pick2.third){
                                                        pick2.third_seed = props?.qualifiers.find((qualifier) => {return qualifier.driver === pick2.third})?.seed;
                                                    }
                                                    else{
                                                        pick2.third_seed = undefined;
                                                    }
                                                    if(pick2.fourth){
                                                        pick2.fourth_seed = props?.qualifiers.find((qualifier) => {return qualifier.driver === pick2.fourth})?.seed;
                                                    }
                                                    else{
                                                        pick2.fourth_seed = undefined;
                                                    }
                                                });
                                                setProps({
                                                    event: props.event,
                                                    picks: randomizedPicks,
                                                    brackets: props.brackets,
                                                    results: props.results,
                                                    qualifiers: props.qualifiers,
                                                });
                                            } else {
                                                let randomizedPicks = randomizePicksTwoWide(props.picks as Pick[]);
                                                setProps({
                                                    event: props.event,
                                                    picks: randomizedPicks,
                                                    brackets: props.brackets,
                                                    results: props.results,
                                                    qualifiers: props.qualifiers,
                                                });
                                            }
                                        }
                                    }}
                                >
                                    <Typography variant={"h6"} fontWeight={"bold"}>
                                        Randomize
                                    </Typography>
                                </Button>
                            }
                            {!props.event.picks_locked &&
                                <Button
                                    variant="contained"
                                    endIcon={<RestartAltIcon/>}
                                    sx={{
                                        minWidth: '120px',
                                        backgroundColor: "#486ce7",
                                        color: "#ffffff",
                                        marginX: 1,
                                        ":hover": {
                                            backgroundColor: "#c40000",
                                            color: "#ffffff",
                                        }
                                    }}
                                    onClick={() => {
                                        handleResetClickOpen()
                                    }}
                                >
                                    <Typography variant={"h6"} fontWeight={"bold"}>
                                        Reset
                                    </Typography>
                                </Button>
                            }
                        </Toolbar>
                    }
                    {props?.picks !== undefined && props.picks.length > 0 ?
                        props?.event.is_four_wide ?
                            <Box sx={{
                                overflowX: 'auto',
                                display: 'flex',
                                justifyContent: 'center',
                                width: '100%',
                            }}>
                                <PickBracketContainerFourWide
                                    picks_locked={props?.event.picks_locked || !ownPicks}
                                    brackets={props?.brackets}
                                    picks={props?.picks}
                                    results={props?.results}
                                    updatePick={updatePick}
                                    event={props?.event}
                                />
                            </Box>

                            :
                            <Box sx={{
                                overflowX: 'auto',
                                display: 'flex',
                                justifyContent: 'center',
                                width: '100%',
                            }}>
                                <PickBracketContainer
                                    picks_locked={props?.event.picks_locked || !ownPicks}
                                    brackets={props?.brackets}
                                    picks={props?.picks}
                                    results={props?.results}
                                    updatePick={updatePick}
                                    event={props?.event}
                                />
                            </Box>
                        :
                        <Grid container sx={{height: '50vh', width: '100%'}}>
                            {loading ? <Loader/> : <Typography variant={"h6"} align={"center"} margin={"auto"}>Picks are not available to be made at this time.</Typography>}
                        </Grid>
                    }
                </Grid>
                {props &&
                    <div style={{display: "none"}}>
                        <div ref={containerRef}>
                            <Grid container sx={{
                                width: "100vw",
                                height: "100vh",
                                backgroundColor: "white",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                alignContent: "center",
                                '@media print': {
                                    '@page': {
                                        size: 'landscape',
                                    },
                                    'html, body': {
                                        size: 'landscape',
                                    },
                                    'body *': {
                                        visibility: 'hidden',
                                    },
                                    '&, & *': {
                                        visibility: 'visible',
                                    },
                                    position: 'absolute',
                                    left: 0,
                                    top: 0,
                                },
                            }}>
                                {props?.event.is_four_wide ?
                                    <PrintBracketContainerFourWide
                                        picks_locked={props.event.picks_locked}
                                        brackets={props.brackets}
                                        picks={props.picks}
                                        updatePick={() => {
                                        }}
                                        results={props.results}
                                        event={props.event}
                                        title={`${nickname}'s ${props.event.name} Bracket`}
                                    />
                                    :
                                    <PrintBracketContainer
                                        picks_locked={props.event.picks_locked}
                                        brackets={props.brackets}
                                        picks={props.picks}
                                        updatePick={() => {
                                        }}
                                        results={props.results}
                                        event={props.event}
                                        title={`${nickname}'s ${props.event.name} Bracket`}
                                    />
                                }
                            </Grid>
                        </div>
                    </div>
                }
            </Layout>
        </>
    );
}
