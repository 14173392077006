import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Logo from "../assets/images/logo.png";
import * as Auth from "../apis/Auth";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {Alert, IconButton, InputAdornment, List, ListItem, Snackbar} from "@mui/material";
import {useState} from "react";
import MuiPhoneNumber from "material-ui-phone-number-2";
import * as Mail from "../apis/Mailchimp";
import GavelIcon from '@mui/icons-material/Gavel';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import LoginIcon from "@mui/icons-material/Login";
import {Helmet} from "react-helmet";
import GA4EventSend from "../utilities/GA4EventSend";
import Sign_Up_Graphic from "../assets/images/sign_up_graphic.png";
import {Visibility, VisibilityOff} from "@mui/icons-material";

export default function SignUp() {
    const [open, setOpen] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [phone, setPhone] = useState<string>("");
    const [isClicked, setIsClicked] = useState(false);
    const [passwordIsValid, setPasswordIsValid] = useState<boolean>(false);
    const [repasswordIsValid, setRepasswordIsValid] = useState<boolean>(false);
    const [buttonEnabled, setButtonEnabled] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showRePassword, setShowRePassword] = useState(false);

    function handleChange(event: React.ChangeEvent<HTMLFormElement>) {
        const data = new FormData(event.currentTarget);
        const password: string = data.get('password') as string | "";
        const valid_password: boolean = password.length >= 8 && !RegExp(/^\d+$/).exec(password);
        setPasswordIsValid(valid_password);
        const repassword: string = data.get('re-password') as string | "";
        const repasswordIsValid: boolean = repassword.length >= 8 && !RegExp(/^\d+$/).exec(repassword) && repassword === password;
        setRepasswordIsValid(repasswordIsValid);
        setButtonEnabled(
            data.get('firstName') !== "" &&
            data.get('lastName') !== "" &&
            data.get('email') !== "" &&
            data.get('nickname') !== "" &&
            data.get('password') !== "" &&
            data.get('re-password') !== "" &&
            data.get('agreeTOS') !== null &&
            valid_password && repasswordIsValid
        );
    }

    async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        setIsClicked(true);
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        // Try Sign In first, if it fails, then Sign Up
        const signInResponse: Auth.AuthResponse = await Auth.SignIn((data.get('email') as string).toLowerCase(), data.get('password') as string);
        if (signInResponse.succeeded) {
            GA4EventSend("sign_in", "user", "sign_in", signInResponse.statusCode + 1, false);
            localStorage.setItem('access_token', signInResponse.data.access);
            localStorage.setItem('refresh_token', signInResponse.data.refresh);
            localStorage.setItem('signed_in', "true");
            const about_me: Auth.AuthResponse = await Auth.about_me(signInResponse.data.refresh, signInResponse.data.access);
            if (about_me.succeeded) {
                GA4EventSend("about_me", "user", "about_me", about_me.statusCode, false);
                localStorage.setItem('user', JSON.stringify(about_me.data));
                window.location.href = "/";
            } else {
                GA4EventSend("about_me", "user", "about_me", about_me.statusCode, false);
                setMessage("Tried to sign in, but failed to get user information.");
                setOpen(true);
            }
            setIsClicked(false);
            return;
        }
        // Sign Up
        const response: Auth.AuthResponse = await Auth.SignUp((data.get('email') as string).toLowerCase(), data.get('password') as string, data.get('re-password') as string, data.get('firstName') as string, data.get('lastName') as string, data.get('nickname') as string, phone);
        if (response.succeeded) {
            GA4EventSend("sign_up", "user", "sign_up", response.statusCode, false);
            const email_subscribe: boolean = data.get('allowPromotions') !== null;
            const sms_subscribe: boolean = phone.length === 17;
            // If Sign Up is successful, then Sign In
            const signInResponse: Auth.AuthResponse = await Auth.SignIn((data.get('email') as string).toLowerCase(), data.get('password') as string);
            if (signInResponse.succeeded) {
                localStorage.setItem('access_token', signInResponse.data.access);
                localStorage.setItem('refresh_token', signInResponse.data.refresh);
                localStorage.setItem('signed_in', "true");
                // If Sign In is successful, then manage Mailchimp subscription and get user information
                const mailchimp_response: Mail.MailResponse = await Mail.manage_subscribe(response.data.id, email_subscribe, sms_subscribe);
                const about_me: Auth.AuthResponse = await Auth.about_me(signInResponse.data.refresh, signInResponse.data.access);
                if(mailchimp_response.succeeded) {
                    GA4EventSend("mailchimp", "user", "mailchimp", mailchimp_response.statusCode, false);
                }
                else{
                    GA4EventSend("mailchimp", "user", "mailchimp", mailchimp_response.statusCode, false);
                }
                if (about_me.succeeded) {
                    GA4EventSend("about_me", "user", "about_me", about_me.statusCode, false);
                    localStorage.setItem('user', JSON.stringify(about_me.data));
                    window.location.href = "/intro";
                } else {
                    GA4EventSend("about_me", "user", "about_me", about_me.statusCode, false);
                    setMessage(about_me.message);
                    setOpen(true);
                }
            } else {
                GA4EventSend("sign_in", "user", "sign_in", signInResponse.statusCode, false);
                setMessage(signInResponse.message);
                setOpen(true);
            }
        } else {
            GA4EventSend("sign_up", "user", "sign_up", response.statusCode, false);
            setMessage(response.message);
            setOpen(true);
        }
        setIsClicked(false);
    }

    return (
        <Grid container>
            <CssBaseline/>
            <Helmet>
                <title>DRBB | Sign Up</title>
            </Helmet>
            <Snackbar anchorOrigin={{vertical: "top", horizontal: "center"}} open={open} onClose={() => setOpen(false)}
                      autoHideDuration={6000}>
                <Alert elevation={6} severity="error" sx={{width: '100%'}} variant="filled">
                    {message}
                </Alert>
            </Snackbar>
            <Grid
                item
                xs={false}
                sm={4}
                md={7}
                sx={{
                    backgroundImage: `url(${Sign_Up_Graphic})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundColor: (t) =>
                        t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                    backgroundSize: 'cover',
                    backgroundPosition: 'right',
                }}
            />
            <Grid item xs={12} sm={8} md={5} sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                backgroundColor: (t) =>
                    t.palette.mode === 'light' ? t.palette.grey[50] : "#141414",
            }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        overflow: 'auto',
                        height: '100vh',
                        padding: '20px',
                    }}
                >
                    <img src={Logo} alt={"DRBB Logo"} height={'200'}/>
                    <Typography component="h1" variant="h3" sx={{mt: 1}} fontWeight={"bold"}>
                        Sign Up
                    </Typography>
                    <Box component="form" noValidate onSubmit={handleSubmit} onChange={handleChange} sx={{mt: 3}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    autoComplete="given-name"
                                    name="firstName"
                                    required
                                    fullWidth
                                    id="firstName"
                                    label="First Name"
                                    autoFocus
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    fullWidth
                                    id="lastName"
                                    label="Last Name"
                                    name="lastName"
                                    autoComplete="family-name"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    autoComplete="email"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="nickname"
                                    label="Nickname"
                                    name="nickname"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    color={passwordIsValid ? "success" : "error"}
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type={showPassword ? "text" : "password"}
                                    id="password"
                                    autoComplete="new-password"
                                    onChange={() => {
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => setShowPassword(!showPassword)}
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    color={repasswordIsValid ? "success" : "error"}
                                    required
                                    fullWidth
                                    name="re-password"
                                    label="Password Again"
                                    type={showRePassword ? "text" : "password"}
                                    id="re-password"
                                    onChange={() => {
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => setShowRePassword(!showRePassword)}
                                                >
                                                    {showRePassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <List sx={{listStyleType: 'disc', marginLeft: '20px'}}>
                                    <ListItem sx={{display: 'list-item'}}>
                                        Password must be at least 8 characters long.
                                    </ListItem>
                                    <ListItem sx={{display: 'list-item'}}>
                                        Password can not be all numbers.
                                    </ListItem>
                                    <ListItem sx={{display: 'list-item'}}>
                                        Password can not be too similar to your name, nickname, or email.
                                    </ListItem>
                                </List>
                            </Grid>
                            {/*<Grid item xs={12}>*/}
                            {/*    <MuiPhoneNumber*/}
                            {/*        defaultCountry={'us'}*/}
                            {/*        onlyCountries={['us']}*/}
                            {/*        fullWidth*/}
                            {/*        onChange={(value) => {*/}
                            {/*            setPhone(value as string);*/}
                            {/*        }*/}
                            {/*        }*/}
                            {/*        name="phone"*/}
                            {/*        label="Phone Number (Optional)"*/}
                            {/*        countryCodeEditable={false}*/}
                            {/*        disableDropdown={true}*/}
                            {/*    />*/}
                            {/*</Grid>*/}
                            {/*<ul>*/}
                            {/*    <li>*/}
                            {/*        By providing your phone number, you agree to receive bracket promotions and updates via SMS.*/}
                            {/*    </li>*/}
                            {/*</ul>*/}
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={<Checkbox name="allowPromotions" value="agreed" color="primary"/>}
                                    label={"I want to receive bracket promotions and updates via email."}
                                />
                                <FormControlLabel
                                    control={<Checkbox name="agreeTOS" value="agreed" color="primary"/>}
                                    label="I have both read and acknowledged both the terms of service and privacy policy."
                                />
                                <Grid container display={"flex"} flexDirection={"row"} justifyContent={"space-around"} margin={'8px'}>
                                    <Button
                                        variant={"contained"}
                                        target={"_blank"}
                                        href="/terms"
                                        sx={{
                                            backgroundColor: "#486ce7",
                                            color: "#ffffff",
                                            ":hover": {
                                                backgroundColor: "#c40000",
                                                color: "#ffffff",
                                            }
                                        }}
                                    >
                                        <GavelIcon/>
                                        <Typography variant={"body2"} sx={{
                                            textTransform: 'none',
                                        }}>
                                            Terms of Service
                                        </Typography>
                                    </Button>

                                    <Button
                                        variant={"contained"}
                                        target={"_blank"}
                                        href="/privacy"
                                        sx={{
                                            backgroundColor: "#486ce7",
                                            color: "#ffffff",
                                            ":hover": {
                                                backgroundColor: "#c40000",
                                                color: "#ffffff",
                                            }
                                        }}
                                    >
                                        <PrivacyTipIcon/>
                                        <Typography variant={"body2"} sx={{
                                            textTransform: 'none',
                                        }}>
                                            Privacy Policy
                                        </Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{
                                mt: 3,
                                mb: 2,
                                backgroundColor: "#486ce7",
                                color: "#ffffff",
                            }}
                            disabled={isClicked || !buttonEnabled}
                        >
                            <Typography variant={"h6"} fontWeight={"bold"} sx={{
                                textTransform: 'none',
                            }}>
                                Sign Up
                            </Typography>
                        </Button>
                        <Grid container justifyContent="center">
                            <Button
                                startIcon={<LoginIcon />}
                                href="/signin"
                                sx={{
                                    backgroundColor: "#486ce7",
                                    color: "#ffffff",
                                    ":hover": {
                                        backgroundColor: "#c40000",
                                        color: "#ffffff",
                                    }
                                }}
                            >
                                <Typography variant={"body2"} sx={{
                                    textTransform: 'none',
                                }}>
                                    Sign In
                                </Typography>
                            </Button>
                        </Grid>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
}