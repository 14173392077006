import {NewsPost} from "../../../data/models/sponsor_api/NewsPost";
import Grid from "@mui/material/Grid";
import NewsRow from "./NewsRow";
import Typography from "@mui/material/Typography";
import {Divider, Theme, useMediaQuery, useTheme} from "@mui/material";
import {useEffect, useState} from "react";

type NewsFeedProps = {
    news: NewsPost[];
}
export default function NewsFeed({news}: Readonly<NewsFeedProps>){
    const [isMdOrUp, setIsMdOrUp] = useState(useMediaQuery((theme: Theme) => theme.breakpoints.up('md')));

    useEffect(() => {

        const handleResize = () => {
            setIsMdOrUp(window.innerWidth >= 960);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [isMdOrUp]);

    return(
        <Grid container sx={{
            direction: "column",
            alignItems: "center",
            justifyContent: "center",
        }}>
            <Grid container sx={{
                maxHeight: isMdOrUp ? '50vh': '100vh',
                width: isMdOrUp ? '40vw' : '95vw',
                overflowY: 'auto',
                overflowX: 'hidden',
                marginY: 1,
                backgroundColor: 'background.paper',
                borderRadius: '5px',
                boxShadow: 1,
            }}>
                <Typography variant="h5" sx={{marginY: 1, p: 1}}>News Feed</Typography>
                {news.map((post) => {
                return (
                    NewsRow(post)
                )
            })}
            </Grid>
        </Grid>
    )
}