import {Result} from "../../data/models/bracket_api/Result";
import {Qualifier} from "../../data/models/bracket_api/Qualifier";
import {Bracket} from "../../data/models/bracket_api/Bracket";
import {FourWideResult} from "../../data/models/bracket_api/FourWideResult";
import {uuidv4} from "../UUIDGeneration";
import {UUID} from "crypto";

function generateStaticResults(bracket: Bracket, results: Result[]): Result[] {
    /**
     * This function generates static results for a given bracket that are the same regardless of number of competitors.
     *
     * @param {Bracket} bracket - The bracket for which to generate results.
     * @param {Result[]} results - An array of results.
     * @returns {Result[]} An array of generated results.
     */
    let returnResults: Result[] = [];
    returnResults.push({
        game_code: uuidv4() as UUID,
        bracket: bracket,
        round: 3,
        game: 1,
        competitor_1_game_code: results.find(result => result.round === 2 && result.game === 1)?.game_code,
        competitor_2_game_code: results.find(result => result.round === 2 && result.game === 2)?.game_code,
    })
    returnResults.push({
        game_code: uuidv4() as UUID,
        bracket: bracket,
        round: 3,
        game: 2,
        competitor_1_game_code: results.find(result => result.round === 2 && result.game === 3)?.game_code,
        competitor_2_game_code: results.find(result => result.round === 2 && result.game === 4)?.game_code,
    })
    returnResults.push({
        game_code: uuidv4() as UUID,
        bracket: bracket,
        round: 4,
        game: 1,
        competitor_1_game_code: returnResults[0].game_code,
        competitor_2_game_code: returnResults[1].game_code,
    })
    return returnResults;
}


function generateStaticResultsFourWide(bracket: Bracket, results: FourWideResult[]): FourWideResult[] {
    /**
     * This function generates static results for a given bracket that are the same regardless of number of competitors.
     *
     * @param {Bracket} bracket - The bracket for which to generate results.
     * @param {FourWideResult[]} results - An array of results.
     * @returns {FourWideResult[]} An array of generated results.
     */
    let returnResults: FourWideResult[] = [];
    returnResults.push({
        game_code: uuidv4() as UUID,
        bracket: bracket,
        round: 2,
        game: 1,
        competitor_1_game_code: results.find(result => result.round === 1 && result.game === 1)?.game_code,
        competitor_2_game_code: results.find(result => result.round === 1 && result.game === 1)?.game_code,
        competitor_3_game_code: results.find(result => result.round === 1 && result.game === 2)?.game_code,
        competitor_4_game_code: results.find(result => result.round === 1 && result.game === 2)?.game_code,
    })
    returnResults.push({
        game_code: uuidv4() as UUID,
        bracket: bracket,
        round: 2,
        game: 2,
        competitor_1_game_code: results.find(result => result.round === 1 && result.game === 3)?.game_code,
        competitor_2_game_code: results.find(result => result.round === 1 && result.game === 3)?.game_code,
        competitor_3_game_code: results.find(result => result.round === 1 && result.game === 4)?.game_code,
        competitor_4_game_code: results.find(result => result.round === 1 && result.game === 4)?.game_code,
    })
    returnResults.push({
        game_code: uuidv4() as UUID,
        bracket: bracket,
        round: 3,
        game: 1,
        competitor_1_game_code: returnResults[0].game_code,
        competitor_2_game_code: returnResults[0].game_code,
        competitor_3_game_code: returnResults[1].game_code,
        competitor_4_game_code: returnResults[1].game_code,
    })
    return returnResults;
}

export function generateResultsFromQualifiers(qualifiers: Qualifier[], bracket: Bracket): Result[] {
    let results: Result[] = [];
    if (bracket.number_of_competitors !== undefined) {
        if(bracket.number_of_competitors < 8){
            return [];
        }
        else if(bracket.number_of_competitors === 8){
            // Round 1 results
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 1,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 2,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 3,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 4,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 5,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 6,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 7,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 8,
            })
            // Round 2 results
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 2,
                game: 1,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 1)?.driver,
                competitor_2: qualifiers.find(qualifier => qualifier.seed === 8)?.driver,
                seed_1: 1,
                seed_2: 8,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 2,
                game: 2,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 4)?.driver,
                competitor_2: qualifiers.find(qualifier => qualifier.seed === 5)?.driver,
                seed_1: 4,
                seed_2: 5,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 2,
                game: 3,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 2)?.driver,
                competitor_2: qualifiers.find(qualifier => qualifier.seed === 7)?.driver,
                seed_1: 2,
                seed_2: 7,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 2,
                game: 4,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 3)?.driver,
                competitor_2: qualifiers.find(qualifier => qualifier.seed === 6)?.driver,
                seed_1: 3,
                seed_2: 6,
            })
        }
        else if(bracket.number_of_competitors === 9){
            // Round 1 results
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 1,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 1)?.driver,
                seed_1: 1,
                winner: qualifiers.find(qualifier => qualifier.seed === 1)?.driver,
                winner_seed: 1,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 2,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 5)?.driver,
                competitor_2: qualifiers.find(qualifier => qualifier.seed === 6)?.driver,
                seed_1: 5,
                seed_2: 6,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 3,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 2)?.driver,
                competitor_2: qualifiers.find(qualifier => qualifier.seed === 9)?.driver,
                seed_1: 2,
                seed_2: 9,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 4,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 5,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 3)?.driver,
                competitor_2: qualifiers.find(qualifier => qualifier.seed === 8)?.driver,
                seed_1: 3,
                seed_2: 8,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 6,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 4)?.driver,
                competitor_2: qualifiers.find(qualifier => qualifier.seed === 7)?.driver,
                seed_1: 4,
                seed_2: 7,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 7,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 8,
            })
            // Round 2 results
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 2,
                game: 1,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 1)?.driver,
                seed_1: 1,
                competitor_1_game_code: results.find(result => result.round === 1 && result.game === 1)?.game_code,
                competitor_2_game_code: results.find(result => result.round === 1 && result.game === 2)?.game_code,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 2,
                game: 2,
                competitor_1_game_code: results.find(result => result.round === 1 && result.game === 3)?.game_code,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 2,
                game: 3,
                competitor_1_game_code: results.find(result => result.round === 1 && result.game === 5)?.game_code,
                competitor_2_game_code: results.find(result => result.round === 1 && result.game === 6)?.game_code,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 2,
                game: 4,
            })
            // Round 3 results
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 3,
                game: 1,
                competitor_1_game_code: results.find(result => result.round === 2 && result.game === 1)?.game_code,
                competitor_2_game_code: results.find(result => result.round === 2 && result.game === 2)?.game_code,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 3,
                game: 2,
                competitor_1_game_code: results.find(result => result.round === 2 && result.game === 3)?.game_code,
            })
            // Round 4 results
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 4,
                game: 1,
                competitor_1_game_code: results.find(result => result.round === 3 && result.game === 1)?.game_code,
                competitor_2_game_code: results.find(result => result.round === 3 && result.game === 2)?.game_code,
            })
            return results;
        }
        else if(bracket.number_of_competitors === 10){
            // Round 1 results
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 1,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 1)?.driver,
                competitor_2: qualifiers.find(qualifier => qualifier.seed === 10)?.driver,
                seed_1: 1,
                seed_2: 10,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 2,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 3,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 3)?.driver,
                competitor_2: qualifiers.find(qualifier => qualifier.seed === 8)?.driver,
                seed_1: 3,
                seed_2: 8,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 4,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 4)?.driver,
                competitor_2: qualifiers.find(qualifier => qualifier.seed === 7)?.driver,
                seed_1: 4,
                seed_2: 7,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 5,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 2)?.driver,
                competitor_2: qualifiers.find(qualifier => qualifier.seed === 9)?.driver,
                seed_1: 2,
                seed_2: 9,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 6,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 5)?.driver,
                competitor_2: qualifiers.find(qualifier => qualifier.seed === 6)?.driver,
                seed_1: 5,
                seed_2: 6,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 7,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 8,
            })
            // Round 2 results
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 2,
                game: 1,
                competitor_1_game_code: results.find(result => result.round === 1 && result.game === 1)?.game_code,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 2,
                game: 2,
                competitor_1_game_code: results.find(result => result.round === 1 && result.game === 3)?.game_code,
                competitor_2_game_code: results.find(result => result.round === 1 && result.game === 4)?.game_code,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 2,
                game: 3,
                competitor_1_game_code: results.find(result => result.round === 1 && result.game === 5)?.game_code,
                competitor_2_game_code: results.find(result => result.round === 1 && result.game === 6)?.game_code,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 2,
                game: 4,
            })
            // Round 3 results
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 3,
                game: 1,
                competitor_1_game_code: results.find(result => result.round === 2 && result.game === 1)?.game_code,
                competitor_2_game_code: results.find(result => result.round === 2 && result.game === 2)?.game_code,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 3,
                game: 2,
                competitor_1_game_code: results.find(result => result.round === 2 && result.game === 3)?.game_code,
            })
            // Round 4 results
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 4,
                game: 1,
                competitor_1_game_code: results.find(result => result.round === 3 && result.game === 1)?.game_code,
                competitor_2_game_code: results.find(result => result.round === 3 && result.game === 2)?.game_code,
            })
            return results;
        }
        else if (bracket.number_of_competitors === 11) {
            // Round 1 results
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 1,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 1)?.driver,
                seed_1: 1,
                winner: qualifiers.find(qualifier => qualifier.seed === 1)?.driver,
                winner_seed: 1,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 2,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 6)?.driver,
                competitor_2: qualifiers.find(qualifier => qualifier.seed === 7)?.driver,
                seed_1: 6,
                seed_2: 7,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 3,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 3)?.driver,
                competitor_2: qualifiers.find(qualifier => qualifier.seed === 10)?.driver,
                seed_1: 3,
                seed_2: 10,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 4,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 4)?.driver,
                competitor_2: qualifiers.find(qualifier => qualifier.seed === 9)?.driver,
                seed_1: 4,
                seed_2: 9,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 5,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 2)?.driver,
                competitor_2: qualifiers.find(qualifier => qualifier.seed === 11)?.driver,
                seed_1: 2,
                seed_2: 11,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 6,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 5)?.driver,
                competitor_2: qualifiers.find(qualifier => qualifier.seed === 8)?.driver,
                seed_1: 5,
                seed_2: 8,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 7,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 8,
            })
            // Round 2 results
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 2,
                game: 1,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 1)?.driver,
                seed_1: 1,
                competitor_1_game_code: results.find(result => result.round === 1 && result.game === 1)?.game_code,
                competitor_2_game_code: results.find(result => result.round === 1 && result.game === 2)?.game_code,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 2,
                game: 2,
                competitor_1_game_code: results.find(result => result.round === 1 && result.game === 3)?.game_code,
                competitor_2_game_code: results.find(result => result.round === 1 && result.game === 4)?.game_code,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 2,
                game: 3,
                competitor_1_game_code: results.find(result => result.round === 1 && result.game === 5)?.game_code,
                competitor_2_game_code: results.find(result => result.round === 1 && result.game === 6)?.game_code,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 2,
                game: 4,
            })
            // Round 3 results
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 3,
                game: 1,
                competitor_1_game_code: results.find(result => result.round === 2 && result.game === 1)?.game_code,
                competitor_2_game_code: results.find(result => result.round === 2 && result.game === 2)?.game_code,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 3,
                game: 2,
                competitor_1_game_code: results.find(result => result.round === 2 && result.game === 3)?.game_code,
            })
            // Round 4 results
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 4,
                game: 1,
                competitor_1_game_code: results.find(result => result.round === 3 && result.game === 1)?.game_code,
                competitor_2_game_code: results.find(result => result.round === 3 && result.game === 2)?.game_code,
            })
            return results;
        }
        else if (bracket.number_of_competitors === 12) {
            // Round 1 results
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 1,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 1)?.driver,
                competitor_2: qualifiers.find(qualifier => qualifier.seed === 12)?.driver,
                seed_1: 1,
                seed_2: 12,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 2,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 6)?.driver,
                competitor_2: qualifiers.find(qualifier => qualifier.seed === 7)?.driver,
                seed_1: 6,
                seed_2: 7,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 3,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 4,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 5,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 2)?.driver,
                competitor_2: qualifiers.find(qualifier => qualifier.seed === 11)?.driver,
                seed_1: 2,
                seed_2: 11,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 6,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 5)?.driver,
                competitor_2: qualifiers.find(qualifier => qualifier.seed === 8)?.driver,
                seed_1: 5,
                seed_2: 8,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 7,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 3)?.driver,
                competitor_2: qualifiers.find(qualifier => qualifier.seed === 10)?.driver,
                seed_1: 3,
                seed_2: 10,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 8,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 4)?.driver,
                competitor_2: qualifiers.find(qualifier => qualifier.seed === 9)?.driver,
                seed_1: 4,
                seed_2: 9,
            })
            // Round 2 results
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 2,
                game: 1,
                competitor_1_game_code: results.find(result => result.round === 1 && result.game === 1)?.game_code,
                competitor_2_game_code: results.find(result => result.round === 1 && result.game === 2)?.game_code,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 2,
                game: 2,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 2,
                game: 3,
                competitor_1_game_code: results.find(result => result.round === 1 && result.game === 5)?.game_code,
                competitor_2_game_code: results.find(result => result.round === 1 && result.game === 6)?.game_code,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 2,
                game: 4,
                competitor_1_game_code: results.find(result => result.round === 1 && result.game === 7)?.game_code,
                competitor_2_game_code: results.find(result => result.round === 1 && result.game === 8)?.game_code,
            })
            // Round 3 results
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 3,
                game: 1,
                competitor_1_game_code: results.find(result => result.round === 2 && result.game === 1)?.game_code,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 3,
                game: 2,
                competitor_1_game_code: results.find(result => result.round === 2 && result.game === 3)?.game_code,
                competitor_2_game_code: results.find(result => result.round === 2 && result.game === 4)?.game_code,
            })
            // Round 4 results
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 4,
                game: 1,
                competitor_1_game_code: results.find(result => result.round === 3 && result.game === 1)?.game_code,
                competitor_2_game_code: results.find(result => result.round === 3 && result.game === 2)?.game_code,
            })
            return results;
        }
        else if (bracket.number_of_competitors === 13) {
            // Round 1 results
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 1,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 1)?.driver,
                seed_1: 1,
                winner: qualifiers.find(qualifier => qualifier.seed === 1)?.driver,
                winner_seed: 1,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 2,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 7)?.driver,
                competitor_2: qualifiers.find(qualifier => qualifier.seed === 8)?.driver,
                seed_1: 7,
                seed_2: 8,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 3,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 4)?.driver,
                competitor_2: qualifiers.find(qualifier => qualifier.seed === 11)?.driver,
                seed_1: 4,
                seed_2: 11,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 4,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 5)?.driver,
                competitor_2: qualifiers.find(qualifier => qualifier.seed === 10)?.driver,
                seed_1: 5,
                seed_2: 10,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 5,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 2)?.driver,
                competitor_2: qualifiers.find(qualifier => qualifier.seed === 13)?.driver,
                seed_1: 2,
                seed_2: 13,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 6,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 7,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 3)?.driver,
                competitor_2: qualifiers.find(qualifier => qualifier.seed === 12)?.driver,
                seed_1: 3,
                seed_2: 12,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 8,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 6)?.driver,
                competitor_2: qualifiers.find(qualifier => qualifier.seed === 9)?.driver,
                seed_1: 6,
                seed_2: 9,
            })
            // Round 2 results
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 2,
                game: 1,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 1)?.driver,
                competitor_1_game_code: results.find(result => result.round === 1 && result.game === 1)?.game_code,
                competitor_2_game_code: results.find(result => result.round === 1 && result.game === 2)?.game_code,
                seed_1: 1,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 2,
                game: 2,
                competitor_1_game_code: results.find(result => result.round === 1 && result.game === 3)?.game_code,
                competitor_2_game_code: results.find(result => result.round === 1 && result.game === 4)?.game_code,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 2,
                game: 3,
                competitor_1_game_code: results.find(result => result.round === 1 && result.game === 5)?.game_code,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 2,
                game: 4,
                competitor_1_game_code: results.find(result => result.round === 1 && result.game === 7)?.game_code,
                competitor_2_game_code: results.find(result => result.round === 1 && result.game === 8)?.game_code,
            })
        }
        else if (bracket.number_of_competitors === 14) {
            // Round 1 results
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 1,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 1)?.driver,
                competitor_2: qualifiers.find(qualifier => qualifier.seed === 14)?.driver,
                seed_1: 1,
                seed_2: 14,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 2,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 3,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 4)?.driver,
                competitor_2: qualifiers.find(qualifier => qualifier.seed === 11)?.driver,
                seed_1: 4,
                seed_2: 11,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 4,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 5)?.driver,
                competitor_2: qualifiers.find(qualifier => qualifier.seed === 10)?.driver,
                seed_1: 5,
                seed_2: 10,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 5,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 2)?.driver,
                competitor_2: qualifiers.find(qualifier => qualifier.seed === 13)?.driver,
                seed_1: 2,
                seed_2: 13,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 6,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 7)?.driver,
                competitor_2: qualifiers.find(qualifier => qualifier.seed === 8)?.driver,
                seed_1: 7,
                seed_2: 8,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 7,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 3)?.driver,
                competitor_2: qualifiers.find(qualifier => qualifier.seed === 12)?.driver,
                seed_1: 3,
                seed_2: 12,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 8,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 6)?.driver,
                competitor_2: qualifiers.find(qualifier => qualifier.seed === 9)?.driver,
                seed_1: 6,
                seed_2: 9,
            })
            // Round 2 results
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 2,
                game: 1,
                competitor_1_game_code: results.find(result => result.round === 1 && result.game === 1)?.game_code,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 2,
                game: 2,
                competitor_1_game_code: results.find(result => result.round === 1 && result.game === 3)?.game_code,
                competitor_2_game_code: results.find(result => result.round === 1 && result.game === 4)?.game_code,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 2,
                game: 3,
                competitor_1_game_code: results.find(result => result.round === 1 && result.game === 5)?.game_code,
                competitor_2_game_code: results.find(result => result.round === 1 && result.game === 6)?.game_code,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 2,
                game: 4,
                competitor_1_game_code: results.find(result => result.round === 1 && result.game === 7)?.game_code,
                competitor_2_game_code: results.find(result => result.round === 1 && result.game === 8)?.game_code,
            })
        }
        else if (bracket.number_of_competitors === 15) {
            // Round 1 results
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 1,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 1)?.driver,
                seed_1: 1,
                winner: qualifiers.find(qualifier => qualifier.seed === 1)?.driver,
                winner_seed: 1,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 2,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 8)?.driver,
                competitor_2: qualifiers.find(qualifier => qualifier.seed === 9)?.driver,
                seed_1: 8,
                seed_2: 9,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 3,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 4)?.driver,
                competitor_2: qualifiers.find(qualifier => qualifier.seed === 13)?.driver,
                seed_1: 4,
                seed_2: 13,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 4,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 5)?.driver,
                competitor_2: qualifiers.find(qualifier => qualifier.seed === 12)?.driver,
                seed_1: 5,
                seed_2: 12,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 5,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 2)?.driver,
                competitor_2: qualifiers.find(qualifier => qualifier.seed === 15)?.driver,
                seed_1: 2,
                seed_2: 15,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 6,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 7)?.driver,
                competitor_2: qualifiers.find(qualifier => qualifier.seed === 10)?.driver,
                seed_1: 7,
                seed_2: 10,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 7,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 3)?.driver,
                competitor_2: qualifiers.find(qualifier => qualifier.seed === 14)?.driver,
                seed_1: 3,
                seed_2: 14,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 8,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 6)?.driver,
                competitor_2: qualifiers.find(qualifier => qualifier.seed === 11)?.driver,
                seed_1: 6,
                seed_2: 11,
            })
            // Round 2 results
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 2,
                game: 1,
                seed_1: 1,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 1)?.driver,
                competitor_1_game_code: results.find(result => result.round === 1 && result.game === 1)?.game_code,
                competitor_2_game_code: results.find(result => result.round === 1 && result.game === 2)?.game_code,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 2,
                game: 2,
                competitor_1_game_code: results.find(result => result.round === 1 && result.game === 3)?.game_code,
                competitor_2_game_code: results.find(result => result.round === 1 && result.game === 4)?.game_code,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 2,
                game: 3,
                competitor_1_game_code: results.find(result => result.round === 1 && result.game === 5)?.game_code,
                competitor_2_game_code: results.find(result => result.round === 1 && result.game === 6)?.game_code,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 2,
                game: 4,
                competitor_1_game_code: results.find(result => result.round === 1 && result.game === 7)?.game_code,
                competitor_2_game_code: results.find(result => result.round === 1 && result.game === 8)?.game_code,
            })
        }
        else if (bracket.number_of_competitors >= 16) {
            // Round 1 results
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 1,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 1)?.driver,
                competitor_2: qualifiers.find(qualifier => qualifier.seed === 16)?.driver,
                seed_1: 1,
                seed_2: 16,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 2,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 8)?.driver,
                competitor_2: qualifiers.find(qualifier => qualifier.seed === 9)?.driver,
                seed_1: 8,
                seed_2: 9,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 3,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 4)?.driver,
                competitor_2: qualifiers.find(qualifier => qualifier.seed === 13)?.driver,
                seed_1: 4,
                seed_2: 13,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 4,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 5)?.driver,
                competitor_2: qualifiers.find(qualifier => qualifier.seed === 12)?.driver,
                seed_1: 5,
                seed_2: 12,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 5,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 2)?.driver,
                competitor_2: qualifiers.find(qualifier => qualifier.seed === 15)?.driver,
                seed_1: 2,
                seed_2: 15,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 6,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 7)?.driver,
                competitor_2: qualifiers.find(qualifier => qualifier.seed === 10)?.driver,
                seed_1: 7,
                seed_2: 10,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 7,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 3)?.driver,
                competitor_2: qualifiers.find(qualifier => qualifier.seed === 14)?.driver,
                seed_1: 3,
                seed_2: 14,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 8,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 6)?.driver,
                competitor_2: qualifiers.find(qualifier => qualifier.seed === 11)?.driver,
                seed_1: 6,
                seed_2: 11,
            })
            // Round 2 results
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 2,
                game: 1,
                competitor_1_game_code: results.find(result => result.round === 1 && result.game === 1)?.game_code,
                competitor_2_game_code: results.find(result => result.round === 1 && result.game === 2)?.game_code,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 2,
                game: 2,
                competitor_1_game_code: results.find(result => result.round === 1 && result.game === 3)?.game_code,
                competitor_2_game_code: results.find(result => result.round === 1 && result.game === 4)?.game_code,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 2,
                game: 3,
                competitor_1_game_code: results.find(result => result.round === 1 && result.game === 5)?.game_code,
                competitor_2_game_code: results.find(result => result.round === 1 && result.game === 6)?.game_code,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 2,
                game: 4,
                competitor_1_game_code: results.find(result => result.round === 1 && result.game === 7)?.game_code,
                competitor_2_game_code: results.find(result => result.round === 1 && result.game === 8)?.game_code,
            })
        }
        results = results.concat(generateStaticResults(bracket, results));
        return results;
    }
    else{
        return [];
    }
}

export function generateResultsFromQualifiersFourWide(qualifiers: Qualifier[], bracket: Bracket): FourWideResult[] {
    let results: FourWideResult[] = [];
    if (bracket.number_of_competitors !== undefined) {
        if(bracket.number_of_competitors < 9){
            return [];
        }
        if (bracket.number_of_competitors === 9) {
            // Round 1 results
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 1,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 1)?.driver,
                seed_1: 1,
                first: qualifiers.find(qualifier => qualifier.seed === 1)?.driver,
                first_seed: 1,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 2,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 4)?.driver,
                competitor_2: qualifiers.find(qualifier => qualifier.seed === 9)?.driver,
                competitor_3: qualifiers.find(qualifier => qualifier.seed === 5)?.driver,
                competitor_4: qualifiers.find(qualifier => qualifier.seed === 8)?.driver,
                seed_1: 4,
                seed_2: 9,
                seed_3: 5,
                seed_4: 8,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 3,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 2)?.driver,
                seed_1: 2,
                first: qualifiers.find(qualifier => qualifier.seed === 2)?.driver,
                first_seed: 2,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 4,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 3)?.driver,
                competitor_3: qualifiers.find(qualifier => qualifier.seed === 6)?.driver,
                competitor_4: qualifiers.find(qualifier => qualifier.seed === 7)?.driver,
                seed_1: 3,
                seed_3: 6,
                seed_4: 7,
            })
        }
        else if(bracket.number_of_competitors === 10){
            // Round 1 results
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 1,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 1)?.driver,
                seed_1: 1,
                first: qualifiers.find(qualifier => qualifier.seed === 1)?.driver,
                first_seed: 1,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 2,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 4)?.driver,
                competitor_3: qualifiers.find(qualifier => qualifier.seed === 5)?.driver,
                competitor_4: qualifiers.find(qualifier => qualifier.seed === 10)?.driver,
                seed_1: 4,
                seed_3: 5,
                seed_4: 10,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 3,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 2)?.driver,
                competitor_3: qualifiers.find(qualifier => qualifier.seed === 7)?.driver,
                competitor_4: qualifiers.find(qualifier => qualifier.seed === 8)?.driver,
                seed_1: 2,
                seed_3: 7,
                seed_4: 8,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 4,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 3)?.driver,
                competitor_3: qualifiers.find(qualifier => qualifier.seed === 6)?.driver,
                competitor_4: qualifiers.find(qualifier => qualifier.seed === 9)?.driver,
                seed_1: 3,
                seed_3: 6,
                seed_4: 9,
            })
        }
        else if(bracket.number_of_competitors === 11){
            // Round 1 results
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 1,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 1)?.driver,
                seed_1: 1,
                first: qualifiers.find(qualifier => qualifier.seed === 1)?.driver,
                first_seed: 1,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 2,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 4)?.driver,
                competitor_2: qualifiers.find(qualifier => qualifier.seed === 9)?.driver,
                competitor_3: qualifiers.find(qualifier => qualifier.seed === 5)?.driver,
                competitor_4: qualifiers.find(qualifier => qualifier.seed === 8)?.driver,
                seed_1: 4,
                seed_2: 9,
                seed_3: 5,
                seed_4: 8,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 3,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 2)?.driver,
                competitor_3: qualifiers.find(qualifier => qualifier.seed === 7)?.driver,
                competitor_4: qualifiers.find(qualifier => qualifier.seed === 11)?.driver,
                seed_1: 2,
                seed_3: 7,
                seed_4: 11,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 4,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 3)?.driver,
                competitor_3: qualifiers.find(qualifier => qualifier.seed === 6)?.driver,
                competitor_4: qualifiers.find(qualifier => qualifier.seed === 10)?.driver,
                seed_1: 3,
                seed_3: 6,
                seed_4: 10,
            })
        }
        else if(bracket.number_of_competitors === 12){
            // Round 1 results
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 1,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 1)?.driver,
                competitor_3: qualifiers.find(qualifier => qualifier.seed === 8)?.driver,
                competitor_4: qualifiers.find(qualifier => qualifier.seed === 9)?.driver,
                seed_1: 1,
                seed_3: 8,
                seed_4: 9,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 2,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 4)?.driver,
                competitor_3: qualifiers.find(qualifier => qualifier.seed === 5)?.driver,
                competitor_4: qualifiers.find(qualifier => qualifier.seed === 12)?.driver,
                seed_1: 4,
                seed_3: 5,
                seed_4: 12,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 3,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 2)?.driver,
                competitor_3: qualifiers.find(qualifier => qualifier.seed === 7)?.driver,
                competitor_4: qualifiers.find(qualifier => qualifier.seed === 10)?.driver,
                seed_1: 2,
                seed_3: 7,
                seed_4: 10,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 4,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 3)?.driver,
                competitor_3: qualifiers.find(qualifier => qualifier.seed === 6)?.driver,
                competitor_4: qualifiers.find(qualifier => qualifier.seed === 11)?.driver,
                seed_1: 3,
                seed_3: 6,
                seed_4: 11,
            })
        }
        else if(bracket.number_of_competitors === 13){
            // Round 1 results
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 1,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 1)?.driver,
                competitor_3: qualifiers.find(qualifier => qualifier.seed === 8)?.driver,
                competitor_4: qualifiers.find(qualifier => qualifier.seed === 9)?.driver,
                seed_1: 1,
                seed_3: 8,
                seed_4: 9,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 2,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 4)?.driver,
                competitor_2: qualifiers.find(qualifier => qualifier.seed === 13)?.driver,
                competitor_3: qualifiers.find(qualifier => qualifier.seed === 5)?.driver,
                competitor_4: qualifiers.find(qualifier => qualifier.seed === 12)?.driver,
                seed_1: 4,
                seed_2: 13,
                seed_3: 5,
                seed_4: 12,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 3,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 2)?.driver,
                competitor_3: qualifiers.find(qualifier => qualifier.seed === 7)?.driver,
                competitor_4: qualifiers.find(qualifier => qualifier.seed === 10)?.driver,
                seed_1: 2,
                seed_3: 7,
                seed_4: 10,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 4,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 3)?.driver,
                competitor_3: qualifiers.find(qualifier => qualifier.seed === 6)?.driver,
                competitor_4: qualifiers.find(qualifier => qualifier.seed === 11)?.driver,
                seed_1: 3,
                seed_3: 6,
                seed_4: 11,
            })
        }
        else if(bracket.number_of_competitors === 14){
            // Round 1 results
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 1,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 1)?.driver,
                competitor_3: qualifiers.find(qualifier => qualifier.seed === 8)?.driver,
                competitor_4: qualifiers.find(qualifier => qualifier.seed === 9)?.driver,
                seed_1: 1,
                seed_3: 8,
                seed_4: 9,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 2,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 4)?.driver,
                competitor_2: qualifiers.find(qualifier => qualifier.seed === 13)?.driver,
                competitor_3: qualifiers.find(qualifier => qualifier.seed === 5)?.driver,
                competitor_4: qualifiers.find(qualifier => qualifier.seed === 12)?.driver,
                seed_1: 4,
                seed_2: 13,
                seed_3: 5,
                seed_4: 12,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 3,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 2)?.driver,
                competitor_3: qualifiers.find(qualifier => qualifier.seed === 7)?.driver,
                competitor_4: qualifiers.find(qualifier => qualifier.seed === 10)?.driver,
                seed_1: 2,
                seed_3: 7,
                seed_4: 10,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 4,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 3)?.driver,
                competitor_2: qualifiers.find(qualifier => qualifier.seed === 14)?.driver,
                competitor_3: qualifiers.find(qualifier => qualifier.seed === 6)?.driver,
                competitor_4: qualifiers.find(qualifier => qualifier.seed === 11)?.driver,
                seed_1: 3,
                seed_2: 14,
                seed_3: 6,
                seed_4: 11,
            })
        }
        else if(bracket.number_of_competitors === 15){
            // Round 1 results
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 1,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 1)?.driver,
                competitor_3: qualifiers.find(qualifier => qualifier.seed === 8)?.driver,
                competitor_4: qualifiers.find(qualifier => qualifier.seed === 9)?.driver,
                seed_1: 1,
                seed_3: 8,
                seed_4: 9,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 2,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 4)?.driver,
                competitor_2: qualifiers.find(qualifier => qualifier.seed === 13)?.driver,
                competitor_3: qualifiers.find(qualifier => qualifier.seed === 5)?.driver,
                competitor_4: qualifiers.find(qualifier => qualifier.seed === 12)?.driver,
                seed_1: 4,
                seed_2: 13,
                seed_3: 5,
                seed_4: 12,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 3,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 2)?.driver,
                competitor_2: qualifiers.find(qualifier => qualifier.seed === 15)?.driver,
                competitor_3: qualifiers.find(qualifier => qualifier.seed === 7)?.driver,
                competitor_4: qualifiers.find(qualifier => qualifier.seed === 10)?.driver,
                seed_1: 2,
                seed_2: 15,
                seed_3: 7,
                seed_4: 10,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 4,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 3)?.driver,
                competitor_2: qualifiers.find(qualifier => qualifier.seed === 14)?.driver,
                competitor_3: qualifiers.find(qualifier => qualifier.seed === 6)?.driver,
                competitor_4: qualifiers.find(qualifier => qualifier.seed === 11)?.driver,
                seed_1: 3,
                seed_2: 14,
                seed_3: 6,
                seed_4: 11,
            })
        }
        else if (bracket.number_of_competitors >= 16) {
            // Round 1 results
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 1,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 1)?.driver,
                competitor_2: qualifiers.find(qualifier => qualifier.seed === 16)?.driver,
                competitor_3: qualifiers.find(qualifier => qualifier.seed === 8)?.driver,
                competitor_4: qualifiers.find(qualifier => qualifier.seed === 9)?.driver,
                seed_1: 1,
                seed_2: 16,
                seed_3: 8,
                seed_4: 9,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 2,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 4)?.driver,
                competitor_2: qualifiers.find(qualifier => qualifier.seed === 13)?.driver,
                competitor_3: qualifiers.find(qualifier => qualifier.seed === 5)?.driver,
                competitor_4: qualifiers.find(qualifier => qualifier.seed === 12)?.driver,
                seed_1: 4,
                seed_2: 13,
                seed_3: 5,
                seed_4: 12,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 3,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 2)?.driver,
                competitor_2: qualifiers.find(qualifier => qualifier.seed === 15)?.driver,
                competitor_3: qualifiers.find(qualifier => qualifier.seed === 7)?.driver,
                competitor_4: qualifiers.find(qualifier => qualifier.seed === 10)?.driver,
                seed_1: 2,
                seed_2: 15,
                seed_3: 7,
                seed_4: 10,
            })
            results.push({
                game_code: uuidv4() as UUID,
                bracket: bracket,
                round: 1,
                game: 4,
                competitor_1: qualifiers.find(qualifier => qualifier.seed === 3)?.driver,
                competitor_2: qualifiers.find(qualifier => qualifier.seed === 14)?.driver,
                competitor_3: qualifiers.find(qualifier => qualifier.seed === 6)?.driver,
                competitor_4: qualifiers.find(qualifier => qualifier.seed === 11)?.driver,
                seed_1: 3,
                seed_2: 14,
                seed_3: 6,
                seed_4: 11,
            })
        }
        results = results.concat(generateStaticResultsFourWide(bracket, results));
        return results;
    } else {
        return [];
    }
}
