import {Bracket} from "../../../../data/models/bracket_api/Bracket";
import {FourWideResult} from "../../../../data/models/bracket_api/FourWideResult";
import {FourWidePick} from "../../../../data/models/bracket_api/FourWidePick";
import {RaceClass} from "../../../../data/models/bracket_api/RaceClass";
import Grid from "@mui/material/Grid";
import PrintBlockFourWide from "./PrintBlockFourWide";
import BracketConnector from "../PrintBracketConnector";
import TopFuel from "../../../../assets/images/bracket_crests/top_fuel.png";
import {Driver} from "../../../../data/models/bracket_api/Driver";
import ProStock from "../../../../assets/images/bracket_crests/pro_stock.png";
import FunnyCar from "../../../../assets/images/bracket_crests/funny_car.png";
import ProStockMotorcycle from "../../../../assets/images/bracket_crests/pro_stock_motorcycle.png";
import PrintBlockReverse from "../two_wide/PrintBlockReverse";
import {PickStatus} from "../../../../data/models/bracket_api/PickStatus";
import Typography from "@mui/material/Typography";
import BracketConnectorReverse from "../PrintBracketConnectorReverse";

export type FourWidePickBracketProps = {
    race_class: RaceClass,
    bracket?: Bracket,
    results?: FourWideResult[],
    picks?: FourWidePick[],
    updatePick: (pick: FourWidePick) => void,
    picks_locked?: boolean
}

export default function PrintBracketFourWide({race_class, bracket, results, picks, updatePick, picks_locked}: Readonly<FourWidePickBracketProps>) {
    let winner: Driver | undefined = picks?.find(pick => {
        return pick.result.round === 3 && pick.first
    })?.first;

    const determineCrestImage = () => {
        switch (race_class) {
            case RaceClass.TOP_FUEL:
                return TopFuel;
            case RaceClass.PRO_STOCK:
                return ProStock;
            case RaceClass.FUNNY_CAR:
                return FunnyCar;
            case RaceClass.PRO_STOCK_MOTORCYCLE:
                return ProStockMotorcycle;
            default:
                return TopFuel;
        }
    }

    const crestImage = determineCrestImage();
    return (
        race_class === RaceClass.TOP_FUEL || race_class === RaceClass.PRO_STOCK ?
            <Grid container sx={{
                display: 'flex',
                flexDirection: "row",
                position: "relative",
                minWidth: 'fit-content',
                flexWrap: "nowrap",
            }}>
                <Grid container sx={{
                    flex: .3,
                    display: 'flex',
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    height: '100%',
                }}>
                    <PrintBlockFourWide
                        pick={picks?.find((pick) => {
                            return pick.result.round === 1 && pick.result.game === 1
                        })}
                        result={results?.find((result) => {
                            return result.round === 1 && result.game === 1
                        })}
                        updatePick={updatePick}
                        picks_locked={picks_locked}
                    />
                    <PrintBlockFourWide
                        pick={picks?.find((pick) => {
                            return pick.result.round === 1 && pick.result.game === 2
                        })}
                        result={results?.find((result) => {
                            return result.round === 1 && result.game === 2
                        })}
                        updatePick={updatePick}
                        picks_locked={picks_locked}
                    />
                    <PrintBlockFourWide
                        pick={picks?.find((pick) => {
                            return pick.result.round === 1 && pick.result.game === 3
                        })}
                        result={results?.find((result) => {
                            return result.round === 1 && result.game === 3
                        })}
                        updatePick={updatePick}
                        picks_locked={picks_locked}
                    />
                    <PrintBlockFourWide
                        pick={picks?.find((pick) => {
                            return pick.result.round === 1 && pick.result.game === 4
                        })}
                        result={results?.find((result) => {
                            return result.round === 1 && result.game === 4
                        })}
                        updatePick={updatePick}
                        picks_locked={picks_locked}
                    />
                </Grid>
                <Grid container sx={{
                    flex: .05,
                    display: 'flex',
                    flexDirection: "column",
                    justifyContent: "space-around",
                    alignItems: "center",
                    height: '100%',
                }}>
                    <BracketConnector height={"25.5%"}/>
                    <BracketConnector height={"25.5%"}/>
                </Grid>
                <Grid container sx={{
                    flex: .3,
                    display: 'flex',
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: '100%',
                }}>
                    <PrintBlockFourWide
                        pick={picks?.find((pick) => {
                            return pick.result.round === 2 && pick.result.game === 1
                        })}
                        result={results?.find((result) => {
                            return result.round === 2 && result.game === 1
                        })}
                        updatePick={updatePick}
                        picks_locked={picks_locked}
                    />
                    <img src={crestImage} alt={"Crest"} height={'100px'}/>
                    <PrintBlockFourWide
                        pick={picks?.find((pick) => {
                            return pick.result.round === 2 && pick.result.game === 2
                        })}
                        result={results?.find((result) => {
                            return result.round === 2 && result.game === 2
                        })}
                        updatePick={updatePick}
                        picks_locked={picks_locked}
                    />
                </Grid>
                <Grid container sx={{
                    flex: .05,
                    display: 'flex',
                    flexDirection: "column",
                    justifyContent: "space-around",
                    alignItems: "center",
                    height: '100%',
                }}>
                    <BracketConnector height={"51%"}/>
                </Grid>
                <Grid container sx={{
                    flex: .3,
                    display: 'flex',
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    height: '100%',
                    position: "relative",
                }}>
                    <PrintBlockFourWide
                        pick={picks?.find((pick) => {
                            return pick.result.round === 3 && pick.result.game === 1 && pick.pick_status !== PickStatus.TIEBREAKER
                        })}
                        result={results?.find((result) => {
                            return result.round === 3 && result.game === 1
                        })}
                        updatePick={updatePick}
                        picks_locked={picks_locked}
                    />
                </Grid>
            </Grid>
            :
            <Grid container sx={{
                display: 'flex',
                flexDirection: "row",
                position: "relative",
                minWidth: 'fit-content',
                flexWrap: "nowrap",
            }}>
                <Grid container sx={{
                    flex: .3,
                    display: 'flex',
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    height: '100%',
                    position: "relative",
                }}>
                    <PrintBlockFourWide
                        pick={picks?.find((pick) => {
                            return pick.result.round === 3 && pick.result.game === 1 && pick.pick_status !== PickStatus.TIEBREAKER
                        })}
                        result={results?.find((result) => {
                            return result.round === 3 && result.game === 1
                        })}
                        updatePick={updatePick}
                        picks_locked={picks_locked}
                    />
                </Grid>
                <Grid container sx={{
                    flex: .05,
                    display: 'flex',
                    flexDirection: "column",
                    justifyContent: "space-around",
                    alignItems: "center",
                    height: '100%',
                }}>
                    <BracketConnectorReverse height={"51%"}/>
                </Grid>
                <Grid container sx={{
                    flex: .3,
                    display: 'flex',
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: '100%',
                }}>
                    <PrintBlockFourWide
                        pick={picks?.find((pick) => {
                            return pick.result.round === 2 && pick.result.game === 1
                        })}
                        result={results?.find((result) => {
                            return result.round === 2 && result.game === 1
                        })}
                        updatePick={updatePick}
                        picks_locked={picks_locked}
                    />
                    <img src={crestImage} alt={"Crest"} height={'100px'}/>
                    <PrintBlockFourWide
                        pick={picks?.find((pick) => {
                            return pick.result.round === 2 && pick.result.game === 2
                        })}
                        result={results?.find((result) => {
                            return result.round === 2 && result.game === 2
                        })}
                        updatePick={updatePick}
                        picks_locked={picks_locked}
                    />
                </Grid>
                <Grid container sx={{
                    flex: .05,
                    display: 'flex',
                    flexDirection: "column",
                    justifyContent: "space-around",
                    alignItems: "center",
                    height: '100%',
                }}>
                    <BracketConnectorReverse height={"25.5%"}/>
                    <BracketConnectorReverse height={"25.5%"}/>
                </Grid>
                <Grid container sx={{
                    flex: .3,
                    display: 'flex',
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    height: '100%',
                }}>
                    <PrintBlockFourWide
                        pick={picks?.find((pick) => {
                            return pick.result.round === 1 && pick.result.game === 1
                        })}
                        result={results?.find((result) => {
                            return result.round === 1 && result.game === 1
                        })}
                        updatePick={updatePick}
                        picks_locked={picks_locked}
                    />
                    <PrintBlockFourWide
                        pick={picks?.find((pick) => {
                            return pick.result.round === 1 && pick.result.game === 2
                        })}
                        result={results?.find((result) => {
                            return result.round === 1 && result.game === 2
                        })}
                        updatePick={updatePick}
                        picks_locked={picks_locked}
                    />
                    <PrintBlockFourWide
                        pick={picks?.find((pick) => {
                            return pick.result.round === 1 && pick.result.game === 3
                        })}
                        result={results?.find((result) => {
                            return result.round === 1 && result.game === 3
                        })}
                        updatePick={updatePick}
                        picks_locked={picks_locked}
                    />
                    <PrintBlockFourWide
                        pick={picks?.find((pick) => {
                            return pick.result.round === 1 && pick.result.game === 4
                        })}
                        result={results?.find((result) => {
                            return result.round === 1 && result.game === 4
                        })}
                        updatePick={updatePick}
                        picks_locked={picks_locked}
                    />
                </Grid>
            </Grid>
    )
}