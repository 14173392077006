import Grid from "@mui/material/Grid";

type bracketConnectorProps = {
    height: string;
    fourWide?: boolean;
}
export default function PrintBracketConnector({height, fourWide}: Readonly<bracketConnectorProps>) {
    return (
        <Grid container sx={{
            display: 'flex',
            flexDirection: "row",
            height: height,
            width: 'fit-content',
            minWidth: fourWide ? "2rem": "1rem",
            justifyContent: "center",
        }}>
            <Grid item sx={{
                flex: .5,
                borderTop: `2px solid ${"#000000"}`,
                borderBottom: `2px solid ${"#000000"}`,
                borderRight: `2px solid ${"#000000"}`,
            }}>
            </Grid>
            <Grid item sx={{
                flex: .5,
                display: 'flex',
                flexDirection: "column",
                justifyContent: "center",
                position: "relative",
            }}>
                <hr color={"#000000"} style={{width: "100%"}}/>
            </Grid>
        </Grid>
    )
}