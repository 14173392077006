import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Logo from "../assets/images/logo.png";
import {reset_password_confirm} from "../apis/Auth";
import {Alert, Snackbar, List, ListItem, useMediaQuery, Theme, InputAdornment} from "@mui/material";
import {useEffect, useState} from "react";
import Copyright from "../utilities/Copyright";
import {useParams} from 'react-router-dom';
import background1 from "../assets/images/user_screen_backgrounds/background1.png";
import background2 from "../assets/images/user_screen_backgrounds/background2.png";
import background3 from "../assets/images/user_screen_backgrounds/background3.png";
import background4 from "../assets/images/user_screen_backgrounds/background4.png";
import background5 from "../assets/images/user_screen_backgrounds/background5.png";
import background6 from "../assets/images/user_screen_backgrounds/background6.png";
import LoginIcon from "@mui/icons-material/Login";
import GA4EventSend from "../utilities/GA4EventSend";
import IconButton from "@mui/material/IconButton";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {Helmet} from "react-helmet";


export default function ResetPassword() {
    const {resetToken} = useParams();
    const [open, setOpen] = useState<boolean>(false);
    const [message, setMessage] = useState<String>("");
    const [isClicked, setIsClicked] = useState<boolean>(false);
    const [passwordIsValid, setPasswordIsValid] = useState<boolean>(false);
    const [repasswordIsValid, setRepasswordIsValid] = useState<boolean>(false);
    const [severity, setSeverity] = useState<"success" | "info" | "warning" | "error" | undefined>("error"); // ["success", "info", "warning", "error"
    const [currentImage, setCurrentImage] = useState(background1);
    const [isMdOrUp, setIsMdOrUp] = useState(useMediaQuery((theme: Theme) => theme.breakpoints.up('md')));
    const [showPassword, setShowPassword] = useState(false);
    const [showRePassword, setShowRePassword] = useState(false);

    useEffect(() => {
        // Define your images in an array
        const images = [background1, background2, background3, background4, background5, background6];
        // Create an array to store the preloaded images
        const preloadedImages = [];
        // Pre load the images
        images.forEach((image) => {
            const img = new Image();
            img.src = image;
            preloadedImages.push(img);
        });
        const timer = setInterval(() => {
            setCurrentImage((currentImage) => {
                const currentIndex = images.indexOf(currentImage);
                const nextIndex = (currentIndex + 1) % images.length;
                return images[nextIndex];
            });
        }, 5000); // Change every 5 seconds
        const handleResize = () => {
            setIsMdOrUp(window.innerWidth >= 960);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
            clearInterval(timer)
        };
    }, [isMdOrUp]);

    function findStringValue(obj: any): string | undefined {
        for (const key in obj) {
            if (typeof obj[key] === 'string') {
                return obj[key];
            } else if (typeof obj[key] === 'object') {
                const result = findStringValue(obj[key]);
                if (result) {
                    return result;
                }
            }
        }
        return undefined;
    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        setIsClicked(true);
        event.preventDefault();
        let formData = new FormData(event.currentTarget);
        let password = formData.get("password") ?? "";
        let response = await reset_password_confirm(localStorage.getItem("refresh_token") ?? "", localStorage.getItem("access_token") ?? "", resetToken ?? "", password as string)
        if (response.succeeded) {
            setMessage(response.message);
            setSeverity("success");
            setOpen(true);
            setTimeout(() => {
                window.location.href = "/signin";
                setIsClicked(false);
            }, 4000);
        } else {
            let error = findStringValue(response.message) ?? "";

            if (error === "Not found.") {
                error = "Error resetting password, attempt is no longer valid, redirecting you to forgot password page"
            }

            setMessage(error);
            setSeverity("error");
            setOpen(true);
            setTimeout(() => {
                if (error === "Error resetting password, attempt is no longer valid, redirecting you to forgot password page") {
                    window.location.href = "/forgot_password";
                }
                setIsClicked(false);
            }, 4000);
        }
        GA4EventSend("reset_password", "user_action", "reset_password", response.statusCode, false);
        setIsClicked(false);
    };

    return (

        <Grid container sx={{height: '100vh'}}>
            <Helmet>
                <title>DRBB | Reset Password</title>
            </Helmet>
            <CssBaseline/>
            <Snackbar anchorOrigin={{vertical: "top", horizontal: "center"}} open={open} onClose={() => setOpen(false)}
                      autoHideDuration={6000}>
                <Alert elevation={6} severity={severity} sx={{width: '100%'}} variant="filled">
                    {message}
                </Alert>
            </Snackbar>
            <Grid
                item
                xs={false}
                sm={4}
                md={7}
                sx={{
                    transition: 'background-image 2s ease-in-out',
                    backgroundImage: `url(${currentImage})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundColor: (t) =>
                        t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
            />
            <Grid item xs={12} sm={8} md={5} sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                backgroundColor: (t) =>
                    t.palette.mode === 'light' ? t.palette.grey[50] : "#141414",
            }}>
                <Box
                    sx={{
                        my: 8,
                        mx: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <img src={Logo} alt={"DRBB Logo"} height={'200'}/>
                    <Typography variant={isMdOrUp ? "h3": "h5"} sx={{mt: 1}} fontWeight={"bold"} textAlign={"center"}>
                        Reset Password
                    </Typography>
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{mt: 1}}>
                        <Grid item xs={12}>
                            <TextField
                                color={passwordIsValid ? "success" : "error"}
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type={showPassword ? "text" : "password"}
                                id="password"
                                autoComplete="new-password"
                                onChange={(event) => {
                                    const password: string = event.target.value;
                                    setPasswordIsValid(password.length >= 8 && !RegExp(/^\d+$/).exec(password));
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => setShowPassword(!showPassword)}
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} marginTop={'12px'}>
                            <TextField
                                color={repasswordIsValid ? "success" : "error"}
                                required
                                fullWidth
                                name="re-password"
                                label="Confirm Password"
                                type={showRePassword ? "text" : "password"}
                                id="re-password"
                                onChange={(event) => {
                                    const re_password: string = event.target.value;
                                    const password: string = (document.getElementById("password") as HTMLInputElement).value;
                                    setRepasswordIsValid(re_password.length >= 8 && !RegExp(/^\d+$/).exec(re_password) && re_password === password);
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => setShowRePassword(!showRePassword)}
                                            >
                                                {showRePassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <List sx={{listStyleType: 'disc', marginLeft: '20px'}}>
                                <ListItem sx={{display: 'list-item'}}>
                                    Password must be at least 8 characters long.
                                </ListItem>
                                <ListItem sx={{display: 'list-item'}}>
                                    Password can not be all numbers.
                                </ListItem>
                                <ListItem sx={{display: 'list-item'}}>
                                    Password can not be too similar to your name, nickname, or email.
                                </ListItem>
                            </List>
                        </Grid>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{
                                mt: 3,
                                mb: 2,
                                backgroundColor: "#486ce7",
                                color: "#ffffff",
                            }}
                            disabled={isClicked || !(passwordIsValid && repasswordIsValid)}
                        >
                            <Typography variant={"h6"} fontWeight={"bold"} sx={{
                                textTransform: 'none',
                            }}>
                                Reset Password
                            </Typography>
                        </Button>
                        <Grid container justifyContent="center">
                            <Button
                                startIcon={<LoginIcon/>}
                                href="/signin"
                                sx={{
                                    backgroundColor: "#486ce7",
                                    color: "#ffffff",
                                    ":hover": {
                                        backgroundColor: "#c40000",
                                        color: "#ffffff",
                                    }
                                }}
                            >
                                <Typography variant={"body2"} sx={{
                                    textTransform: 'none',
                                }}>
                                    Return To Sign In Screen
                                </Typography>
                            </Button>
                        </Grid>
                        <Copyright sx={{mt: 5}}/>
                    </Box>
                </Box>
            </Grid>

        </Grid>
    );
}