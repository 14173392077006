import Grid from "@mui/material/Grid";
import {Divider} from "@mui/material";
import PickRowReverse from "../PickRowReverse";
import Paper from "@mui/material/Paper";
import {FourWidePickBlockProps} from "./PickBlockFourWide";
export default function PickBlockReverseFourWide({pick, result, updatePick, picks_locked}: Readonly<FourWidePickBlockProps>){
    return (
        <Grid container component={Paper} m={1} sx={{
            display: 'flex',
            flexDirection: "column",
            height: 'fit-content',
            width: 'fit-content',
        }}>
            <PickRowReverse
                competitor_number={1}
                pick={pick}
                result={result}
                onClick={updatePick}
                picks_locked={picks_locked}
                fourWide={true}
            />
            <Divider/>
            <PickRowReverse
                competitor_number={2}
                pick={pick}
                result={result}
                onClick={updatePick}
                picks_locked={picks_locked}
                fourWide={true}
            />
            <Divider/>
            <PickRowReverse
                competitor_number={3}
                pick={pick}
                result={result}
                onClick={updatePick}
                picks_locked={picks_locked}
                fourWide={true}
            />
            <Divider/>
            <PickRowReverse
                competitor_number={4}
                pick={pick}
                result={result}
                onClick={updatePick}
                picks_locked={picks_locked}
                fourWide={true}
            />
        </Grid>
    )
}