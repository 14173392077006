import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import {Event} from "../../data/models/bracket_api/Event";
import {useEffect, useState} from "react";
import {Theme, useMediaQuery} from "@mui/material";
import {Carousel} from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../../styles/upcoming_events_carousel.css";

type UpcomingEventsCarouselProps = {
    events: Event[],
}

function EventCard(event: Readonly<Event>) {
    const [isMdOrUp, setIsMdOrUp] = useState(useMediaQuery((theme: Theme) => theme.breakpoints.up('md')));

    useEffect(() => {
        const handleResize = () => {
            setIsMdOrUp(window.innerWidth >= 960);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [isMdOrUp]);

    return (
        <Card key={event.id}
            sx={{
            backgroundImage: `url(${event.image_link})`,
            backgroundSize: 'contain',
            width: '100%',
            maxWidth: isMdOrUp ? '80vw' : '95vw',
            aspectRatio: '1.77',
            backgroundPosition: 'center',
            display: 'flex',
            justifyContent: isMdOrUp ? 'flex-start' : 'center',
            alignItems: 'flex-end',
            padding: 1,
            borderRadius: '10px',
            margin: 'auto',
        }}>
            <Grid container sx={{
                height: 'fit-content',
                width: 'fit-content',
                display: 'flex',
                flexDirection: 'column',
                maxWidth: '50%',
                background: 'rgba( 255, 255, 255, 0.3 )',
                backdropFilter: 'blur(6px)',
                boxShadow: '0 8px 32px 0 rgba( 31, 38, 135, 0.37 )',
                borderRadius: '10px',
                color: 'white',
                alignItems: 'flex-start',
                padding: 1,
                visibility: isMdOrUp ? 'visible' : 'hidden',
            }}>
                <Typography variant={"h4"} align={"left"} fontWeight={"bold"}>
                    {event.name}
                </Typography>
                <Typography variant={"h6"} align={"left"}>
                    {new Date(event.open_date).toDateString() + " - " + new Date(event.close_date).toDateString()}
                </Typography>
            </Grid>
        </Card>
    )
}

export default function UpcomingEventsCarousel(props: Readonly<UpcomingEventsCarouselProps>) {
    let events = props.events.filter((event) => {
        return event.image_link != null
    });
    return (
        <Grid container sx={{
            direction: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: 1,
            position: "relative",
        }}>
            <Carousel
                showIndicators={events.length > 1}
                swipeable={true}
                showArrows={false}
                showStatus={false}
                infiniteLoop={false}
                dynamicHeight={false}
                width={"100%"}
            >
                {events.map((event) => {
                    return (
                        EventCard(event)
                    )
                })}
            </Carousel>
        </Grid>
    )
}