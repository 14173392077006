import Grid from "@mui/material/Grid";
import Logo from "../../../../assets/images/logo.png";
import {useEffect, useRef, useState} from "react";
import {Pick} from "../../../../data/models/bracket_api/Pick";
import {Result} from "../../../../data/models/bracket_api/Result";
import {Bracket} from "../../../../data/models/bracket_api/Bracket";
import {RaceClass} from "../../../../data/models/bracket_api/RaceClass";
import {Event} from "../../../../data/models/bracket_api/Event";
import PickBracket from "./PickBracket";
import Link from "@mui/material/Link";

type PickBracketContainerProps = {
    picks_locked: boolean
    brackets? : Bracket[],
    updatePick: (pick: Pick) => void,
    results?: Result[]
    picks?: Pick[],
    event?: Event
}
export default function PickBracketContainer({brackets, picks_locked, updatePick, results, picks, event}: Readonly<PickBracketContainerProps>) {
    const containerRef = useRef<HTMLDivElement>(null);
    const [isOverflowing, setIsOverflowing] = useState<boolean>(true);

    useEffect(() => {
        const container = containerRef.current;

        let isMouseDown = false;
        let startX: number;
        let scrollLeft: number;

        const element = containerRef.current;

        if (element) {
            setIsOverflowing(element.scrollWidth > element.clientWidth);
        }

        const handleMouseDown = (event: MouseEvent) => {
            isMouseDown = true;
            startX = event.pageX - container!.offsetLeft;
            scrollLeft = container!.scrollLeft;
        };

        const handleMouseUp = () => {
            isMouseDown = false;
        };

        const handleMouseMove = (event: MouseEvent) => {
            if (!isMouseDown) return;
            event.preventDefault();
            const x = event.pageX - container!.offsetLeft;
            const walk = (x - startX); // Adjust the scroll speed here
            container!.scrollLeft = scrollLeft - walk;
        };

        const handleResize = () => {
            const element = containerRef.current;

            if (element) {
                setIsOverflowing(element.scrollWidth > element.clientWidth);
            }
        }

        window.addEventListener('resize', handleResize);
        container!.addEventListener('mousedown', handleMouseDown);
        container!.addEventListener('mouseup', handleMouseUp);
        container!.addEventListener('mousemove', handleMouseMove);

        return () => {
            window.removeEventListener('resize', handleResize);
            container!.removeEventListener('mousedown', handleMouseDown);
            container!.removeEventListener('mouseup', handleMouseUp);
            container!.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    return (
        <Grid
            container
            ref={containerRef}
            sx={{
                width: 'fit-content',
                overflow: 'auto',
                cursor: isOverflowing ? 'grab' : 'default',
                userSelect: 'none',
            }}
        >
            <Grid container sx={{
                display: 'flex',
                flexDirection: "column",
                justifyContent: "space-evenly",
                alignItems: "center",
                alignContent: "center",
                height: 'fit-content',
                width: 'fit-content',
            }}>
                <Grid container sx={{
                    marginY: 1,
                    display: 'flex',
                    flexDirection: "row",
                    flexWrap: "nowrap",
                    width: 'fit-content',
                }}>
                    <PickBracket
                        race_class={RaceClass.TOP_FUEL}
                        bracket={brackets?.find(bracket => bracket.race_class === RaceClass.TOP_FUEL)}
                        picks_locked={picks_locked}
                        updatePick={updatePick}
                        results={results?.filter((result) => {return result.bracket.race_class === RaceClass.TOP_FUEL})}
                        picks={picks?.filter((pick) => {return pick.result.bracket.race_class === RaceClass.TOP_FUEL})}
                    />
                    <PickBracket
                        race_class={RaceClass.FUNNY_CAR}
                        bracket={brackets?.find(bracket => bracket.race_class === RaceClass.FUNNY_CAR)}
                        picks_locked={picks_locked}
                        updatePick={updatePick}
                        results={results?.filter((result) => {return result.bracket.race_class === RaceClass.FUNNY_CAR})}
                        picks={picks?.filter((pick) => {return pick.result.bracket.race_class === RaceClass.FUNNY_CAR})}
                    />
                </Grid>
                <Grid container sx={{
                    marginY: 1,
                    display: 'flex',
                    flexDirection: "row",
                    flexWrap: "nowrap",
                    height: '1rem',
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                    {
                        event?.left_sponsor_image_link && (event.left_sponsor_click_link ?
                                <Link href={event.left_sponsor_click_link} target={"_blank"}>
                                    <img src={event.left_sponsor_image_link} alt={"Sponsor Logo"}
                                         style={{maxHeight: '250px', maxWidth: '250px'}}/>
                                </Link> :
                                <img src={event.left_sponsor_image_link} alt={"Sponsor Logo"}
                                     style={{maxHeight: '250px', maxWidth: '250px'}}/>
                        )
                    }
                    <img src={Logo} alt={"DRBB Logo"} height={'350px'} style={{margin: '50px'}}/>
                    {
                        event?.right_sponsor_image_link && (event.right_sponsor_click_link ?
                                <Link href={event.right_sponsor_click_link} target={"_blank"}>
                                    <img src={event.right_sponsor_image_link} alt={"Sponsor Logo"}
                                         style={{maxHeight: '250px', maxWidth: '250px'}}/>
                                </Link> :
                                <img src={event.right_sponsor_image_link} alt={"Sponsor Logo"}
                                     style={{maxHeight: '250px', maxWidth: '250px'}}/>
                        )
                    }
                </Grid>
                <Grid container sx={{
                    marginY: 1,
                    display: 'flex',
                    flexDirection: "row",
                    flexWrap: "nowrap",
                    width: 'fit-content',
                }}>
                    <PickBracket
                        race_class={RaceClass.PRO_STOCK}
                        bracket={brackets?.find(bracket => bracket.race_class === RaceClass.PRO_STOCK)}
                        picks_locked={picks_locked}
                        updatePick={updatePick}
                        results={results?.filter((result) => {return result.bracket.race_class === RaceClass.PRO_STOCK})}
                        picks={picks?.filter((pick) => {return pick.result.bracket.race_class === RaceClass.PRO_STOCK})}
                    />
                    <PickBracket
                        race_class={RaceClass.PRO_STOCK_MOTORCYCLE}
                        bracket={brackets?.find(bracket => bracket.race_class === RaceClass.PRO_STOCK_MOTORCYCLE)}
                        picks_locked={picks_locked}
                        updatePick={updatePick}
                        results={results?.filter((result) => {return result.bracket.race_class === RaceClass.PRO_STOCK_MOTORCYCLE})}
                        picks={picks?.filter((pick) => {return pick.result.bracket.race_class === RaceClass.PRO_STOCK_MOTORCYCLE})}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}