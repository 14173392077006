export enum PickStatus{
    PENDING,
    CORRECT,
    INCORRECT,
    TIEBREAKER,
    BYE
}

export function pickStatusFromInt(status: number): PickStatus{
    switch(status){
        case 1:
            return PickStatus.PENDING;
        case 2:
            return PickStatus.CORRECT;
        case 3:
            return PickStatus.INCORRECT;
        case 4:
            return PickStatus.TIEBREAKER;
        case 5:
            return PickStatus.BYE;
        default:
            return PickStatus.PENDING;
    }
}