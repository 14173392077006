import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {
    Alert,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Snackbar, Theme,
    useMediaQuery,
    useTheme
} from "@mui/material";
import {useEffect, useState} from "react";
import TextField from "@mui/material/TextField";
import * as React from "react";
import Button from "@mui/material/Button";
import Logo from "../assets/images/logo.png";
import * as AuthAPI from "../apis/Auth";
import * as LeagueAPI from "../apis/League";
import Link from "@mui/material/Link";
import {Helmet} from "react-helmet";

export default function Intro() {
    if (localStorage.getItem('user') === null) {
        window.location.href = "/signup";
    }
    const [open, setOpen] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [method, setMethod] = useState<string>("");
    const [inviteCode, setInviteCode] = useState<string>("");
    const [isClicked, setIsClicked] = useState(false);
    const [success, setSuccess] = useState<boolean>(false);
    const [isMdOrUp, setIsMdOrUp] = useState(useMediaQuery((theme: Theme) => theme.breakpoints.up('md')));
    useEffect(() => {
        const handleResize = () => {
            setIsMdOrUp(window.innerWidth >= 960);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [isMdOrUp]);

    const handleChange = (event: SelectChangeEvent) => {
        setMethod(event.target.value);
    };

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    async function joinLeague() {
        setIsClicked(true);
        let response = await LeagueAPI.join_league(
            JSON.parse(localStorage.getItem('user') as string).id,
            inviteCode
        );
        if (response.succeeded) {
            setSuccess(true);
        } else {
            setSuccess(false);
        }
        setMessage(response.message);
        setOpen(true);
        setIsClicked(false);
    }

    function continueToHome() {
        localStorage.setItem("home", "true");
        AuthAPI.set_acquisition_source(localStorage.getItem("refresh_token") as string, localStorage.getItem("access_token") as string, method)
            .then((res) => {
                window.location.href = "/";
            });
    }

    const theme = useTheme();
    const darkMode = theme.palette.mode === 'dark';

    return (
        <Grid container sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
            background: darkMode ?
            'linear-gradient(135deg, white 10%, #121212 10%, #121212 12%, white 12%, white 22%, transparent 22%);'
            :
            'linear-gradient(135deg, #7F9AF5 10%, white 10%, white 12%, #7F9AF5 12%, #7F9AF5 22%, transparent 22%);',
            backgroundAttachment: 'fixed',
            overflowY: 'auto',
        }}>
            <Helmet>
                <title>{"DRBB | Welcome"}</title>
            </Helmet>
            <CssBaseline/>
            <Snackbar anchorOrigin={{vertical: "top", horizontal: "center"}} open={open} onClose={handleClose}
                      autoHideDuration={6000}>
                <Alert elevation={6} severity={success ? "success" : "error"} sx={{width: '100%'}} variant="filled">
                    {message}
                </Alert>
            </Snackbar>
            <Grid container
                  sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      height: 'fit-content',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: 'fit-content',
                      borderRadius: isMdOrUp ? '10px' : '0',
                      padding: '1rem',
                      backgroundColor: (t) =>
                          t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                  }}>
                <CssBaseline/>
                <img src={Logo} alt={'logo'} style={{maxWidth: '250px'}}/>
                <Typography component="h1" variant="h3" sx={{marginY: 1}} fontWeight={"bold"} textAlign={"center"}>
                    Welcome to Drag Race Bracket Bonanza!
                </Typography>
                <FormControl sx={{
                    maxWidth: '90%',
                    width: '300px',
                }}>
                    <InputLabel id="hear-about-us">How did you hear about DRBB?</InputLabel>
                    <Select
                        labelId="hear-about-us"
                        id="demo-simple-select"
                        value={method}
                        label="How did you here about DRBB?"
                        onChange={handleChange}
                    >
                        <MenuItem value={'Facebook'}>Facebook</MenuItem>
                        <MenuItem value={'Twitter'}>Twitter</MenuItem>
                        <MenuItem value={'Instagram'}>Instagram</MenuItem>
                        <MenuItem value={'NHRA TV'}>NHRA TV</MenuItem>
                        <MenuItem value={'Email'}>Email</MenuItem>
                        <MenuItem value={'Other'}>Other</MenuItem>
                    </Select>
                </FormControl>
                <Typography component="h1" variant="h5" sx={{mt: 1}} fontWeight={"bold"} textAlign={"center"}>
                    If you were sent an invite code, enter it below.
                </Typography>
                <TextField
                    margin="normal"
                    required
                    id="invite_code"
                    label="League Invite Code"
                    name="email"
                    autoFocus
                    sx={{
                        maxWidth: '90%',
                        width: '300px',
                    }}
                    onChange={(e) => {
                        setInviteCode(e.target.value);
                    }}
                />
                <Button
                    variant={"contained"}
                    sx={{
                        marginY: '1rem',
                        backgroundColor: "#486ce7",
                        color: "#ffffff",
                        ":hover": {
                            backgroundColor: "#c40000",
                            color: "#ffffff",
                        }
                    }}
                    disabled={isClicked}
                    onClick={() => {
                        setIsClicked(true)
                        joinLeague().then(() => {
                            setIsClicked(false);
                        });
                    }}
                >
                    <Typography variant="h6" component="h1" sx={{textAlign: 'center'}}>
                        Join League
                    </Typography>
                </Button>
                <Link href={'https://mailchi.mp/2a9d16e6744b/sms-signup'} target={"_blank"}>
                    <Button
                        variant={"contained"}
                        sx={{
                            marginY: '1rem',
                            backgroundColor: "#486ce7",
                            color: "#ffffff",
                            ":hover": {
                                backgroundColor: "#c40000",
                                color: "#ffffff",
                            }
                        }}
                        disabled={isClicked}
                        onClick={() => {}}
                    >
                        <Typography variant="h6" component="h1" sx={{textAlign: 'center'}}>
                            Sign Up For SMS Notifications
                        </Typography>
                    </Button>
                </Link>
                <Button
                    variant={"contained"}
                    sx={{
                        marginY: '1rem',
                        backgroundColor: "#486ce7",
                        color: "#ffffff",
                        ":hover": {
                            backgroundColor: "#c40000",
                            color: "#ffffff",
                        }
                    }}
                    disabled={isClicked || method === ""}
                    onClick={() => {
                        continueToHome();
                    }}
                >
                    <Typography variant="h6" component="h1" sx={{textAlign: 'center'}}>
                        Continue
                    </Typography>
                </Button>
            </Grid>
        </Grid>
    )
}