import {ResultDetailProps} from "../components/home/results/ResultsCard";
import {Config} from "../Config";

const config: Config = new Config();

type ResultDetailResponse = {
    data?: ResultDetailProps,
    succeeded: boolean,
    message: string,
    statusCode: number,
}
export default async function ResultDetail(id: string): Promise<ResultDetailResponse>{
    if(window.location.pathname === "/error"){
        return {succeeded: false, statusCode: 500, message: "Something went wrong on our end"};
    }
    try{
        if(id === null){
            return {succeeded: false, statusCode: 500, message: "Result id is empty"};
        }
        else {
            let response: Response = await fetch(config.backendURL + config.endpoints.result_detail + id + "/", {
                method: 'GET',
                headers: {
                    'content-type': 'application/json;charset=UTF-8',
                },
            });
            let responseStatus: number = response.status;
            let data: any = await response.json();
            if(responseStatus < 400){
                return {
                    succeeded: true, statusCode: responseStatus, message: "Result acquired successfully", data: data,
                };
            }

            else{
                return {succeeded: false, statusCode: responseStatus, message: "Failed to retrieve result details"};
            }
        }
    }
    catch (e) {
        if(e instanceof TypeError && e.message === "Failed to fetch"){
            window.location.href = "/error";
        }
        return {succeeded: false, statusCode: 500, message: "Something went wrong on our end"};
    }
}