import Typography from "@mui/material/Typography";
import {
    Alert,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Snackbar,
    Switch
} from "@mui/material";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DateTimePicker} from "@mui/x-date-pickers/DateTimePicker";
import {RaceClass} from "../../../data/models/bracket_api/RaceClass";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import * as React from "react";
import {ChangeEvent, useState} from "react";
import {Event} from "../../../data/models/bracket_api/Event";
import dayjs from "dayjs";
import {Series} from "../../../data/models/bracket_api/Series";
import {PrimaryButtonStyles, SubmitButtonStyles} from "../../../styles/ButtonStyles";
import {Bracket} from "../../../data/models/bracket_api/Bracket";
import {EventConfigLoadResponse} from "../../../apis/ScreenLoad";
import {upload_left_sponsor_image, upload_right_sponsor_image, upload_event_image} from "../../../apis/Event";

type EventDetailFormProps = {
    event: Event,
    series: Series[],
    onEventChange: (event: Event) => void,
    toggleBrackets: (classes: string[]) => void,
    brackets: Bracket[],
    submitEvent: () => Promise<EventConfigLoadResponse>,
};

export default function EventDetailForm(props: Readonly<EventDetailFormProps>) {
    let raceClasses = [];
    for(let bracket of props.brackets){
        raceClasses.push(bracket.race_class);
    }
    const [selectedOptions, setSelectedOptions] = useState<string[]>(raceClasses);
    const [open, setOpen] = useState<boolean>(false);
    const [message, setMessage] = useState<String>("");
    const [severity, setSeverity] = useState<"success" | "info" | "warning" | "error" | undefined>("error");
    const [isClicked, setIsClicked] = useState(false);
    const [eventImage, setEventImage] = useState<File | undefined>(undefined);
    const [leftSponsorImage, setLeftSponsorImage] = useState<File | undefined>(undefined);
    const [rightSponsorImage, setRightSponsorImage] = useState<File | undefined>(undefined);
    const onFormChange = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        let data = new FormData(event.currentTarget);
        let eventName = data.get('event-name') as string;
        let eventLocation = data.get('event-location') as string;
        let eventDescription = data.get('event-description') as string;

        if ((event.target as HTMLInputElement).type === 'file') {
            return; // If it is, return early
        }

        props.onEventChange({
            ...props.event,
            name: eventName,
            location: eventLocation,
            description: eventDescription,
        })
    }

    const handleImageUpload = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        // Perform any validation or checks on the file here
        // make sure file is of a photo extension
        const extension: string = file?.name.split('.').pop() as string;
        if (extension !== 'jpg' && extension !== 'jpeg' && extension !== 'png') {
            setSeverity("error")
            setMessage("File must be a photo");
            setOpen(true);
        } else {
            if (event.target.id === "event-image") {
                setEventImage(file);
                props.onEventChange({
                    ...props.event,
                    image_link: URL.createObjectURL(file!),
                })
            } else if (event.target.id === "event-left-sponsor-image") {
                setLeftSponsorImage(file)
                props.onEventChange({
                    ...props.event,
                    left_sponsor_image_link: URL.createObjectURL(file!),
                })
            } else if (event.target.id === "event-right-sponsor-image") {
                setRightSponsorImage(file)
                props.onEventChange({
                    ...props.event,
                    right_sponsor_image_link: URL.createObjectURL(file!),
                })
            }
            setSeverity("success")
            setMessage("File is valid format");
            setOpen(true);
        }
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        setIsClicked(true);
        event.preventDefault();
        if(props.event.series === undefined){
            setSeverity("error");
            setMessage("Please select a series");
            setOpen(true);
            setIsClicked(false);
            return
        }
        let response = await props.submitEvent();
        if(response.succeeded){
            if(eventImage !== undefined){
                let imageResponse = await upload_event_image(eventImage as File, props.event.id as string);
                if(!imageResponse.succeeded){
                    setSeverity("error");
                    setMessage(imageResponse.message);
                    setOpen(true);
                    setIsClicked(false);
                    return
                }
                else{
                    props.onEventChange({
                        ...props.event,
                        image_link: imageResponse.data,
                    })
                }
            }
            if(leftSponsorImage !== undefined){
                let imageResponse = await upload_left_sponsor_image(leftSponsorImage as File, props.event.id as string);
                if(!imageResponse.succeeded){
                    setSeverity("error");
                    setMessage(imageResponse.message);
                    setOpen(true);
                    setIsClicked(false);
                    return
                }
                else{
                    props.onEventChange({
                        ...props.event,
                        left_sponsor_image_link: imageResponse.data,
                    })
                }
            }
            if(rightSponsorImage !== undefined){
                let imageResponse = await upload_right_sponsor_image(rightSponsorImage as File, props.event.id as string);
                if(!imageResponse.succeeded){
                    setSeverity("error");
                    setMessage(imageResponse.message);
                    setOpen(true);
                    setIsClicked(false);
                    return
                }
                else{
                    props.onEventChange({
                        ...props.event,
                        right_sponsor_image_link: imageResponse.data,
                    })
                }
            }
            setSeverity("success");
            setMessage(response.message);
            setOpen(true);
        }
        else{
            setSeverity("error");
            setMessage(response.message);
            setOpen(true);
        }
        setIsClicked(false);
    }


    return (
        <Grid container sx={{
            backgroundColor: 'background.paper',
            borderRadius: '5px',
            display: 'flex',
            flexDirection: 'column',
            height: 'fit-content',
            width: 'fit-content',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '12px',
        }}>
            <Snackbar anchorOrigin={{vertical: "top", horizontal: "center"}} open={open} onClose={() => {
                setOpen(false);
            }}
                      autoHideDuration={6000}>
                <Alert elevation={6} severity={severity} sx={{width: '100%'}} variant="filled">
                    {message}
                </Alert>
            </Snackbar>
            <Grid container sx={{
                display: 'flex',
                flexDirection: 'row',
                direction: 'row',
                flexWrap: 'nowrap',
                width: 'fit-content',
                minWidth: '800px',
            }} component={"form"} onChange={onFormChange} onSubmit={handleSubmit}>
                <Grid container direction={"column"} margin={'8px'}>
                    <TextField
                        label="Event Name"
                        variant="filled"
                        defaultValue={props.event.name}
                        name={"event-name"}
                        id={"event-name"}
                        sx={{
                            width: '100%',
                            marginY: 1,
                        }}
                        onChange={() => {
                        }}
                    />
                    <TextField
                        label="Event Location"
                        variant="filled"
                        defaultValue={props.event.location}
                        multiline
                        rows={3}
                        sx={{
                            width: '100%',
                            marginY: 1,
                        }}
                        name={"event-location"}
                        id={"event-location"}
                        onChange={() => {
                        }}
                    />
                    <TextField
                        label="Event Description"
                        variant="filled"
                        multiline
                        rows={8}
                        defaultValue={props.event.description}
                        sx={{
                            width: '100%',
                            marginY: 1,
                        }}
                        name={"event-description"}
                        id={"event-description"}
                        onChange={() => {
                        }}
                    />
                    <FormControl sx={{
                        width: '100%',
                        marginY: 1,
                    }}>
                        <InputLabel id="series-label">Select Series</InputLabel>
                        <Select
                            labelId="series-label"
                            id="series-select"
                            name={"series-select"}
                            label={"Select Series"}
                            value={props.event.series ? props.event.series.name : ""}
                            onChange={(event) => {
                                let selectedSeries = props.series.find(series => series.name === event.target.value);
                                if (selectedSeries === undefined) return;
                                let updatedEvent: Event = {...props.event, series: selectedSeries};
                                props.onEventChange(updatedEvent);
                            }}
                        >
                            {
                                props.series.map((series) => {
                                    return <MenuItem key={series.id} value={series.name}>{series.name}</MenuItem>
                                })
                            }
                        </Select>
                    </FormControl>
                    <FormControl sx={{
                        width: '100%',
                        marginY: 1,
                    }}>
                        <InputLabel id="race-class-label">Select Race Classes</InputLabel>
                        <Select
                            labelId="race-class-label"
                            id="race-class-select"
                            name={"race-class-select"}
                            label={"Select Race Classes"}
                            multiple
                            value={selectedOptions}
                            onChange={(event) => {
                                setSelectedOptions(event.target.value as string[])
                                props.toggleBrackets(event.target.value as string[]);
                            }}
                        >
                            <MenuItem value={RaceClass.TOP_FUEL}>Top Fuel</MenuItem>
                            <MenuItem value={RaceClass.FUNNY_CAR}>Funny Car</MenuItem>
                            <MenuItem value={RaceClass.PRO_STOCK}>Pro Stock</MenuItem>
                            <MenuItem value={RaceClass.PRO_STOCK_MOTORCYCLE}>Pro Stock Motorcycle</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid container direction={"column"} margin={'8px'}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                            label="Event Start Date and Time"
                            value={dayjs(props.event.start_date)}
                            sx={{
                                width: '100%',
                                marginY: 1,
                            }}
                            onChange={(e) => {
                                if (e === null) return;
                                let updatedEvent: Event = {...props.event, start_date: e.toDate()};
                                props.onEventChange(updatedEvent);
                            }}
                        />
                        <DateTimePicker
                            label="Brackets Open Date and Time"
                            value={dayjs(props.event.open_date)}
                            sx={{
                                width: '100%',
                                marginY: 1,
                            }}
                            onChange={(e) => {
                                if (e === null) return;
                                let updatedEvent: Event = {...props.event, open_date: e.toDate()};
                                props.onEventChange(updatedEvent);
                            }}
                        />
                        <DateTimePicker
                            label="Brackets Close Date and Time"
                            value={dayjs(props.event.close_date)}
                            sx={{
                                width: '100%',
                                marginY: 1,
                            }}
                            onChange={(e) => {
                                if (e === null) return;
                                let updatedEvent = {...props.event, close_date: e.toDate()};
                                props.onEventChange(updatedEvent);
                            }}
                        />
                        <DateTimePicker
                            label="Event End Date and Time"
                            value={dayjs(props.event.end_date)}
                            sx={{
                                width: '100%',
                                marginY: 1,
                            }}
                            onChange={(e) => {
                                if (e === null) return;
                                let updatedEvent: Event = {...props.event, end_date: e.toDate()};
                                props.onEventChange(updatedEvent);
                            }}
                        />
                    </LocalizationProvider>
                    <FormControlLabel
                        control={
                            <Switch
                                name="four_wide"
                                checked={props.event.is_four_wide}
                                onChange={(event) => {
                                    let updatedEvent = props.event;
                                    updatedEvent.is_four_wide = !updatedEvent.is_four_wide;
                                    props.onEventChange(updatedEvent);
                                }}
                            />
                        }
                        label="Four Wide"
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                name="picks_locked"
                                defaultChecked={props.event.picks_locked}
                                onChange={(event) => {
                                    let updatedEvent = props.event;
                                    updatedEvent.picks_locked = !updatedEvent.picks_locked;
                                    props.onEventChange(updatedEvent);
                                }}
                            />
                        }
                        label="Picks Locked"
                    />
                    <Button variant="contained" component="label" style={{margin: '8px'}} sx={{
                        backgroundColor: "#486ce7",
                        color: "#ffffff",
                        borderRadius: 0,
                        ":hover": {
                            backgroundColor: "#c40000",
                            color: "#ffffff",
                        }
                    }}>
                        <Typography variant={'h6'} textAlign={"center"}>
                            Upload Event Photo
                        </Typography>
                        <input type="file" id="event-image" name={"event-image"} hidden onInput={handleImageUpload}/>
                    </Button>
                    <Button variant="contained" component="label" style={{margin: '8px'}} sx={{
                        backgroundColor: "#486ce7",
                        color: "#ffffff",
                        borderRadius: 0,
                        ":hover": {
                            backgroundColor: "#c40000",
                            color: "#ffffff",
                        }
                    }}>
                        <Typography variant={'h6'} textAlign={"center"}>
                            Upload Left Sponsor Photo
                        </Typography>
                        <input type="file" id="event-left-sponsor-image" name={"event-left-sponsor-image"} hidden onInput={handleImageUpload}/>
                    </Button>
                    <Button variant="contained" component="label" style={{margin: '8px'}} sx={{
                        backgroundColor: "#486ce7",
                        color: "#ffffff",
                        borderRadius: 0,
                        ":hover": {
                            backgroundColor: "#c40000",
                            color: "#ffffff",
                        }
                    }}>
                        <Typography variant={'h6'} textAlign={"center"}>
                            Upload Right Sponsor Photo
                        </Typography>
                        <input type="file" id="event-right-sponsor-image" name={"event-right-sponsor-image"} hidden onInput={handleImageUpload}/>
                    </Button>
                    <Button variant="contained" type={"submit"} sx={SubmitButtonStyles} disabled={isClicked}>
                        <Typography variant={'h6'} textAlign={"center"}>
                            Submit Changes
                        </Typography>
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
}