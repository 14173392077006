import Grid from "@mui/material/Grid";
import PickRow from "../PickRow";
import {Divider} from "@mui/material";
import {Pick} from "../../../../data/models/bracket_api/Pick";
import Paper from "@mui/material/Paper";
import {Result} from "../../../../data/models/bracket_api/Result";

export type TwoWidePickBlockProps = {
    pick?: Pick,
    result?: Result,
    updatePick: (pick: Pick) => void,
    picks_locked: boolean
}

export default function PickBlock({pick, result, updatePick, picks_locked}: Readonly<TwoWidePickBlockProps>){
    return (
        <Grid container component={Paper} m={1} sx={{
            display: 'flex',
            flexDirection: "column",
            height: 'fit-content',
            width: 'fit-content',
        }}>
            <PickRow
                competitor_number={1}
                pick={pick}
                result={result}
                onClick={updatePick}
                picks_locked={picks_locked}
                fourWide={false}
            />
            <Divider/>
            <PickRow
                competitor_number={2}
                pick={pick}
                result={result}
                onClick={updatePick}
                picks_locked={picks_locked}
                fourWide={false}
            />
        </Grid>
    )
}