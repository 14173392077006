import {League} from "../../data/models/user_auth/League";
import {
    Alert,
    Avatar,
    Card,
    CardContent,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Snackbar, Theme,
    useMediaQuery
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CopyToClipboardButton from "../common/CopyToClipboardButton";
import Button from "@mui/material/Button";
import * as React from "react";
import {useEffect, useState} from "react";
import {copyPicks} from "../../apis/Pick";
import Paper from "@mui/material/Paper";

type LeagueRowProps = {
    league: League,
    user_id?: number,
    event_id?: string,
    brackets_completed?: Array<number>,
    event_active?: boolean,
    brackets_open?: boolean,
    leagues: Array<League>,
    brackets_viewable?: boolean,
}

export default function LeagueRow(props: Readonly<LeagueRowProps>) {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState<"success" | "info" | "warning" | "error">("success");
    const [isMdOrUp, setIsMdOrUp] = useState(useMediaQuery((theme: Theme) => theme.breakpoints.up('md')));
    let completedBrackets = props.brackets_completed ?? [];
    useEffect(() => {
        const handleResize = () => {
            setIsMdOrUp(window.innerWidth >= 960);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [isMdOrUp]);


    function determineFillOutBracketDisabled() {
        return false;
    }

    async function handleCopyPicks(fromLeagueId: number, toLeagueId: number) {
        let response = await copyPicks(localStorage.getItem("access_token") ?? "", localStorage.getItem("refresh_token") ?? "", fromLeagueId, toLeagueId, props.event_id as string);
        if (response.succeeded) {
            completedBrackets.push(toLeagueId);
            setMessage(response.message);
            setSeverity("success");
            setOpen(true);
        } else {
            setMessage(response.message);
            setSeverity("error");
            setOpen(true);
        }
    }

    let fillOutBracketDisabled = determineFillOutBracketDisabled();

    return (
        <Card
            component={Paper}
            elevation={3}
            sx={{
                marginY: 2,
                borderRadius: '10px',
            }}
        >
            <Snackbar anchorOrigin={{vertical: "top", horizontal: "center"}} open={open} onClose={() => setOpen(false)}
                      autoHideDuration={6000}>
                <Alert elevation={6} severity={severity} sx={{width: '100%'}} variant="filled">
                    {message}
                </Alert>
            </Snackbar>
            <CardContent>
                <Grid container sx={{
                    display: 'flex',
                    flexDirection: isMdOrUp ? 'row' : 'column',
                    justifyContent: isMdOrUp ? 'flex-start' : 'center',
                    alignItems: 'center',
                    flexWrap: 'nowrap',
                }}>
                    {props.league.image_url !== "" ?
                        <Avatar src={props.league.image_url} sx={{height: '125px', width: '125px'}}/>
                        :
                        <Avatar sx={{height: '75px', width: '75px'}}/>
                    }
                    <Grid container sx={{
                        direction: 'column',
                        justifyContent: isMdOrUp ? 'flex-start' : 'center',
                        alignContent: 'center',
                        alignItems: 'center',
                        overflow: 'ellipsis',
                    }}>
                        <Typography variant={isMdOrUp ? 'h3' : 'h5'} margin={1} fontWeight={'bold'}
                                    align={isMdOrUp ? "left" : "center"}>
                            {props.league.name}
                        </Typography>
                        <Grid container sx={{
                            direction: 'row',
                            alignItems: 'center'
                        }}>
                            <Button
                                variant={"contained"}
                                sx={{
                                    margin: 1,
                                    backgroundColor: "#486ce7",
                                    width: isMdOrUp ? 'fit-content' : '100%',
                                    color: "#ffffff",
                                    ":hover": {
                                        backgroundColor: "#c40000",
                                        color: "#ffffff",
                                    }
                                }}
                                onClick={() => {
                                    window.location.href = "/league/?" + encodeURIComponent("league_id=" + props.league.id);
                                }}>
                                <Typography variant={'h6'}>
                                    View League
                                </Typography>
                            </Button>
                            {props.league.name !== "Drag Race Bracket Bonanza" &&
                            <CopyToClipboardButton
                                text_to_copy={`You have been invited to join ${props.league.name}! The invite code is ${props.league.invite_code}`}
                                text_to_display={"Copy Invite Code"}
                                style={{
                                    margin: '8px',
                                    width: isMdOrUp ? 'fit-content' : '100%',
                                    backgroundColor: "#486ce7",
                                    color: "#ffffff",
                                }}
                            />
                            }
                            {props.brackets_viewable && props.event_id !== undefined && props.event_active &&
                                <Button
                                    variant={"contained"}
                                    sx={{
                                        margin: 1,
                                        backgroundColor: "#486ce7",
                                        width: isMdOrUp ? 'fit-content' : '100%',
                                        color: "#ffffff",
                                        ":hover": {
                                            backgroundColor: "#c40000",
                                            color: "#ffffff",
                                        }
                                    }}
                                    disabled={fillOutBracketDisabled}
                                    onClick={() => {
                                        window.location.href = "/bracket/?" + encodeURIComponent("event_id=" + props.event_id + "&league_id=" + props.league.id + "&user_id=" + props.user_id);
                                    }}>
                                    <Typography variant={'h6'}>
                                        {props?.brackets_open ? (completedBrackets.includes(props.league.id) ? "Edit Bracket" : "Fill Out Bracket") : "View Bracket"}
                                    </Typography>
                                </Button>
                            }
                            {props.event_id !== undefined && props.event_active &&
                                !fillOutBracketDisabled && props?.brackets_completed && props.brackets_completed.filter((leagueId: number) => {
                                    return leagueId !== props.league.id
                                }).length > 0 &&
                                <FormControl sx={{
                                    minWidth: '300px',
                                    width: isMdOrUp ? 'fit-content' : '100%',
                                }}>
                                    <InputLabel id="copy-picks-label">Copy Bracket</InputLabel>
                                    <Select
                                        labelId="copy-picks-label"
                                        id="copy-picks-select"
                                        name={"copy-picks-select"}
                                        label={"Copy Bracket To {props.league.name}"}
                                        onChange={(event) => {
                                            let fromLeagueId = event.target.value as number;
                                            let toLeagueId = props.league.id;
                                            handleCopyPicks(fromLeagueId, toLeagueId);
                                        }}
                                    >
                                        {props.brackets_completed.filter((leagueId: number) => {
                                            return leagueId !== props.league.id
                                        }).map((leagueId: number) => {
                                            return (
                                                <MenuItem key={leagueId}
                                                          value={leagueId}>{props.leagues.find((league) => {
                                                    return league.id === leagueId
                                                })?.name ?? ""}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}