import Skeleton from '@mui/material/Skeleton';
import { Grid, Box } from '@mui/material';

export default function EventDetailFormSkeleton() {
    return (
        <Grid container direction={'row'} sx={{width: 'fit-content'}}>
            <Box margin={'8px'}>
                <Skeleton variant="text" width={384} height={60} />
                <Skeleton variant="text" width={384} height={100} />
                <Skeleton variant="text" width={384} height={220} />
                <Skeleton variant="text" width={384} height={60} />
                <Skeleton variant="text" width={384} height={60} />

            </Box>
            <Box margin={'8px'}>
                <Skeleton variant="text" width={300} height={60} />
                <Skeleton variant="text" width={300} height={60} />
                <Skeleton variant="text" width={300} height={60} />
                <Skeleton variant="text" width={300} height={60} />

                <Skeleton variant="circular" width={20} height={20} />
                <Skeleton variant="circular" width={20} height={20} />

                <Skeleton variant="text" width={300} height={60} />
                <Skeleton variant="text" width={300} height={60} />
                <Skeleton variant="text" width={300} height={60} />
                <Skeleton variant="text" width={300} height={60} />
            </Box>
            <Box margin={'8px'}>
                <Skeleton variant="text" width={300} height={60} />
                <Skeleton variant="text" width={300} height={60} />
                <Skeleton variant="text" width={300} height={60} />
            </Box>
        </Grid>
    );
}