import Typography from "@mui/material/Typography";
import Layout from "../utilities/Layout";
import Grid from "@mui/material/Grid";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    CardActions,
    CardContent,
    Divider,
    Stack, Theme,
    useMediaQuery
} from "@mui/material";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {Helmet} from "react-helmet";
import {useEffect, useState} from "react";

export default function Help() {
    const [isMdOrUp, setIsMdOrUp] = useState(useMediaQuery((theme: Theme) => theme.breakpoints.up('md')));
    useEffect(() => {
        const handleResize = () => {
            setIsMdOrUp(window.innerWidth >= 960);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [isMdOrUp]);
    return (
        <Layout>
            <Helmet>
                <title>DRBB | Help</title>
            </Helmet>
            <Grid container sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <Grid component={Paper} elevation={3} container sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    borderRadius: '10px',
                    width: '90%',
                    margin: 2,
                    p: 2,
                }}>
                    <Typography variant={"h5"} color="text.primary" fontWeight={"bold"}>
                        Frequently Asked Questions
                    </Typography>
                    <Divider sx={{width: "95%", m: 2}}/>
                    <Accordion sx={{
                        width: '90%',
                    }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="brackets-content"
                            id="brackets-header"
                        >
                            <Typography variant={"h6"}>How do I fill out a bracket?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                To fill out a bracket, you must first create an account and log in. Brackets are
                                available to be filled out
                                only between when qualifiers are published and before the first round of Top Fuel
                                eliminations. In this time
                                period, you can navigate either to the leagues screen and press the fill out bracket
                                button, or press the fill
                                out bracket button on the home screen. You will be taken to the bracket screen, where
                                for two wide you can click
                                the winner for each race, and for four wide you can click the placings (first through
                                fourth) for each race.
                                Clicking an already selected driver will deselect them. Each pro division will have you
                                enter a guess for the
                                top speed during eliminations to act as a tiebreaker for competitors. You are required
                                to fill out a bracket
                                for the global DRBB competition before you can fill out a bracket for any leagues you
                                are in. You can copy
                                brackets you have already filled out by selecting it in the drop down menu underneath
                                each league on the
                                leagues screen.
                            </Typography>
                            <br/>
                            {/*TODO Add Links when ready*/}
                            {/*<Typography>*/}
                            {/*    Below is a video tutorial on how to fill out a bracket for both Two Wide and Four Wide eliminations.*/}
                            {/*    <br/>*/}
                            {/*    <br/>*/}
                            {/*    <Stack direction={"column"} spacing={2}>*/}
                            {/*        <Link target={"_blank"}>*/}
                            {/*            Two Wide Bracket Video*/}
                            {/*        </Link>*/}
                            {/*        <Link target={"_blank"}>*/}
                            {/*            Four Wide Bracket Video*/}
                            {/*        </Link>*/}
                            {/*    </Stack>*/}
                            {/*</Typography>*/}
                        </AccordionDetails>
                    </Accordion>
                    <Accordion sx={{
                        width: '90%',
                    }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="scoring-content"
                            id="scoring-header"
                        >
                            <Typography variant={"h6"}>How are brackets scored?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant={"h6"}>Two Wide:</Typography>
                            <Typography>
                                Each pick is worth different amounts of points depending on the round.
                                Each pick in the first round is worth one point, the second round is worth two points,
                                the semi-finals are worth four points, and the finals are worth eight points.
                                The tiebreaker is the top speed during eliminations which is worth an additional point
                                if correct up to the hundredths place. For each pick, if you pick a driver to win who
                                is 7 or more seeds lower than the loser, you will receive double the usual amount of
                                points for that pick (for example if you pick a 12 seed to beat a 5 seed in the second
                                round, it will be worth 4 points instead of 2).
                            </Typography>
                            <br/>
                            <Typography variant={"h6"}>Four Wide:</Typography>
                            <Typography>
                                Each pick is worth different amounts of points depending on the round.
                                Each pick in the first round is worth one point, the semi-finals round is worth two
                                points, and the finals are worth four points. The tiebreaker is the top speed during
                                eliminations which is worth an additional point if correct up to the hundredths place.
                                For each matchup you are required to pick who will get first, second, third, and fourth.
                                You will be fully awarded the amount of points if you correctly pick the correct placing
                                for a driver in that round. If you are within one placing for a driver, you will receive
                                half the normal points. If you more than two placings off you will receive no points.
                                If you get every placing correct for a round, you will receive one additional point.
                                There are no bonus points for picking lower seeds in four-wide. So for example, if you
                                pick all four placings correctly in a first round race, that race will give you 5 points.
                                Another example is if you get one placing correct, two off by one, and one off by two
                                in a second round race you will receive 1 + 0.5 + 0.5 + 0 = 2 points for that round.
                            </Typography>
                            <br/>
                            <Typography>
                                Occasionally DRBB may decide to add point modifiers to special races that will increase
                                the value for every pick by a given amount.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion sx={{
                        width: '90%',
                    }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="leagues-create-content"
                            id="leagues-create-header"
                        >
                            <Typography variant={"h6"}>How do I create a league?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                To create a league, you must first create an account and log in. Once logged in, you can
                                navigate to the leagues
                                screen and press the create league button. You will be taken to the league creation
                                screen where you can enter
                                the name of the league and a photo for the league. The photo must be under 5MB and in
                                the .jpg, .jpeg, or .png.
                                After creating the league, you will be taken to a detailed league screen where you can
                                invite people to the league,
                                view the leaderboard for your league during races, and view the history of your league.
                                You can also edit the league
                                by pressing the edit button on the league screen.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion sx={{
                        width: '90%',
                    }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="leagues-join-content"
                            id="leagues-join-header"
                        >
                            <Typography variant={"h6"}>How do I join a league?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                In order to join a league, you must first create an account and log in. If you have been
                                invited to a league,
                                you will have received either a message or email containing an invite code. You can
                                enter this code either upon sign
                                up, or by navigating to the leagues screen and pressing the join league button and then
                                enter the code.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion sx={{
                        width: '90%',
                    }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="player-history-content"
                            id="play-history-header"
                        >
                            <Typography variant={"h6"}>Where can I find my player history?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                To view player history, first you select the leagues tab in the navigation bar. Then,
                                you can select the league that you want to view the history for. Next, select the league
                                history button within the league page.
                                Events can searched by name, and the brackets picked by the player are displayed.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion sx={{
                        width: '90%',
                    }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="player-history-content"
                            id="play-history-header"
                        >
                            <Typography variant={"h6"}>How can I watch the race on race day?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Races can be watched on race day through NHRA.tv with a subscription or on FS1 or FOX.
                                The link
                                to the NHRA tv schedules can be found below.
                            </Typography>
                            <Link rel="noopener noreferrer" target="_blank" href="https://www.nhra.com/tv-schedule">NHRA
                                TV Schedule</Link>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion sx={{
                        width: '90%',
                    }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="player-history-content"
                            id="play-history-header"
                        >
                            <Typography variant={"h6"}>How can I research my picks or get more driver
                                information?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                The stats tab in the navigation bar will take you to a page where every driver is
                                listed, sorted by division.
                                Drivers can also be found via the search bar at the top of the page. Clicking on a
                                driver allows the user to view the drivers stats and history. Users can find many
                                specific driver
                                statistics, including their total number of national event wins and runner-ups, career
                                best speed and E.T, and
                                their win loss record for each season.
                                To view the history of a specific event, select the races tab in the navigation bar
                                and then click on the event you want to view. The event page will display the
                                leaderboard for the event, along with past winners.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion sx={{
                        width: '90%',
                    }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="player-history-content"
                            id="play-history-header"
                        >
                            <Typography variant={"h6"}>How will Summit Racing Equipment gift certificates be awarded?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Summit Race Equipment gift certificates will be awarded after the following series and
                                national events:
                                <br/><br/>
                                Four Wide Challenge Series
                                <br/>
                                1st-4th Place: $50 gift certificate
                                <br/>
                                8th, 12th, 16th, 20th, 24th, 28th, 32nd, 36th, and 40th Place: $50 gift certificate
                                <br/><br/>

                                Summit Racing Equipment Nationals (Norwalk Nationals)
                                <br/>
                                1st Place Overall: $150 gift certificate
                                <br/>
                                2nd & 3rd Place Overall: $50 gift certificate
                                <br/>
                                1st Place in Top Fuel, Funny Car, Pro Stock & Pro Stock Motorcycle: $50 gift certificate
                                <br/>
                                2nd & 3rd Place in Top Fuel, Funny Car, Pro Stock & Pro Stock Motorcycle: $25 gift
                                certificate
                                <br/><br/>

                                The Majors Series
                                <br/>
                                1st-15th Place: $50 gift certificate
                                <br/><br/>

                                U.S. Nationals
                                <br/>
                                1st-5th Place Overall: $50 gift certificate
                                <br/>
                                1st-5th Place in Top Fuel, Funny Car, Pro Stock & Pro Stock Motorcycle: $25 gift
                                certificate
                                <br/><br/>

                                Overall Season Series
                                <br/>
                                1-20th Place: $25 gift certificate
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Stack direction={"row"} justifyContent={isMdOrUp ? "space-between" : "center"} width={"90%"}
                       display={"flex"} flexWrap={"wrap"}>
                    <Card variant={"elevation"} elevation={3} sx={{
                        maxWidth: '325px',
                        minWidth: '300px',
                        width: 'fit-content',
                        borderRadius: '10px',
                        margin: isMdOrUp ? "8px 0": "8px",
                        p: 1,
                    }}>
                        <CardContent>
                            <Typography variant={"h5"} color="text.primary" fontWeight={"bold"} gutterBottom>
                                Contact Us For Support
                            </Typography>
                            <Typography variant="body1">
                                If you are seeing technical issues, and none of the FAQs are helping, please send us an
                                email at
                                <br/>
                                <Link href={"mailto:techsupport@dragracebracketbonanza.com"} target={"_blank"}>
                                    techsupport@dragracebracketbonanza.com
                                </Link>
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button size="small" href={"mailto:techsupport@dragracebracketbonanza.com"}
                                    target={"_blank"}>Send Email</Button>
                        </CardActions>
                    </Card>
                    <Card variant={"elevation"} elevation={3} sx={{
                        maxWidth: '325px',
                        minWidth: '300px',
                        width: 'fit-content',
                        borderRadius: '10px',
                        margin: isMdOrUp ? "8px 0": "8px",
                        p: 1,
                    }}>
                        <CardContent>
                            <Typography variant={"h5"} color="text.primary" fontWeight={"bold"} gutterBottom>
                                Want to help us improve?
                            </Typography>
                            <Typography variant="body1">
                                We are always looking for ways to improve the DRBB experience. If you have any
                                suggestions
                                or feedback, please fill out our survey.
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button size="small" href={"https://forms.gle/fzD4GmyHZLPar3v9A"} target={"_blank"}>Fill Out
                                Survey</Button>
                        </CardActions>
                    </Card>
                </Stack>
            </Grid>
        </Layout>
    )
}