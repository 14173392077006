import {FourWidePick} from "../../data/models/bracket_api/FourWidePick";
import {Pick} from "../../data/models/bracket_api/Pick";
import {Result} from "../../data/models/bracket_api/Result";
import {FourWideResult} from "../../data/models/bracket_api/FourWideResult";
import {User} from "../../data/models/user_auth/User";
import {League} from "../../data/models/user_auth/League";
import {PickStatus} from "../../data/models/bracket_api/PickStatus";
import {Driver} from "../../data/models/bracket_api/Driver";
import {uuidv4} from "../UUIDGeneration";
import {UUID} from "crypto";
import {RaceClass} from "../../data/models/bracket_api/RaceClass";
import {BracketScreenProps} from "../../pages/Bracket";
import {Event} from "../../data/models/bracket_api/Event";
import {Bracket} from "../../data/models/bracket_api/Bracket";
import {Qualifier} from "../../data/models/bracket_api/Qualifier";
import {generateResultsFromQualifiers, generateResultsFromQualifiersFourWide} from "./ResultGeneration";

type DefaultPickGenerationProps = {
    // Sorted by seed
    results: Result[] | FourWideResult[],
    isFourWide: boolean,
    player: User,
    league: League
}

/**
 * Generates a blank pick for one bracket
 * @param props
 * @returns Array of blank picks
 */
export function generateBlankPicks(props: DefaultPickGenerationProps): Pick[] | FourWidePick[] {
    if (props.isFourWide) {
        let picks: FourWidePick[] = [];
        for (let i in props.results) {
            let result: FourWideResult = props.results[i] as FourWideResult;
            picks.push({
                id: uuidv4() as UUID,
                player: props.player,
                result: result,
                competitor_1: result.competitor_1,
                competitor_2: result.competitor_2,
                competitor_3: result.competitor_3,
                competitor_4: result.competitor_4,
                seed_1: result.seed_1,
                seed_2: result.seed_2,
                seed_3: result.seed_3,
                seed_4: result.seed_4,
                league: props.league,
                pick_status: PickStatus.PENDING,
            });
        }
        // Add Tiebreak Pick
        let tiebreakerResults: FourWideResult[] = [];
        for (let i in props.results) {
            let result: FourWideResult = props.results[i] as Result;
            if (result.round === 3) {
                tiebreakerResults.push(result);
            }
        }
        for(let tiebreakerResult of tiebreakerResults){
            picks.push({
                id: uuidv4() as UUID,
                player: props.player,
                result: tiebreakerResult,
                league: props.league,
                pick_status: PickStatus.TIEBREAKER,
            });
        }

        // Mark appropriate picks as BYE
        picks.forEach((pick) => {
            if([pick.result.competitor_1_game_code, pick.result.competitor_2_game_code, pick.result.competitor_3_game_code, pick.result.competitor_4_game_code].filter((code) => code !== undefined).length === 1){
                pick.pick_status = PickStatus.BYE;
            }
        });

        return picks;
    } else {
        let picks: Pick[] = [];
        // Grab bulk of information from results
        for (let i in props.results) {
            let result: Result = props.results[i] as Result;
            picks.push({
                id: uuidv4() as UUID,
                player: props.player,
                result: result,
                competitor_1: result.competitor_1,
                competitor_2: result.competitor_2,
                seed_1: result.seed_1,
                seed_2: result.seed_2,
                league: props.league,
                pick_status: PickStatus.PENDING,
            })
            if(result.winner !== undefined && result.winner_seed !== undefined && result.round === 1 && result.competitor_2 === undefined){
                picks[picks.length - 1].winner = result.winner;
                picks[picks.length - 1].winner_seed = result.winner_seed;
            }
        }

        // Add Tiebreak Pick
        let tiebreakerResults: Result[] = [];
        for (let i in props.results) {
            let result: Result = props.results[i] as Result;
            if (result.round === 4) {
                tiebreakerResults.push(result);
            }
        }
        for(let tiebreakerResult of tiebreakerResults){
            picks.push({
                id: uuidv4() as UUID,
                player: props.player,
                result: tiebreakerResult,
                league: props.league,
                pick_status: PickStatus.TIEBREAKER,
            });
        }

        // Mark appropriate picks as BYE
        picks.forEach((pick) => {
            if(pick.result.round === 1 && pick.competitor_2 === undefined){
                pick.pick_status = PickStatus.BYE;
            }
            else if(pick.result.round === 2 && pick.result.competitor_1_game_code === undefined && pick.result.competitor_2_game_code === undefined){
                pick.pick_status = PickStatus.BYE;
            }
        });
        return picks;
    }
}


function groupPicksByRoundFourWide(picksByRound: FourWidePick[][], originalPicks: FourWidePick[]): FourWidePick[][] {
    picksByRound.push(originalPicks.filter((pick) => {
        return pick.result.round === 1
    }));
    picksByRound.push(originalPicks.filter((pick) => {
        return pick.result.round === 2
    }));
    picksByRound.push(originalPicks.filter((pick) => {
        return pick.result.round === 3
    }));
    return picksByRound;
}

function groupPicksByRoundTwoWide(picksByRound: Pick[][], originalPicks: Pick[]): Pick[][] {
    picksByRound.push(originalPicks.filter((pick) => {
        return pick.result.round === 1
    }));
    picksByRound.push(originalPicks.filter((pick) => {
        return pick.result.round === 2
    }));
    picksByRound.push(originalPicks.filter((pick) => {
        return pick.result.round === 3
    }));
    picksByRound.push(originalPicks.filter((pick) => {
        return pick.result.round === 4
    }));
    return picksByRound;
}

export function randomizePicksTwoWide(picks: Pick[]): Pick[] {
    let randomizedPicks: Pick[] = [];
    let picksByRound: Pick[][] = groupPicksByRoundTwoWide([], picks);
    for (let round of picksByRound) {
        let roundCopy = [];
        for(let pick of round) {
            let currentPick = pick;
            if(pick.competitor_2 === undefined && pick.competitor_1 !== undefined){
                currentPick.winner = pick.competitor_1;
                currentPick.winner_seed = pick.seed_1;
            }
            else if(pick.competitor_1 === undefined && pick.competitor_2 !== undefined){
                currentPick.winner = pick.competitor_2;
                currentPick.winner_seed = pick.seed_2;
            }
            else if(pick.competitor_1 !== undefined && pick.competitor_2 !== undefined){
                let random = Math.floor(Math.random() * 2);
                if(random === 0){
                    currentPick.winner = pick.competitor_1;
                    currentPick.winner_seed = pick.seed_1;
                }
                else{
                    currentPick.winner = pick.competitor_2;
                    currentPick.winner_seed = pick.seed_2;
                }
            }
            else{
                currentPick.winner = undefined;
                currentPick.winner_seed = undefined;
            }
            if(currentPick.result.round < 4){
                let nextRoundPicks = picksByRound[currentPick.result.round];
                let nextRoundPick = nextRoundPicks.find((pick) => {
                    return pick.result.competitor_1_game_code === currentPick.result.game_code || pick.result.competitor_2_game_code === currentPick.result.game_code;
                });
                if(nextRoundPick !== undefined){
                    let nextRoundPickIndex = nextRoundPicks.indexOf(nextRoundPick);
                    if(nextRoundPick.result.competitor_1_game_code === currentPick.result.game_code){
                        nextRoundPick.competitor_1 = currentPick.winner;
                        nextRoundPick.seed_1 = currentPick.winner_seed;
                    }
                    else if(nextRoundPick.result.competitor_2_game_code === currentPick.result.game_code){
                        nextRoundPick.competitor_2 = currentPick.winner;
                        nextRoundPick.seed_2 = currentPick.winner_seed;
                    }
                    nextRoundPicks[nextRoundPickIndex] = nextRoundPick;
                }
            }
            roundCopy.push(currentPick);
        }
        randomizedPicks.push(...roundCopy);
    }
    return randomizedPicks;
}

export function randomizePicksFourWide(picks: FourWidePick[]): FourWidePick[] {
    let randomizedPicks: FourWidePick[] = [];
    let picksByRound: FourWidePick[][] = groupPicksByRoundFourWide([], picks);
    for (let round of picksByRound) {
        let roundCopy = [];
        for(let pick of round) {
            let currentPick = pick;
            // Shuffle the competitors
            let competitors = [
                [pick.competitor_1, pick.seed_1],
                [pick.competitor_2, pick.seed_2],
                [pick.competitor_3, pick.seed_3],
                [pick.competitor_4, pick.seed_4]
            ];
            competitors = competitors.sort(() => Math.random() - 0.5);
            // Remove all undefined competitors
            competitors = competitors.filter((competitor) => {
                return competitor[0] !== undefined;
            });
            // Place competitors back into the pick
            for(let competitor of competitors){
                if(currentPick.first === undefined){
                    currentPick.first = competitor[0] as Driver;
                    currentPick.first_seed = competitor[1] as number;
                }
                else if(currentPick.second === undefined){
                    currentPick.second = competitor[0] as Driver;
                    currentPick.second_seed = competitor[1] as number;
                }
                else if(currentPick.third === undefined){
                    currentPick.third = competitor[0] as Driver;
                    currentPick.third_seed = competitor[1] as number;
                }
                else if(currentPick.fourth === undefined){
                    currentPick.fourth = competitor[0] as Driver;
                    currentPick.first_seed = competitor[1] as number;
                }
            }

            if(currentPick.result.round < 3){
                let nextRoundPicks = picksByRound[currentPick.result.round];
                let nextRoundPick = nextRoundPicks.find((pick) => {
                    return pick.result.competitor_1_game_code === currentPick.result.game_code ||
                        pick.result.competitor_2_game_code === currentPick.result.game_code ||
                        pick.result.competitor_3_game_code === currentPick.result.game_code ||
                        pick.result.competitor_4_game_code === currentPick.result.game_code;
                });
                if(nextRoundPick !== undefined){
                    let nextRoundPickIndex = nextRoundPicks.indexOf(nextRoundPick);
                    if(nextRoundPick.result.competitor_1_game_code === currentPick.result.game_code){
                        nextRoundPick.competitor_1 = currentPick.first;
                        nextRoundPick.seed_1 = currentPick.first_seed;
                    }
                    else if(nextRoundPick.result.competitor_3_game_code === currentPick.result.game_code){
                        nextRoundPick.competitor_3 = currentPick.first;
                        nextRoundPick.seed_3 = currentPick.first_seed;
                    }
                    if(nextRoundPick.result.competitor_2_game_code === currentPick.result.game_code){
                        nextRoundPick.competitor_2 = currentPick.second;
                        nextRoundPick.seed_2 = currentPick.second_seed;
                    }
                    else if(nextRoundPick.result.competitor_4_game_code === currentPick.result.game_code){
                        nextRoundPick.competitor_4 = currentPick.second;
                        nextRoundPick.seed_4 = currentPick.second_seed;
                    }
                    nextRoundPicks[nextRoundPickIndex] = nextRoundPick;
                }
            }
            roundCopy.push(currentPick);
        }
        randomizedPicks.push(...roundCopy);
    }
    return randomizedPicks;
}

export function generatePracticeBracketProps(isFourWide: boolean, user: User, drivers: Driver[]): BracketScreenProps{
    let event: Event = {
        id: uuidv4() as UUID,
        name: "Practice Bracket",
        location: "Practice Location",
        start_date: new Date(),
        end_date: new Date(),
        open_date: new Date(),
        close_date: new Date(),
        description: "Practice Bracket",
        is_four_wide: isFourWide,
        picks_locked: false,
    }
    let league: League = {
        id: 1,
        name: "Practice League",
        image_url: "",
        invite_code: "123456",
        creator: "user",
        players: [user],
    }
    let brackets: Bracket[] = [
        {
            id: uuidv4() as UUID,
            event: event,
            race_class: RaceClass.TOP_FUEL,
            number_of_competitors: 16,
        },
        {
            id: uuidv4() as UUID,
            event: event,
            race_class: RaceClass.FUNNY_CAR,
            number_of_competitors: 15,
        },
        {
            id: uuidv4() as UUID,
            event: event,
            race_class: RaceClass.PRO_STOCK,
            number_of_competitors: 14,
        },
        {
            id: uuidv4() as UUID,
            event: event,
            race_class: RaceClass.PRO_STOCK_MOTORCYCLE,
            number_of_competitors: 13,
        },
    ];

    let qualifiers: Qualifier[] = [];
    let results: Result[] | FourWideResult[] = [];
    for(let bracket of brackets){
        let bracketQualifiers: Qualifier[] = [];
        if(bracket.number_of_competitors) {
            let drivers_of_race_class: Driver[] = drivers.filter((driver) => {
                return (driver.divisions as RaceClass[]).includes(bracket.race_class);
            });
            for (let i = 0; i < bracket.number_of_competitors; i++) {
                bracketQualifiers.push({
                    race_class: bracket.race_class,
                    seed: i + 1,
                    bracket: bracket,
                    event: event,
                    driver: drivers_of_race_class[i],
                });
            }
            if(isFourWide){
                results = (results as FourWideResult[]).concat(generateResultsFromQualifiersFourWide(bracketQualifiers, bracket));
            }
            else{
                results = (results as Result[]).concat(generateResultsFromQualifiers(bracketQualifiers, bracket));
            }
        }
        qualifiers = qualifiers.concat(bracketQualifiers);
    }
    let picks = generateBlankPicks({
        results: results as FourWideResult[],
        isFourWide: isFourWide,
        player: user,
        league: league,
    });


    return {
        event: event,
        brackets: brackets,
        qualifiers: qualifiers,
        results: results,
        picks: picks,
    }
}