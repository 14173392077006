import Layout from "../utilities/Layout";
import Grid from "@mui/material/Grid";
import * as LeagueAPI from "../apis/League";
import * as React from "react";
import {useState} from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {Alert, Snackbar} from "@mui/material";
import GA4EventSend from "../utilities/GA4EventSend";
import Paper from "@mui/material/Paper";
import {Helmet} from "react-helmet";

export default function LeagueJoin() {
    if (localStorage.getItem('user') === null) {
        let pathname = encodeURIComponent("/pathname");
        window.location.href = "/signin/redirect?path=" + pathname;
    }

    const [open, setOpen] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [inviteCode, setInviteCode] = useState<string>("");
    const [isClicked, setIsClicked] = useState(false);
    const [success, setSuccess] = useState<boolean>(false);

    async function joinLeague() {
        setIsClicked(true);
        let response = await LeagueAPI.join_league(
            JSON.parse(localStorage.getItem('user') as string).id,
            inviteCode
        );
        if (response.succeeded) {
            setSuccess(true);
        } else {
            setSuccess(false);
        }
        GA4EventSend("league_join", "submit", "league_join", response.statusCode, false);
        setMessage(response.message);
        setOpen(true);
        setIsClicked(false);
    }

    return (
        <Layout>
            <Snackbar anchorOrigin={{vertical: "top", horizontal: "center"}} open={open} onClose={() => setOpen(false)}
                      autoHideDuration={6000}>
                <Alert elevation={6} severity={success ? "success" : "error"} sx={{width: '100%'}} variant="filled">
                    {message}
                </Alert>
            </Snackbar>
            <Helmet>
                <title>DRBB | Join League</title>
            </Helmet>
            <Grid container sx={{
                height: '60vh',
                p: 2
            }}>
                <Grid container component={Paper} elevation={3}
                      direction="column" justifyContent="center"
                      alignItems="center"
                      width={'fit-content'}
                      height={'fit-content'}
                      margin={'auto'}
                      p={2}
                >
                    <Typography variant="h5" fontWeight={"bold"} sx={{textAlign: 'center', marginTop: '1rem'}}>
                        If you were sent an invite code, enter it below.
                    </Typography>
                    <TextField
                        margin="normal"
                        required
                        id="invite_code"
                        label="League Invite Code"
                        name="email"
                        autoFocus
                        sx={{
                            maxWidth: '90%',
                            width: '300px',
                        }}
                        onChange={(e) => {
                            setInviteCode(e.target.value);
                        }}
                    />
                    <Button variant={"contained"} sx={{
                        marginTop: '1rem',
                        marginBottom: '1rem',
                    }}
                            disabled={isClicked}
                            onClick={() => {
                                setIsClicked(true);
                                joinLeague().then(() => {
                                    setIsClicked(false);
                                });
                            }}
                    >
                        <Typography variant="h6" component="h1" sx={{textAlign: 'center'}}>
                            Join League
                        </Typography>
                    </Button>
                </Grid>
            </Grid>
        </Layout>
    )
}