import {RaceClass} from "../data/models/bracket_api/RaceClass";

export default function RaceClassFromInt(raceClasses: number[]): RaceClass[] {
    let classes: RaceClass[] = [];
    for(let raceClass of raceClasses) {
        switch (raceClass) {
            case 1:
                classes.push(RaceClass.TOP_FUEL);
                break;
            case 2:
                classes.push(RaceClass.FUNNY_CAR);
                break;
            case 3:
                classes.push(RaceClass.PRO_STOCK);
                break;
            case 4:
                classes.push(RaceClass.PRO_STOCK_MOTORCYCLE);
                break;
            default:
                classes.push(RaceClass.TOP_FUEL);
        }
    }
    return classes;
}