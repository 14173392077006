import {
    InputAdornment,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow, Theme,
    Tooltip, useMediaQuery
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import {ChangeEvent, useEffect, useState} from "react";
import * as React from "react";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";

export type LeaderboardEntry = {
    rank: number,
    nickname: string,
    points: number,
    possible_points: number,
}

export type LeaderboardProps = {
    display_name: string,
    entries: LeaderboardEntry[],
    viewable: boolean,
    event_id: string,
    league_id: string,
}
export default function Leaderboard(props: Readonly<LeaderboardProps>){
    const [isMdOrUp, setIsMdOrUp] = useState(useMediaQuery((theme: Theme) => theme.breakpoints.up('md')));
    const nickname = JSON.parse(localStorage.getItem("user")!).nickname || "";
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(props.entries.length < 100 ? props.entries.length : 50);
    let [searchEntries, setSearchEntries] = useState<LeaderboardEntry[]>(props.entries);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    function handleSearchChange(event: ChangeEvent<HTMLInputElement>) {
        setSearchEntries(props.entries.filter((row: LeaderboardEntry) => row.nickname.toLowerCase().includes(event.target.value.toLowerCase())));
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMdOrUp(window.innerWidth >= 960);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [isMdOrUp]);

    let viewable = props.viewable;
    return(
        <>
            {props.entries.length > 100 &&
                <TextField
                    label="Search Nickname"
                    variant="filled"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon/>
                            </InputAdornment>
                        )
                    }}
                    sx={{
                        backgroundColor: 'background.paper',
                        borderRadius: '5px',
                        width: '100%',
                        minWidth: '300px',
                        paddingTop: '6px',
                    }}
                    onChange={handleSearchChange}
                />
            }
        <TableContainer component={Paper} sx={{ maxHeight: isMdOrUp ? '100%' : '50vh',}}>
            <Table stickyHeader aria-label="sticky table">
                <TableHead>
                    <TableRow  style={{position: 'sticky', top: 0, zIndex: 1, backgroundColor: '#fff'}}>
                        <TableCell colSpan={4}>
                            <Typography variant={"h4"} fontWeight={"bold"} align={"center"}>
                                {props.display_name}
                            </Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{paddingX: 0.9}}>
                            <Typography variant={"h6"} fontWeight={"bold"} sx={{width: "10%"}}>
                                Rank
                            </Typography>
                        </TableCell>
                        <TableCell align="left" sx={{paddingX: 0.9}}>
                            <Typography variant={"h6"} fontWeight={"bold"}>
                                User
                            </Typography>
                        </TableCell>
                        <TableCell align="right" sx={{paddingX: 0.9}}>
                            <Typography variant={"h6"} fontWeight={"bold"}>
                                Points
                            </Typography>
                        </TableCell>
                        <TableCell align="right" sx={{paddingX: 0.9}}>
                            <Typography variant={"h6"} fontWeight={"bold"}>
                                Possible Points
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {searchEntries
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => (
                        <TableRow
                            key={row.nickname}
                            sx={{
                                backgroundColor: row.nickname === nickname ? '#33a3ff' : 'inherit',
                            }}
                        >
                            <TableCell component="th" scope="row" sx={{width: "10%", p: 0.8}}>
                                <Typography variant={"body1"}>
                                    {row.rank}
                                </Typography>
                            </TableCell>
                            <TableCell align="left" sx={{p:0.8}}>
                                {viewable ?
                                    <Tooltip title={"View " + row.nickname + "'s Bracket"} placement={"top-start"}>
                                        <Link href={"/bracket/?" + encodeURIComponent("event_id=" + props.event_id + "&league_id=" + props.league_id + "&user_id=" + row.nickname)}>
                                            <Typography variant={"body2"} fontWeight={"600"}>
                                                {row.nickname}
                                            </Typography>
                                        </Link>
                                    </Tooltip>
                                    :
                                    <Typography variant={"body2"} fontWeight={"600"}>
                                        {row.nickname}
                                    </Typography>
                                }
                            </TableCell>
                            <TableCell align="right" sx={{p:0.8}}>
                                <Typography variant={"body2"}>
                                    {row.points}
                                </Typography>
                            </TableCell>
                            <TableCell align="right" sx={{p:0.8}}>
                                <Typography variant={"body2"}>
                                    {row.possible_points}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
            {props.entries.length > 100 &&
                <TablePagination
                    rowsPerPageOptions={isMdOrUp ? [10, 50, 100] : [10]}
                    component="div"
                    count={props.entries.length - 1}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            }
    </>
    )
}