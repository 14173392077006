import {Config} from "../Config";
import {AuthResponse, refresh_token} from "./Auth";

const config: Config = new Config();

export type MailResponse = {
    succeeded: boolean;
    statusCode: number;
    message: String;
    data?: any;
}

export async function send_mail_invite(email: String) {
    if (window.location.pathname === "/error") {
        return {succeeded: false, statusCode: 500, message: "Something went wrong on our end"};
    }
    try {
        if (email.length === 0) {
            return {succeeded: false, statusCode: 400, message: "Email is empty"};
        } else {
            let response: Response = await fetch(config.backendURL + config.endpoints.mail_invite, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json;charset=UTF-8',
                    "Authorization": "JWT " + localStorage.getItem('access_token'),
                },
                body: JSON.stringify({
                    email: email,
                }),
            });
            let responseStatus: number = response.status;
            let data: any = await response.json();
            switch (responseStatus) {
                case 200:
                    return {
                        succeeded: true, statusCode: 200, message: "Invite sent successfully",
                    };
                case 400:
                    return {succeeded: false, statusCode: 400, message: "Invite failed please try again later"};
                case 401:
                    return {succeeded: false, statusCode: 401, message: data["detail"]};
                case 404:
                    return {succeeded: false, statusCode: 400, message: "Servers are down, please come back later"};
                case 500:
                    return {succeeded: false, statusCode: 500, message: "Something went wrong on our end"};
                default:
                    return {succeeded: false, statusCode: 500, message: "Something went wrong on our end"};
            }
        }
    } catch (e) {
        if(e instanceof TypeError && e.message === "Failed to fetch"){
            window.location.href = "/error";
        }
        return {succeeded: false, statusCode: 500, message: "Something went wrong on our end"};
    }
}

export async function manage_subscribe(user_id: number, email_subscribe: boolean, sms_subscribe: boolean) {
    if (window.location.pathname === "/error") {
        return {succeeded: false, statusCode: 500, message: "Something went wrong on our end"};
    }
    try {
        let response: Response = await fetch(config.backendURL + config.endpoints.manage_subscribe, {
            method: 'POST',
            headers: {
                'content-type': 'application/json;charset=UTF-8',
                'Authorization': 'JWT ' + localStorage.getItem('access_token'),
            },
            body: JSON.stringify({
                user: user_id,
                mail_subscribed: email_subscribe,
                sms_subscribed: sms_subscribe,
            }),
        });
        let responseStatus: number = response.status;
        let data: any = await response.json();
        switch (responseStatus) {
            case 200:
                if (email_subscribe) {
                    return {succeeded: true, statusCode: 200, message: "Subscribed successfully"};
                } else {
                    return {succeeded: true, statusCode: 200, message: "Unsubscribed successfully"};
                }
            case 400:
                return {succeeded: false, statusCode: 400, message: "Subscription failed please try again later"};
            case 401:
                return {succeeded: false, statusCode: 401, message: data["detail"]};
            case 404:
                return {succeeded: false, statusCode: 400, message: "Servers are down, please come back later"};
            case 500:
                return {succeeded: false, statusCode: 500, message: "Something went wrong on our end"};
            default:
                return {succeeded: false, statusCode: 500, message: "Something went wrong on our end"};
        }
    } catch (e) {
        if(e instanceof TypeError && e.message === "Failed to fetch"){
            window.location.href = "/error";
        }
        return {succeeded: false, statusCode: 500, message: "Something went wrong on our end"};
    }
}

export async function get_user_mail_settings(access: string, refresh: string): Promise<MailResponse> {
    if (window.location.pathname === "/error") {
        return {succeeded: false, statusCode: 500, message: "Something went wrong on our end"};
    }
    try {
        if (access === null) {
            return {succeeded: false, statusCode: 400, message: "Access token is empty"};
        } else {
            let response: Response = await fetch(config.backendURL + config.endpoints.mail_settings, {
                method: 'GET',
                headers: {
                    'content-type': 'application/json;charset=UTF-8',
                    'Authorization': 'JWT ' + localStorage.getItem('access_token'),
                },
            });
            let responseStatus: number = response.status;
            let data: any = await response.json();
            switch (responseStatus) {
                case 200:
                    return {
                        succeeded: true, statusCode: 200, message: "Mail settings retrieved successfully", data: data
                    };
                case 400:
                    return {
                        succeeded: false,
                        statusCode: 400,
                        message: "Mail settings retrieval failed please try again later"
                    };
                case 401:
                    let refresh_validation: AuthResponse = await refresh_token(refresh);
                    if (refresh_validation.succeeded) {
                        return await get_user_mail_settings(access, refresh);
                    } else {
                        return {succeeded: false, statusCode: 401, message: "Access token is invalid"};
                    }
                default:
                    return {succeeded: false, statusCode: 500, message: "Something went wrong on our end"};
            }
        }
    } catch (e) {
        if(e instanceof TypeError && e.message === "Failed to fetch"){
            window.location.href = "/error";
        }
        return {succeeded: false, statusCode: 500, message: "Something went wrong on our end"};
    }
}