import {FourWideResult} from "../../../data/models/bracket_api/FourWideResult";
import {Result} from "../../../data/models/bracket_api/Result";
import {Card, CardContent, Divider, IconButton, Theme, useMediaQuery} from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import RaceClassFromInt from "../../../utilities/RaceClassTools";
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import {Dialog, DialogTitle, DialogContent, ButtonBase} from "@mui/material";
import {useEffect, useState} from "react";
import React from "react";
import {Driver} from "../../../data/models/bracket_api/Driver";
import ResultDetail from "../../../apis/Result";
import ResultDetailDialogContentFourWide from "./ResultDetailDialogContentFourWide";
import Loader from "../../common/Loader";
import ResultDetailDialogContentTwoWide from "./ResultDetailDialogContentTwoWide";
import CloseIcon from "@mui/icons-material/Close";
import {Qualifier} from "../../../data/models/bracket_api/Qualifier";
import Box from "@mui/material/Box";

type ResultsCardProps = {
    result: Result | FourWideResult,
    qualifiers: Qualifier[],
}

export type record = {
    wins: number,
    losses: number,
}

export type DriverDetailProps = {
    driver: Driver,
    season_record: record,
    pick_percentage: number,
}

export type ResultDetailProps = {
    competitor_1: DriverDetailProps,
    competitor_2: DriverDetailProps,
    competitor_3?: DriverDetailProps,
    competitor_4?: DriverDetailProps,
    other_driver_picked_percentage: number,
}

export default function ResultsCard(props: Readonly<ResultsCardProps>) {
    const [open, setOpen] = useState(false);
    const [resultDetail, setResultDetail] = useState<ResultDetailProps | null>(null);
    const [isMdOrUp, setIsMdOrUp] = useState(useMediaQuery((theme: Theme) => theme.breakpoints.up('md')));

    useEffect(() => {

        const handleResize = () => {
            setIsMdOrUp(window.innerWidth >= 960);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [isMdOrUp]);

    async function handleClickOpen() {
        setOpen(true);
        if (resultDetail === null) {
            // Make call to get result detail
            let response = await ResultDetail(props.result.game_code);
            if (response.succeeded && response.data !== undefined) {
                setResultDetail(response.data);
            } else {
                console.error(response.message);
            }
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    if (typeof props.result === "object" && "competitor_3" in props.result) {
        let result = props.result as FourWideResult;
        return (
            <React.Fragment>
                <ButtonBase>
                    <Card sx={{
                        margin: 1,
                        width: '300px',
                        cursor: "pointer",
                    }}>
                        <CardContent>
                            <Grid container sx={{
                                direction: "column",
                            }}>
                                <Typography variant={"body1"}
                                            fontWeight={"bold"}
                                            sx={{
                                                textAlign: "left",
                                                margin: 0,
                                                marginBottom: '4px',
                                                marginLeft: '8px',
                                                padding: 0,
                                            }}>
                                    {RaceClassFromInt([result.bracket.race_class as unknown as number])[0] + " - Round " + result.round}
                                </Typography>
                                <Divider sx={{width: '100%', marginBottom: '4px'}}/>
                                <Grid container sx={{
                                    direction: "row",
                                    display: "flex",
                                    flexWrap: "nowrap",
                                    alignItems: "center",
                                    borderRadius: 2,
                                }}>
                                    <Grid item xs={1.5} sx={{
                                        visibility: result.first?.id && result.competitor_1?.id && result.first.id === result.competitor_1.id ? "block" : "hidden",
                                        color: "#486CE7",
                                    }}>
                                        <EmojiEventsIcon/>
                                    </Grid>
                                    <Grid item xs={1.5}>
                                        <Typography>
                                            {result.seed_1}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <Typography noWrap={true} variant={"body2"}>
                                            {result.competitor_1 !== undefined && result.competitor_1 !== null && result.competitor_1.first_name + " " + result.competitor_1.last_name}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2.5}>
                                        <Typography>
                                            {result.competitor_1_exception ? result.competitor_1_exception : result.competitor_1_time}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container sx={{
                                    direction: "row",
                                    display: "flex",
                                    flexWrap: "nowrap",
                                    alignItems: "center",
                                    borderRadius: 2,
                                }}>
                                    <Grid item xs={1.5} sx={{
                                        visibility: result.first?.id && result.competitor_2?.id && result.first.id === result.competitor_2.id ? "block" : "hidden",
                                        color: "#486CE7",
                                    }}>
                                        <EmojiEventsIcon/>
                                    </Grid>
                                    <Grid item xs={1.5}>
                                        <Typography>
                                            {result.seed_2}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <Typography noWrap>
                                            {result.competitor_2 !== undefined && result.competitor_2 !== null && result.competitor_2.first_name + " " + result.competitor_2.last_name}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2.5}>
                                        <Typography noWrap>
                                            {result.competitor_2_exception ? result.competitor_2_exception : result.competitor_2_time}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container sx={{
                                    direction: "row",
                                    display: "flex",
                                    flexWrap: "nowrap",
                                    alignItems: "center",
                                    borderRadius: 2,
                                }}>
                                    <Grid item xs={1.5} sx={{
                                        visibility: result.first?.id && result.competitor_3?.id && result.first.id === result.competitor_3.id ? "block" : "hidden",
                                        color: "#486CE7",
                                    }}>
                                        <EmojiEventsIcon/>
                                    </Grid>
                                    <Grid item xs={1.5}>
                                        <Typography>
                                            {result.seed_3}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <Typography noWrap>
                                            {result.competitor_3 !== undefined && result.competitor_3 !== null && result.competitor_3.first_name + " " + result.competitor_3.last_name}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2.5}>
                                        <Typography noWrap>
                                            {result.competitor_3_exception ? result.competitor_3_exception : result.competitor_3_time}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container sx={{
                                    direction: "row",
                                    display: "flex",
                                    flexWrap: "nowrap",
                                    alignItems: "center",
                                    borderRadius: 2,
                                }}>
                                    <Grid item xs={1.5} sx={{
                                        visibility: result.first?.id && result.competitor_4?.id && result.first.id === result.competitor_4.id ? "block" : "hidden",
                                        color: "#486CE7",
                                    }}>
                                        <EmojiEventsIcon/>
                                    </Grid>
                                    <Grid item xs={1.5}>
                                        <Typography>
                                            {result.seed_4}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <Typography noWrap>
                                            {result.competitor_4 !== undefined && result.competitor_4 !== null && result.competitor_4.first_name + " " + result.competitor_4.last_name}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2.5}>
                                        <Typography noWrap>
                                            {result.competitor_4_exception ? result.competitor_4_exception : result.competitor_4_time}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </ButtonBase>
                <Dialog open={open} onClose={handleClose} maxWidth={false} sx={{alignItems: 'center', overflowX: 'hidden'}}>
                    <DialogTitle sx={{width: "95%", display: 'flex', justifyContent: 'flex-end', padding: "0px 4px !important"}}>
                        <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent sx={{padding: isMdOrUp ? '24px, 20px': 0, display: 'flex', justifyContent: 'center', minHeight: '400px'}}>
                        {
                            resultDetail !== null ?
                                <ResultDetailDialogContentFourWide details={resultDetail} result={props.result} qualifiers={props.qualifiers}/>
                                :
                                <Box sx={{width: '300px', height: '400px', display: 'flex'}}>
                                    <Loader/>
                                </Box>
                        }
                    </DialogContent>
                </Dialog>
            </React.Fragment>
        )
    } else {
        let result = props.result as Result;
        return (
            <React.Fragment>
                <ButtonBase onClick={handleClickOpen}>
                    <Card sx={{
                        margin: 1,
                        width: '300px',
                        cursor: "pointer",
                    }}>
                        <CardContent sx={{
                            margin: 0,
                            padding: 1,
                        }}>
                            <Grid container sx={{
                                direction: "column",
                            }}>
                                <Typography variant={"body1"}
                                            fontWeight={"bold"}
                                            sx={{
                                                textAlign: "left",
                                                margin: 0,
                                                marginBottom: '4px',
                                                marginLeft: '8px',
                                                padding: 0,
                                            }}>
                                    {RaceClassFromInt([result.bracket.race_class as unknown as number])[0] + " - Round " + result.round}
                                </Typography>
                                <Divider sx={{width: '100%', marginBottom: '4px'}}/>
                                <Grid container sx={{
                                    direction: "row",
                                    display: "flex",
                                    flexWrap: "nowrap",
                                    alignItems: "center",
                                    borderRadius: 2,
                                }}>
                                    <Grid item xs={1.5} sx={{
                                        visibility: result.winner?.id && result.competitor_1?.id && result.winner.id === result.competitor_1.id ? "block" : "hidden",
                                        color: "#486CE7",
                                    }}>
                                        <EmojiEventsIcon/>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Typography textAlign={"right"}>
                                            {result.seed_1}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={7} >
                                        <Typography noWrap={true} ml={1} variant={"body2"} textAlign={"left"}>
                                            {result.competitor_1?.first_name + " " + result.competitor_1?.last_name}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2.5}>
                                        <Typography>
                                            {result.competitor_1_exception ? result.competitor_1_exception : result.competitor_1_time}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container sx={{
                                    direction: "row",
                                    display: "flex",
                                    flexWrap: "nowrap",
                                    alignItems: "center",
                                    borderRadius: 2,
                                }}>
                                    <Grid item xs={1.5} sx={{
                                        visibility: result.winner?.id && result.competitor_2?.id && result.winner.id === result.competitor_2.id ? "block" : "hidden",
                                        color: "#486CE7",
                                    }}>
                                        <EmojiEventsIcon/>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Typography textAlign={"right"}>
                                            {result.seed_2}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <Typography noWrap ml={1} textAlign={"left"}>
                                            {result.competitor_2?.first_name + " " + result.competitor_2?.last_name}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2.5}>
                                        <Typography noWrap>
                                            {result.competitor_2_exception ? result.competitor_2_exception : result.competitor_2_time}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </ButtonBase>
                <Dialog open={open} onClose={handleClose} maxWidth={false} sx={{alignItems: 'center', overflowX: 'hidden'}}>
                    <DialogTitle sx={{width: "95%", display: 'flex', justifyContent: 'flex-end', padding: "0px 4px !important"}}>
                        <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent sx={{padding: isMdOrUp ? '24px, 20px': 0, display: 'flex', justifyContent: 'center', minHeight: '400px'}}>
                        {
                            resultDetail !== null ?
                                <ResultDetailDialogContentTwoWide details={resultDetail} result={props.result} qualifiers={props.qualifiers}/>
                                :
                                <Box sx={{width: '300px', height: '400px', display: 'flex'}}>
                                    <Loader/>
                                </Box>
                        }
                    </DialogContent>
                </Dialog>
            </React.Fragment>
        )
    }
}