import {Config} from "../Config";
import {AuthResponse, refresh_token} from "./Auth";

const config = new Config();

export type PickResponse = {
    succeeded: boolean,
    statusCode: number,
    message: string,
    data?: any,
}

export async function copyPicks(access: string, refresh: string, fromLeagueId: number, toLeagueId: number, eventId: string): Promise<PickResponse> {
    if (window.location.pathname === '/error') {
        return {succeeded: false, statusCode: 400, message: "Servers are down, please come back later"};
    }
    try {
        if (access === null) {
            return {succeeded: false, statusCode: 401, message: "Access token is empty"};
        } else {
            let response: Response = await fetch(config.backendURL + config.endpoints.pick_copy, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json;charset=UTF-8',
                    'Authorization': 'JWT ' + access,
                },
                body: JSON.stringify({
                    'event': eventId,
                    'from_league': fromLeagueId,
                    'to_league': toLeagueId
                })
            });
            let data = await response.json();
            let responseStatus: number = response.status;
            if (responseStatus < 400) {
                return {succeeded: true, statusCode: 200, message: "Picks Copied Successfully", data: data};
            }
            else if(responseStatus === 401){
                let refresh_validation: AuthResponse = await refresh_token(refresh);
                if (refresh_validation.succeeded) {
                    return await copyPicks(access, refresh, fromLeagueId, toLeagueId, eventId);
                } else {
                    return {succeeded: false, statusCode: 401, message: "Access token is invalid"};
                }
            }
            else{
                return {succeeded: false, statusCode: 400, message: "Failed to copy picks", data: data};
            }
        }
    } catch (e) {
        if(e instanceof TypeError && e.message === "Failed to fetch"){
            window.location.href = '/error';
        }
        return {succeeded: false, statusCode: 500, message: "Something went wrong on our end"};
    }
}

export async function submitPicks(access: string, refresh: string, picks: any): Promise<PickResponse> {
    if (window.location.pathname === '/error') {
        return {succeeded: false, statusCode: 400, message: "Servers are down, please come back later"};
    }
    try {
        if (access === null) {
            return {succeeded: false, statusCode: 401, message: "Access token is empty"};
        } else {
            let response: Response = await fetch(config.backendURL + config.endpoints.pick_bulk, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json;charset=UTF-8',
                    'Authorization': 'JWT ' + access,
                },
                body: JSON.stringify(picks)
            });
            let data = await response.json();
            let responseStatus: number = response.status;
            if (responseStatus < 400) {
                return {succeeded: true, statusCode: 200, message: "Picks Saved Successfully", data: data};
            }
            else if(responseStatus === 401){
                let refresh_validation: AuthResponse = await refresh_token(refresh);
                if (refresh_validation.succeeded) {
                    return await submitPicks(access, refresh, picks);
                } else {
                    return {succeeded: false, statusCode: 401, message: "Access token is invalid"};
                }
            }
            else{
                return {succeeded: false, statusCode: 400, message: data, data: data};
            }
        }
    } catch (e) {
        if(e instanceof TypeError && e.message === "Failed to fetch"){
            window.location.href = '/error';
        }
        return {succeeded: false, statusCode: 500, message: "Something went wrong on our end"};
    }
}

export async function submitPicksFourWide(access: string, refresh: string, picks: any): Promise<PickResponse> {
    if (window.location.pathname === '/error') {
        return {succeeded: false, statusCode: 400, message: "Servers are down, please come back later"};
    }
    try {
        if (access === null) {
            return {succeeded: false, statusCode: 401, message: "Access token is empty"};
        } else {
            let response: Response = await fetch(config.backendURL + config.endpoints.four_wide_pick_bulk, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json;charset=UTF-8',
                    'Authorization': 'JWT ' + access,
                },
                body: JSON.stringify(picks)
            });
            let data = await response.json();
            let responseStatus: number = response.status;
            if (responseStatus < 400) {
                return {succeeded: true, statusCode: 200, message: "Picks Saved Successfully", data: data};
            }
            else if(responseStatus === 401){
                let refresh_validation: AuthResponse = await refresh_token(refresh);
                if (refresh_validation.succeeded) {
                    return await submitPicksFourWide(access, refresh, picks);
                } else {
                    return {succeeded: false, statusCode: 401, message: "Access token is invalid"};
                }
            }
            else{
                return {succeeded: false, statusCode: 400, message: data, data: data};
            }
        }
    } catch (e) {
        if(e instanceof TypeError && e.message === "Failed to fetch"){
            window.location.href = '/error';
        }
        return {succeeded: false, statusCode: 500, message: "Something went wrong on our end"};
    }
}

export async function pickStats(access: string, refresh: string): Promise<PickResponse> {
    if (window.location.pathname === '/error') {
        return {succeeded: false, statusCode: 400, message: "Servers are down, please come back later"};
    }
    try {
        if (access === null) {
            return {succeeded: false, statusCode: 401, message: "Access token is empty"};
        } else {
            let response: Response = await fetch(config.backendURL + config.endpoints.pick_stats, {
                method: 'GET',
                headers: {
                    'content-type': 'application/json;charset=UTF-8',
                    'Authorization': 'JWT ' + access,
                }
            });
            let data = await response.json();
            let responseStatus: number = response.status;
            if (responseStatus < 400) {
                return {succeeded: true, statusCode: 200, message: "Successfully pulled user's pick stats", data: data};
            }
            else if(responseStatus === 401){
                let refresh_validation: AuthResponse = await refresh_token(refresh);
                if (refresh_validation.succeeded) {
                    return await pickStats(access, refresh);
                } else {
                    return {succeeded: false, statusCode: 401, message: "Access token is invalid"};
                }
            }
            else{
                return {succeeded: false, statusCode: 400, message: "Failed to pull user's pick stats", data: data};
            }
        }
    } catch (e) {
        if(e instanceof TypeError && e.message === "Failed to fetch"){
            window.location.href = '/error';
        }
        return {succeeded: false, statusCode: 500, message: "Something went wrong on our end"};
    }
}