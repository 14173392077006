import React from 'react';
import Header from "../components/common/Header";
import Footer from "../components/common/Footer";
import Grid from "@mui/material/Grid";
import CssBaseline from "@mui/material/CssBaseline";
import HomeIcon from '@mui/icons-material/Home';
import GroupsIcon from '@mui/icons-material/Groups';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import {useTheme} from "@mui/material";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ShopIcon from '@mui/icons-material/Shop';

interface LayoutProps {
    children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
    let sections = [
        { title: 'Home', url: '/', icon: <HomeIcon style={{color: 'white'}}/>, active: window.location.pathname==='/'},
        { title: 'My Leagues', url: '/leagues', icon: <GroupsIcon style={{color: 'white'}}/>, active: window.location.pathname==='/leagues'},
        { title: 'Races', url: '/races', icon: <CalendarMonthIcon style={{color: 'white'}}/>, active: window.location.pathname==='/races'},
        { title: 'Stats', url: '/stats', icon: <QueryStatsIcon style={{color: 'white'}}/>, active: window.location.pathname==='/stats'},
        { title: 'Account', url: '/account', icon: <AccountBoxIcon style={{color: 'white'}}/>, active: window.location.pathname==='/account'},
        { title: 'Shop', url: 'https://DRBBShop.com', icon: <ShopIcon style={{color: 'white'}}/>, active: false},
        { title: 'Help', url: '/help', icon: <HelpOutlineIcon style={{color: 'white'}}/>, active: window.location.pathname==='/help'},
    ]
    // Add admin button if user is admin
    try {
        if (localStorage.getItem('signed_in') === "true" && JSON.parse(localStorage.getItem('user') as string).is_staff) {
            sections.push({
                title: 'Admin',
                url: '/admin',
                icon: <AdminPanelSettingsIcon style={{color: 'white'}}/>,
                active: false
            });
        }
    }
    catch (e) {

    }
    const title: string = 'Drag Race Bracket Bonanza';
    const theme = useTheme();
    const darkMode = theme.palette.mode === 'dark';
    return (
        <Grid container sx={{
            minHeight: '100vh',
            display: 'flex',
            overflowY: 'auto',
            background: darkMode ?
                'linear-gradient(135deg, white 10%, #121212 10%, #121212 12%, white 12%, white 22%, transparent 22%);'
                :
                'linear-gradient(135deg, #7F9AF5 10%, white 10%, white 12%, #7F9AF5 12%, #7F9AF5 22%, transparent 22%);',
            backgroundAttachment: 'fixed',
        }}>
            <CssBaseline/>
            <Header sections={sections} title={title}/>
            {children}
            <Footer/>
        </Grid>
    );
};

export default Layout;