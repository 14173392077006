import {ResultDetailProps} from "./ResultsCard";
import Grid from "@mui/material/Grid";
import GaugeChart from 'react-gauge-chart';
import ResultDetailDialogDriverColumn, {handleError} from "./ResultDetailDialogDriverColumn";
import {FourWideResult} from "../../../data/models/bracket_api/FourWideResult";
import Typography from "@mui/material/Typography";
import {Divider, Theme, useMediaQuery} from "@mui/material";
import Box from "@mui/material/Box";
import {useEffect, useState} from "react";
import {Qualifier} from "../../../data/models/bracket_api/Qualifier";

type ResultDetailPropsContainer = {
    details: ResultDetailProps,
    result: FourWideResult,
    qualifiers: Qualifier[],
}
export default function ResultDetailDialogContentFourWide(props: Readonly<ResultDetailPropsContainer>) {
    const [isMdOrUp, setIsMdOrUp] = useState(useMediaQuery((theme: Theme) => theme.breakpoints.up('md')));

    useEffect(() => {

        const handleResize = () => {
            setIsMdOrUp(window.innerWidth >= 960);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [isMdOrUp]);
    return (
        <Grid container direction={"column"} width={"95%"} minWidth={isMdOrUp ? 'fit-content': '87vw'} sx={{alignItems:"center"}}>
            <Grid container direction={"row"} justifyContent={"space-between"} display={"flex"} flexWrap={"nowrap"} sx={{overflowX: "hidden"}}>
                {
                    props.details.competitor_1 &&
                    <ResultDetailDialogDriverColumn
                        details={props.details.competitor_1}
                        sx={{
                            flex: 0.2,
                            position: 'relative',
                            alignItems: 'center',
                        }}
                        seed={props.result.seed_1}
                        qualifying_time={props.qualifiers.find(qualifier => qualifier.driver && qualifier.driver.id === props.details.competitor_1.driver.id)?.elapsed_time}
                    />
                }
                {
                    props.details.competitor_2 &&
                    <ResultDetailDialogDriverColumn
                        details={props.details.competitor_2}
                        sx={{
                            flex: 0.2,
                            position: 'relative',
                            alignItems: 'center',
                        }}
                        seed={props.result.seed_2}
                        qualifying_time={props.qualifiers.find(qualifier => qualifier.driver && qualifier.driver.id === props.details.competitor_2.driver.id)?.elapsed_time}
                    />
                }
                <Grid container direction={"column"} sx={{
                    flex: 0.2,
                }}>
                    <Typography variant={"body1"} textAlign={"center"} fontWeight={"bolder"} marginY={1} height={isMdOrUp? '60px' : '45px'} overflow={'hidden'}>
                        Driver
                    </Typography>
                    <Divider/>
                    <Box height={isMdOrUp ? "220px" : "99px"} width={isMdOrUp ? "200px": "90px"}/>
                    <Divider/>
                    <Typography variant={isMdOrUp ? "h6": "body1"} textAlign={"center"} height={isMdOrUp? '60px' : '25px'} overflow={'hidden'} fontWeight={isMdOrUp ? "bold" : "normal"} marginY={1}>
                        Seed
                    </Typography>
                    <Typography variant={isMdOrUp ? "h6": "body1"} textAlign={"center"} height={isMdOrUp? '60px' : '25px'} overflow={'hidden'} fontWeight={isMdOrUp ? "bold" : "normal"} marginY={1}>
                        Qualifying Time
                    </Typography>
                    <Typography variant={isMdOrUp ? "h6": "body1"} textAlign={"center"} height={isMdOrUp? '60px' : '25px'} overflow={'hidden'} fontWeight={isMdOrUp ? "bold" : "normal"} marginY={1}>
                        {isMdOrUp ? "Career Best E.T." : "Best E.T."}
                    </Typography>
                    <Typography variant={isMdOrUp ? "h6": "body1"} textAlign={"center"} height={isMdOrUp? '60px' : '25px'} overflow={'hidden'} fontWeight={isMdOrUp ? "bold" : "normal"} marginY={1}>
                        Best Speed
                    </Typography>
                    <Typography variant={isMdOrUp ? "h6": "body1"} textAlign={"center"} height={isMdOrUp? '60px' : '25px'} overflow={'hidden'} fontWeight={isMdOrUp ? "bold" : "normal"} marginY={1}>
                        Season Record
                    </Typography>
                    <Typography variant={isMdOrUp ? "h6": "body1"} textAlign={"center"} fontWeight={"bold"} marginY={1}  overflow={'hidden'} fontSize={isMdOrUp ? "inherit" : "10px !important"}>
                        {isMdOrUp ? "Pick Percentage" : "Pick %"}
                    </Typography>
                    {/*<Typography variant={isMdOrUp ? "h6": "body1"} textAlign={"center"} fontWeight={"bolder"} marginY={1}>*/}
                    {/*    Driver <br/><br/>*/}
                    {/*</Typography>*/}
                    {/*<Divider/>*/}
                    {/*<Box height={isMdOrUp ? "220px" : "66px"} width={isMdOrUp ? "200px": "60px"}/>*/}
                    {/*<Divider/>*/}
                    {/*<Typography variant={isMdOrUp ? "h6": "body1"} textAlign={"center"} fontWeight={"bolder"} marginY={1} fontSize={isMdOrUp ? "inherit" : "10px !important"}>*/}
                    {/*    Seed*/}
                    {/*</Typography>*/}
                    {/*<Typography variant={isMdOrUp ? "h6": "body1"} textAlign={"center"} fontWeight={"bolder"} marginY={1} fontSize={isMdOrUp ? "inherit" : "10px !important"}>*/}
                    {/*    Qualifying Time*/}
                    {/*</Typography>*/}
                    {/*<Typography variant={isMdOrUp ? "h6": "body1"} textAlign={"center"} fontWeight={"bolder"} marginY={1} fontSize={isMdOrUp ? "inherit" : "10px !important"}>*/}
                    {/*    {isMdOrUp ? "Career Best E.T." : "Best E.T."}*/}
                    {/*</Typography>*/}
                    {/*<Typography variant={isMdOrUp ? "h6": "body1"} textAlign={"center"} fontWeight={"bolder"} marginY={1} fontSize={isMdOrUp ? "inherit" : "10px !important"}>*/}
                    {/*    Best Speed*/}
                    {/*</Typography>*/}
                    {/*<Typography variant={isMdOrUp ? "h6": "body1"} textAlign={"center"} fontWeight={"bold"} marginY={1} fontSize={isMdOrUp ? "inherit" : "10px !important"}>*/}
                    {/*    Season Record*/}
                    {/*</Typography>*/}
                    {/*<Typography variant={isMdOrUp ? "h6": "body1"} textAlign={"center"} fontWeight={"bold"} marginY={1} fontSize={isMdOrUp ? "inherit" : "10px !important"}>*/}
                    {/*    {isMdOrUp ? "Pick Percentage" : "Pick %"}*/}
                    {/*</Typography>*/}
                </Grid>
                {
                    props.details.competitor_3 &&
                    <ResultDetailDialogDriverColumn
                        details={props.details.competitor_3}
                        sx={{
                            flex: 0.2,
                            position: 'relative',
                            alignItems: 'center',
                        }}
                        seed={props.result.seed_3}
                        qualifying_time={props.qualifiers.find(qualifier => props.details.competitor_3 && qualifier.driver && qualifier.driver.id === props.details.competitor_3.driver.id)?.elapsed_time}
                    />
                }
                {
                    props.details.competitor_4 &&
                    <ResultDetailDialogDriverColumn
                        details={props.details.competitor_4}
                        sx={{
                            flex: 0.2,
                            position: 'relative',
                            alignItems: 'center',
                        }}
                        seed={props.result.seed_4}
                        qualifying_time={props.qualifiers.find(qualifier => props.details.competitor_4 && qualifier.driver && qualifier.driver.id === props.details.competitor_4.driver.id)?.elapsed_time}
                    />
                }
            </Grid>
            {props.details.other_driver_picked_percentage !== null && props.details.other_driver_picked_percentage !== 0 &&
                <>
                    <Typography variant={isMdOrUp ? "h6": "body1"} textAlign={"center"} fontWeight={"bold"} marginY={1}>
                        Other Driver Pick Percentage
                    </Typography>
                    <GaugeChart
                        nrOfLevels={2}
                        arcsLength={[props.details.other_driver_picked_percentage / 100, 1 - props.details.other_driver_picked_percentage / 100]}
                        colors={["#486ce7", "#aaa"]}
                        animate={false}
                        cornerRadius={0}
                        percent={props.details.other_driver_picked_percentage / 100}
                        style={{
                            width: isMdOrUp ? "300px" : "150px",
                        }}
                    />
                </>
            }
        </Grid>
    )
}